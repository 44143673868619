export const privacyPolicyFr: string = `
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ul.lst-kix_ibqveulz93g8-8 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-7 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-6 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-5 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-4 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-3 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-2 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-1 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-0 {
            list-style-type: none
        }

        .lst-kix_4qb661u38zts-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_4qb661u38zts-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_4qb661u38zts-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_1mea951yss04-0 {
            list-style-type: none
        }

        .lst-kix_4iorh8rmmrxk-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_4iorh8rmmrxk-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_4iorh8rmmrxk-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_4iorh8rmmrxk-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_srzc9u7qrlgb-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_kbka666puyyd-2 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-1 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-0 {
            list-style-type: none
        }

        .lst-kix_srzc9u7qrlgb-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_kbka666puyyd-6 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-5 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-4 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-3 {
            list-style-type: none
        }

        .lst-kix_srzc9u7qrlgb-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_kbka666puyyd-8 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-7 {
            list-style-type: none
        }

        .lst-kix_srzc9u7qrlgb-2>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_g187jgfw7d8u-5 {
            list-style-type: none
        }

        .lst-kix_4iorh8rmmrxk-4>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_1mea951yss04-5 {
            list-style-type: none
        }

        .lst-kix_srzc9u7qrlgb-7>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_g187jgfw7d8u-4 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-6 {
            list-style-type: none
        }

        ul.lst-kix_g187jgfw7d8u-7 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-8 {
            list-style-type: none
        }

        .lst-kix_4iorh8rmmrxk-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_1mea951yss04-7 {
            list-style-type: none
        }

        ul.lst-kix_g187jgfw7d8u-6 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-8 {
            list-style-type: none
        }

        ul.lst-kix_g187jgfw7d8u-1 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-6 {
            list-style-type: none
        }

        .lst-kix_4iorh8rmmrxk-2>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_1mea951yss04-1 {
            list-style-type: none
        }

        ul.lst-kix_g187jgfw7d8u-0 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-7 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-2 {
            list-style-type: none
        }

        ul.lst-kix_g187jgfw7d8u-3 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-4 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-3 {
            list-style-type: none
        }

        .lst-kix_srzc9u7qrlgb-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_g187jgfw7d8u-2 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-5 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-4 {
            list-style-type: none
        }

        .lst-kix_srzc9u7qrlgb-4>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_jjocaga77yvy-2 {
            list-style-type: none
        }

        .lst-kix_4iorh8rmmrxk-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_jjocaga77yvy-3 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-0 {
            list-style-type: none
        }

        .lst-kix_4iorh8rmmrxk-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_jjocaga77yvy-1 {
            list-style-type: none
        }

        .lst-kix_srzc9u7qrlgb-5>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_g187jgfw7d8u-8 {
            list-style-type: none
        }

        .lst-kix_4qb661u38zts-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_srzc9u7qrlgb-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_4qb661u38zts-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_4qb661u38zts-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_4qb661u38zts-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_4qb661u38zts-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_4qb661u38zts-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_w9fja5od708-3 {
            list-style-type: none
        }

        ul.lst-kix_w9fja5od708-4 {
            list-style-type: none
        }

        .lst-kix_kuf2cz8v3p7x-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_kuf2cz8v3p7x-4>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_w9fja5od708-1 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_dfd3xiy9wao-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_4gb6erjva6gm-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_4gb6erjva6gm-5>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_w9fja5od708-2 {
            list-style-type: none
        }

        ul.lst-kix_w9fja5od708-7 {
            list-style-type: none
        }

        ul.lst-kix_w9fja5od708-8 {
            list-style-type: none
        }

        ul.lst-kix_w9fja5od708-5 {
            list-style-type: none
        }

        ul.lst-kix_w9fja5od708-6 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-5 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-4 {
            list-style-type: none
        }

        .lst-kix_kuf2cz8v3p7x-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_kuf2cz8v3p7x-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_dfd3xiy9wao-3 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_dfd3xiy9wao-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_4gb6erjva6gm-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_4gb6erjva6gm-7>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_dfd3xiy9wao-2 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-1 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_w9fja5od708-0 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-0 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-7>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_dfd3xiy9wao-8 {
            list-style-type: none
        }

        .lst-kix_vl80fq9xj0zy-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_dfd3xiy9wao-7 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-6 {
            list-style-type: none
        }

        ul.lst-kix_4iorh8rmmrxk-1 {
            list-style-type: none
        }

        ul.lst-kix_4iorh8rmmrxk-0 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_4iorh8rmmrxk-5 {
            list-style-type: none
        }

        ul.lst-kix_4iorh8rmmrxk-4 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-5>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_4iorh8rmmrxk-3 {
            list-style-type: none
        }

        ul.lst-kix_4iorh8rmmrxk-2 {
            list-style-type: none
        }

        ul.lst-kix_4iorh8rmmrxk-8 {
            list-style-type: none
        }

        .lst-kix_vl80fq9xj0zy-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_4iorh8rmmrxk-7 {
            list-style-type: none
        }

        ul.lst-kix_4iorh8rmmrxk-6 {
            list-style-type: none
        }

        .lst-kix_bdqgnzvk5mdq-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_vl80fq9xj0zy-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_vl80fq9xj0zy-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_bdqgnzvk5mdq-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kuf2cz8v3p7x-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_vl80fq9xj0zy-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_bdqgnzvk5mdq-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_g187jgfw7d8u-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_1mea951yss04-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_g187jgfw7d8u-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_g187jgfw7d8u-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_3sw0yshborkn-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_1mea951yss04-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_3sw0yshborkn-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_3sw0yshborkn-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_3sw0yshborkn-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_g187jgfw7d8u-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_akpzl7ddoxyx-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_1mea951yss04-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_akpzl7ddoxyx-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_1mea951yss04-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_akpzl7ddoxyx-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_3sw0yshborkn-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_akpzl7ddoxyx-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_4gb6erjva6gm-0 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-1 {
            list-style-type: none
        }

        ul.lst-kix_4gb6erjva6gm-1 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-0 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-3 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-2 {
            list-style-type: none
        }

        ul.lst-kix_4gb6erjva6gm-4 {
            list-style-type: none
        }

        ul.lst-kix_4gb6erjva6gm-5 {
            list-style-type: none
        }

        ul.lst-kix_4gb6erjva6gm-2 {
            list-style-type: none
        }

        ul.lst-kix_4gb6erjva6gm-3 {
            list-style-type: none
        }

        ul.lst-kix_4gb6erjva6gm-8 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-8 {
            list-style-type: none
        }

        ul.lst-kix_4gb6erjva6gm-6 {
            list-style-type: none
        }

        ul.lst-kix_4gb6erjva6gm-7 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-5 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-4 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-7 {
            list-style-type: none
        }

        ul.lst-kix_vl80fq9xj0zy-6 {
            list-style-type: none
        }

        .lst-kix_y75wbynhs0cx-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_jjocaga77yvy-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_y75wbynhs0cx-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jjocaga77yvy-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_jjocaga77yvy-5>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_srzc9u7qrlgb-1 {
            list-style-type: none
        }

        ul.lst-kix_srzc9u7qrlgb-0 {
            list-style-type: none
        }

        ul.lst-kix_srzc9u7qrlgb-3 {
            list-style-type: none
        }

        ul.lst-kix_srzc9u7qrlgb-2 {
            list-style-type: none
        }

        .lst-kix_jjocaga77yvy-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_jjocaga77yvy-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_ibqveulz93g8-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_srzc9u7qrlgb-5 {
            list-style-type: none
        }

        ul.lst-kix_srzc9u7qrlgb-4 {
            list-style-type: none
        }

        ul.lst-kix_srzc9u7qrlgb-7 {
            list-style-type: none
        }

        ul.lst-kix_srzc9u7qrlgb-6 {
            list-style-type: none
        }

        .lst-kix_ibqveulz93g8-7>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_srzc9u7qrlgb-8 {
            list-style-type: none
        }

        .lst-kix_jjocaga77yvy-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_jjocaga77yvy-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_ibqveulz93g8-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_ibqveulz93g8-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_ibqveulz93g8-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_ibqveulz93g8-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_ibqveulz93g8-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_y75wbynhs0cx-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_jjocaga77yvy-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_kuf2cz8v3p7x-8 {
            list-style-type: none
        }

        .lst-kix_y75wbynhs0cx-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_ibqveulz93g8-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jjocaga77yvy-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_ibqveulz93g8-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_y75wbynhs0cx-4>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_kuf2cz8v3p7x-1 {
            list-style-type: none
        }

        ul.lst-kix_kuf2cz8v3p7x-0 {
            list-style-type: none
        }

        .lst-kix_y75wbynhs0cx-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_y75wbynhs0cx-5>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_kuf2cz8v3p7x-3 {
            list-style-type: none
        }

        ul.lst-kix_kuf2cz8v3p7x-2 {
            list-style-type: none
        }

        .lst-kix_y75wbynhs0cx-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_y75wbynhs0cx-6>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_kuf2cz8v3p7x-5 {
            list-style-type: none
        }

        ul.lst-kix_kuf2cz8v3p7x-4 {
            list-style-type: none
        }

        ul.lst-kix_kuf2cz8v3p7x-7 {
            list-style-type: none
        }

        ul.lst-kix_kuf2cz8v3p7x-6 {
            list-style-type: none
        }

        .lst-kix_w9fja5od708-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_w9fja5od708-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_w9fja5od708-5>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_bdqgnzvk5mdq-0 {
            list-style-type: none
        }

        .lst-kix_w9fja5od708-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_w9fja5od708-4>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_bdqgnzvk5mdq-1 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-2 {
            list-style-type: none
        }

        .lst-kix_w9fja5od708-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_w9fja5od708-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_bdqgnzvk5mdq-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_w9fja5od708-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_bdqgnzvk5mdq-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_bdqgnzvk5mdq-7 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-8 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-3 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-4 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-5 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-6 {
            list-style-type: none
        }

        .lst-kix_w9fja5od708-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_kuf2cz8v3p7x-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_3sw0yshborkn-2 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_kbka666puyyd-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_3sw0yshborkn-3 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-4 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-5 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kbka666puyyd-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_dfd3xiy9wao-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_3sw0yshborkn-0 {
            list-style-type: none
        }

        .lst-kix_4gb6erjva6gm-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_4gb6erjva6gm-6>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_3sw0yshborkn-1 {
            list-style-type: none
        }

        .lst-kix_kuf2cz8v3p7x-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_dfd3xiy9wao-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_kuf2cz8v3p7x-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_3sw0yshborkn-6 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-7 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-8 {
            list-style-type: none
        }

        .lst-kix_4gb6erjva6gm-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_4gb6erjva6gm-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_vl80fq9xj0zy-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kbka666puyyd-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_kbka666puyyd-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_kbka666puyyd-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_4gb6erjva6gm-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_vl80fq9xj0zy-7>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_4qb661u38zts-7 {
            list-style-type: none
        }

        ul.lst-kix_4qb661u38zts-6 {
            list-style-type: none
        }

        ul.lst-kix_4qb661u38zts-8 {
            list-style-type: none
        }

        ul.lst-kix_4qb661u38zts-3 {
            list-style-type: none
        }

        ul.lst-kix_4qb661u38zts-2 {
            list-style-type: none
        }

        ul.lst-kix_4qb661u38zts-5 {
            list-style-type: none
        }

        .lst-kix_bdqgnzvk5mdq-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_4qb661u38zts-4 {
            list-style-type: none
        }

        .lst-kix_vl80fq9xj0zy-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_4qb661u38zts-1 {
            list-style-type: none
        }

        ul.lst-kix_4qb661u38zts-0 {
            list-style-type: none
        }

        .lst-kix_vl80fq9xj0zy-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kuf2cz8v3p7x-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_1mea951yss04-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_1mea951yss04-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_g187jgfw7d8u-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_g187jgfw7d8u-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_1mea951yss04-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_3sw0yshborkn-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_g187jgfw7d8u-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_3sw0yshborkn-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_3sw0yshborkn-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_akpzl7ddoxyx-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_1mea951yss04-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_g187jgfw7d8u-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_akpzl7ddoxyx-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_1mea951yss04-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_g187jgfw7d8u-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_akpzl7ddoxyx-2>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_y75wbynhs0cx-0 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-1 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-2 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-3 {
            list-style-type: none
        }

        .lst-kix_akpzl7ddoxyx-4>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_y75wbynhs0cx-4 {
            list-style-type: none
        }

        .lst-kix_akpzl7ddoxyx-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_y75wbynhs0cx-5 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-6 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-7 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-8 {
            list-style-type: none
        }

        .lst-kix_3sw0yshborkn-7>li:before {
            content: "\\0025cb   "
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        ul.lst-kix_akpzl7ddoxyx-0 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-1 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_akpzl7ddoxyx-6 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-7 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-8 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-2 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-3 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-4 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-5 {
            list-style-type: none
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c4 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #000000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000000;
            vertical-align: top;
            border-right-color: #000000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 234pt;
            border-top-color: #000000;
            border-bottom-style: solid
        }

        .c6 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 16pt;
            font-family: "Arial";
            font-style: normal
        }

        .c5 {
            padding-top: 20pt;
            padding-bottom: 6pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c16 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Arial";
            font-style: normal
        }

        .c0 {
            padding-top: 18pt;
            padding-bottom: 6pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c1 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c12 {
            padding-top: 16pt;
            padding-bottom: 4pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c20 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 20pt;
            font-family: "Arial";
            font-style: normal
        }

        .c14 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c2 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c19 {
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }

        .c8 {
            text-decoration-skip-ink: none;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c18 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c3 {
            margin-left: 36pt;
            padding-left: 0pt
        }

        .c15 {
            padding: 0;
            margin: 0
        }

        .c9 {
            color: inherit;
            text-decoration: inherit
        }

        .c13 {
            margin-left: 72pt;
            padding-left: 0pt
        }

        .c7 {
            height: 11pt
        }

        .c10 {
            background-color: #ffff00
        }

        .c17 {
            height: 0pt
        }

        .c11 {
            font-weight: 700
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c18 doc-content">
    <h1 class="c5" id="h.8f07ytlesi6c"><span class="c20">Politique de confidentialit&eacute;</span></h1>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Derni&egrave;re mise &agrave; jour : 2024-06-20</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Veuillez noter : En cas d&#39;informations ambigu&euml;s ou de divergences entre les versions, la version anglaise de cette politique pr&eacute;vaut sur la version fran&ccedil;aise. Nous vous encourageons &agrave;
            consulter la version anglaise pour les d&eacute;tails les plus pr&eacute;cis et complets.</span></p>
    <h2 class="c0" id="h.iv9cfocfkh8"><span class="c6">1. Aper&ccedil;u de la politique de confidentialit&eacute;</span></h2>
    <p class="c2"><span class="c1">PakVille.ca, exploit&eacute; par PakVille Construction Corp, sert principalement les utilisateurs au Canada et aux &Eacute;tats-Unis. Cette politique d&eacute;crit comment PakVille Construction Corp (le &quot;Site&quot;,
            &quot;nous&quot;) collecte, utilise et divulgue vos informations personnelles lorsque vous visitez, utilisez nos services, demandez un devis ou nous contactez via pakville.ca (le &quot;Site&quot;) ou communiquez autrement avec nous
            (collectivement, les &quot;Services&quot;). Aux fins de cette politique, &quot;vous&quot; d&eacute;signe l&#39;utilisateur des Services, que vous soyez client, visiteur du site ou autre individu dont les informations ont &eacute;t&eacute;
            collect&eacute;es conform&eacute;ment &agrave; cette politique.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Veuillez lire attentivement cette politique. En utilisant et acc&eacute;dant aux Services, vous acceptez la collecte, l&#39;utilisation et la divulgation de vos informations telles que d&eacute;crites dans cette
            politique. Si vous n&#39;&ecirc;tes pas d&#39;accord avec cette politique, veuillez ne pas utiliser ou acc&eacute;der aux Services.</span></p>
    <h2 class="c0" id="h.6rsyxm9jc5of"><span class="c6">2. Modifications de cette politique de confidentialit&eacute;</span></h2>
    <p class="c2"><span class="c1">Nous pouvons mettre &agrave; jour cette politique de temps &agrave; autre, notamment pour refl&eacute;ter les changements dans nos pratiques ou pour des raisons op&eacute;rationnelles, l&eacute;gales ou
            r&eacute;glementaires. Nous publierons la politique r&eacute;vis&eacute;e sur le site, mettrons &agrave; jour la date de &quot;derni&egrave;re mise &agrave; jour&quot; et prendrons toute autre mesure requise par la loi applicable.</span>
    </p>
    <h2 class="c0" id="h.6b292panjcu9"><span class="c6">3. Comment nous collectons et utilisons vos informations personnelles</span></h2>
    <p class="c2"><span class="c1">Pour fournir les Services, nous collectons et avons collect&eacute; au cours des 12 derniers mois des informations personnelles vous concernant &agrave; partir de diverses sources, comme indiqu&eacute; ci-dessous.
            Les informations que nous collectons et utilisons varient selon votre interaction avec nous.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">En plus des utilisations sp&eacute;cifiques mentionn&eacute;es ci-dessous, nous pouvons utiliser les informations que nous collectons &agrave; votre sujet pour communiquer avec vous, fournir les Services, respecter
            les obligations l&eacute;gales applicables, faire respecter les conditions de service applicables et prot&eacute;ger ou d&eacute;fendre les Services, nos droits, et les droits de nos utilisateurs ou autres.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span>Nous utilisons des mesures organisationnelles, techniques et administratives ad&eacute;quates pour s&eacute;curiser les informations au sein de notre entreprise. Nous mettons en &oelig;uvre le chiffrement pour les
            donn&eacute;es au repos, en stockage et pendant la transmission pour assurer la protection de nos bases de donn&eacute;es. Vous pouvez consulter les mesures de s&eacute;curit&eacute; d&#39;Amazon Web Services </span><span class="c8"><a
                class="c9" href="https://aws.amazon.com/fr/compliance/iso-certified" target="_blank">ici</a></span><span>&nbsp;et explorer leurs
            informations sur la protection des donn&eacute;es et la confidentialit&eacute; </span><span class="c8"><a class="c9"
                href="https://aws.amazon.com/fr/compliance/data-protection" target="_blank">ici</a></span><span>.</span></p>
    <h2 class="c0" id="h.bffxntz6m1js"><span class="c6">4. Quelles informations personnelles nous collectons</span></h2>
    <p class="c2"><span class="c1">Les types d&#39;informations personnelles que nous obtenons &agrave; votre sujet d&eacute;pendent de la mani&egrave;re dont vous interagissez avec notre Site et utilisez nos Services. Lorsque nous utilisons le terme
            &quot;informations personnelles&quot;, nous faisons r&eacute;f&eacute;rence aux informations qui vous identifient, se rapportent &agrave; vous, vous d&eacute;crivent ou peuvent &ecirc;tre associ&eacute;es &agrave; vous. Les sections
            suivantes d&eacute;crivent les cat&eacute;gories et types sp&eacute;cifiques d&#39;informations personnelles que nous collectons.</span></p>
    <h3 class="c12" id="h.nqaqe6jlfykw"><span class="c16">4.1 Informations que nous collectons directement de vous</span></h3>
    <p class="c2"><span class="c1">Les informations que vous soumettez directement &agrave; nous via nos Services peuvent inclure :</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <ul class="c15 lst-kix_4iorh8rmmrxk-0 start">
        <li class="c2 c3 li-bullet-0"><span class="c1">D&eacute;tails de contact de base, y compris votre nom, adresse, num&eacute;ro de t&eacute;l&eacute;phone, email.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Informations de devis, y compris votre nom, adresse de livraison, adresse email, num&eacute;ro de t&eacute;l&eacute;phone.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Informations d&#39;achat, y compris les articles que vous consultez ou ajoutez &agrave; votre liste de souhaits.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Informations de support client, y compris les informations que vous choisissez d&#39;inclure dans les communications avec nous, par exemple, lors de l&#39;envoi d&#39;un message via
                Contactez-Nous.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Certaines fonctionnalit&eacute;s des Services peuvent vous obliger &agrave; nous fournir certaines informations vous concernant. Vous pouvez choisir de ne pas fournir ces informations, mais cela
                peut vous emp&ecirc;cher d&#39;utiliser ou d&#39;acc&eacute;der &agrave; ces fonctionnalit&eacute;s.</span></li>
    </ul>
    <h3 class="c12" id="h.di2h63a1e3pr"><span class="c16">4.2 Informations que nous recueillons &agrave; travers les cookies</span></h3>
    <p class="c2"><span class="c1">Nous recueillons &eacute;galement automatiquement certaines informations sur votre interaction avec les Services (&laquo; Donn&eacute;es d&#39;utilisation &raquo;). Pour ce faire, nous pouvons utiliser des cookies,
            des pixels et des technologies similaires (&laquo; Cookies &raquo;). Les Donn&eacute;es d&#39;utilisation peuvent inclure des informations sur la mani&egrave;re dont vous acc&eacute;dez et utilisez notre Site et votre compte, y compris
            des informations sur votre appareil, votre navigateur, des informations sur votre connexion r&eacute;seau, votre adresse IP et d&#39;autres informations concernant votre interaction avec les Services.</span></p>
    <h3 class="c12" id="h.3p3c52w3bxla"><span class="c16">4.3 Informations que nous obtenons de tiers</span></h3>
    <p class="c2"><span class="c1">Enfin, nous pouvons obtenir des informations vous concernant de la part de tiers, y compris de fournisseurs et de prestataires de services qui peuvent collecter des informations en notre nom, tels que :</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <ul class="c15 lst-kix_srzc9u7qrlgb-0 start">
        <li class="c2 c3 li-bullet-0"><span class="c1">Des entreprises qui soutiennent notre Site et nos Services, tels qu&#39;AWS, Google Analytics, Meta Business Suite, Calendly.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Lorsque vous visitez notre Site, ouvrez ou cliquez sur des emails que nous vous envoyons, ou interagissez avec nos Services ou publicit&eacute;s, nous, ou des tiers avec lesquels nous
                travaillons, pouvons automatiquement collecter certaines informations en utilisant des technologies de suivi en ligne telles que des pixels, des balises web, des kits de d&eacute;veloppement logiciel, des biblioth&egrave;ques tierces
                et des cookies.</span></li>
    </ul>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Toute information que nous obtenons de tiers sera trait&eacute;e conform&eacute;ment &agrave; cette Politique de Confidentialit&eacute;. Nous ne sommes pas responsables de l&#39;exactitude des informations fournies
            par les tiers et ne sommes pas responsables des politiques ou pratiques de ces tiers. Pour plus d&#39;informations, voir la section ci-dessous, Sites Web tiers et Liens.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Amazon Web Services :</span></p>
    <p class="c2"><span>Nous utilisons Amazon Web Services pour envoyer des emails transactionnels, qui incluent des devis et des confirmations de commande. Au cours de cette proc&eacute;dure, nous fournissons &agrave; AWS l&#39;adresse email du
            destinataire et un aper&ccedil;u de la commande. Pour des informations d&eacute;taill&eacute;es sur les pratiques de confidentialit&eacute; d&#39;AWS, vous pouvez consulter leur politique de confidentialit&eacute; </span><span
            class="c8"><a class="c9" href="https://aws.amazon.com/compliance/data-protection">ici</a></span><span
            class="c1">.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Google Analytics :</span></p>
    <p class="c2"><span>Google Analytics est un service d&#39;analyse web propos&eacute; par Google qui suit et rapporte le trafic des sites web. Google utilise les donn&eacute;es collect&eacute;es pour suivre et surveiller l&#39;utilisation de nos
            Services. Ces donn&eacute;es sont partag&eacute;es avec d&#39;autres services Google. Google peut utiliser les donn&eacute;es collect&eacute;es pour contextualiser et personnaliser les annonces de son propre r&eacute;seau publicitaire.
            Vous pouvez consulter leur politique de confidentialit&eacute; </span><span class="c8"><a class="c9"
                href="https://policies.google.com/privacy?hl=fr-CA" target="_blank">ici</a></span><span class="c1">.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
      
    <p class="c2"><span class="c1">Meta Business Suite :</span></p>
    <p class="c2"><span class="c2">Meta Business Suite est un outil complet fourni par Meta Platforms, Inc. qui permet aux entreprises de g&eacute;rer leurs interactions sur les r&eacute;seaux sociaux, leurs campagnes publicitaires et leurs analyses
            sur Facebook et Instagram. Elle aide &agrave; rationaliser les communications et les efforts de marketing &agrave; partir d&#39;une seule interface. Nous utilisons Meta Business Suite pour am&eacute;liorer nos strat&eacute;gies de
            marketing et interagir plus efficacement avec notre public. Pour des informations d&eacute;taill&eacute;es sur les pratiques de confidentialit&eacute; de Meta Business Suite, veuillez consulter leur politique de confidentialit&eacute;
        </span><span class="c8"><a class="c9"
                href="https://developers.facebook.com/docs/meta-pixel/guides/terms-and-policies/?locale=fr_FR">ici</a></span><span
            class="c2 c7">.</span></p>
            <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Calendly :</span></p>
    <p class="c2"><span>Calendly est une entreprise de logiciels qui d&eacute;veloppe une plateforme de communication commerciale utilis&eacute;e par les &eacute;quipes pour planifier, pr&eacute;parer et suivre des r&eacute;unions externes. Vous
            pouvez consulter leur politique de confidentialit&eacute; </span><span class="c8"><a class="c9"
                href="https://calendly.com/privacy">ici</a></span><span class="c1">.</span></p>
                
    
                <h3 class="c12" id="h.3p3c52w3bxla"><span class="c16">4.4 Informations sur le traitement des paiements</span></h3>
                <p class="c2"><span class="c7">Nous utilisons Stripe, un service de traitement des paiements de premier plan, pour g&eacute;rer de mani&egrave;re s&eacute;curis&eacute;e toutes les transactions dans notre boutique en ligne. Lorsque vous effectuez
                        un achat, Stripe prend en charge le traitement de vos d&eacute;tails de paiement, y compris les informations de votre carte de cr&eacute;dit. Ce processus implique le cryptage de vos donn&eacute;es de carte de cr&eacute;dit pour en
                        assurer la s&eacute;curit&eacute; et la confidentialit&eacute; lors de leur transmission sur Internet. Les mesures de s&eacute;curit&eacute; avanc&eacute;es de Stripe sont conformes aux normes industrielles les plus &eacute;lev&eacute;es
                        pour prot&eacute;ger vos informations personnelles contre l&#39;acc&egrave;s et l&#39;utilisation non autoris&eacute;s. Pour plus de d&eacute;tails sur la fa&ccedil;on dont Stripe traite et prot&egrave;ge vos donn&eacute;es personnelles,
                        veuillez consulter la politique de confidentialit&eacute; de Stripe </span><span class="c8"><a class="c9"
                        href="https://stripe.com/fr-ca/privacy" target="_blank">ici</a></span><span class="c1">.</span></p>

    <h2 class="c0" id="h.2s5hdi63bwrq"><span class="c6">5. Comment nous utilisons vos informations personnelles</span></h2>
    <ul class="c15 lst-kix_kuf2cz8v3p7x-0 start">
        <li class="c2 c3 li-bullet-0"><span class="c11">Fourniture de produits et services.</span><span class="c1">&nbsp;Nous utilisons vos informations personnelles pour vous fournir les Services afin d&#39;ex&eacute;cuter notre contrat avec vous, y
                compris pour remplir vos commandes, pour envoyer des notifications li&eacute;es &agrave; votre compte, vos achats, retours, &eacute;changes ou autres transactions, pour cr&eacute;er, maintenir et g&eacute;rer votre compte, pour
                organiser les exp&eacute;ditions, faciliter les retours et &eacute;changes et vous permettre de publier des avis.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Marketing et publicit&eacute;. </span><span class="c1">Nous utilisons vos informations personnelles &agrave; des fins de marketing et de promotion, telles que pour vous envoyer des
                communications marketing, publicitaires et promotionnelles par courriel, message texte ou courrier postal, et pour vous montrer des publicit&eacute;s pour des produits ou services. Cela peut inclure l&#39;utilisation de vos
                informations personnelles pour mieux adapter les Services et la publicit&eacute; sur notre Site et d&#39;autres sites web.</span></li>
    </ul>
    <ul class="c15 lst-kix_kuf2cz8v3p7x-1 start">
        <li class="c2 c13 li-bullet-0"><span class="c1">Sachez que vous avez la possibilit&eacute; de vous d&eacute;sabonner de nos messages marketing. Cela peut se faire en s&eacute;lectionnant l&#39;option &#39;se d&eacute;sabonner&#39;
                trouv&eacute;e au bas de nos courriels. Alternativement, vous pouvez nous contacter directement pour demander le retrait de notre liste de diffusion.</span></li>
    </ul>
    <ul class="c15 lst-kix_kuf2cz8v3p7x-0">
        <li class="c2 c3 li-bullet-0"><span class="c11">S&eacute;curit&eacute; et pr&eacute;vention de la fraude. </span><span class="c1">Nous utilisons vos informations personnelles pour d&eacute;tecter, enqu&ecirc;ter ou prendre des mesures
                concernant des activit&eacute;s potentiellement frauduleuses, ill&eacute;gales ou malveillantes.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Communication avec vous. </span><span class="c1">Nous utilisons vos informations personnelles pour vous fournir un support client et am&eacute;liorer nos Services. Cela est dans notre
                int&eacute;r&ecirc;t l&eacute;gitime pour &ecirc;tre r&eacute;actif envers vous, pour vous fournir des services efficaces et pour maintenir notre relation commerciale avec vous.</span></li>
    </ul>
    <h2 class="c0" id="h.1hv6bd1x2j2i"><span class="c6">6. Cookies</span></h2>
    <p class="c2"><span class="c1">Comme de nombreux sites web, nous utilisons des Cookies sur notre Site. Nous utilisons des Cookies pour alimenter et am&eacute;liorer notre Site et nos Services (y compris pour se souvenir de vos actions et
            pr&eacute;f&eacute;rences), pour ex&eacute;cuter des analyses et mieux comprendre l&#39;interaction des utilisateurs avec les Services (dans notre int&eacute;r&ecirc;t l&eacute;gitime pour administrer, am&eacute;liorer et optimiser les
            Services). Nous pouvons &eacute;galement permettre &agrave; des tiers et &agrave; des prestataires de services d&#39;utiliser des Cookies sur notre Site pour mieux adapter les services, les produits et la publicit&eacute; sur notre Site
            et d&#39;autres sites web.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">La plupart des navigateurs acceptent automatiquement les Cookies par d&eacute;faut, mais vous pouvez choisir de configurer votre navigateur pour supprimer ou rejeter les Cookies via les contr&ocirc;les de votre
            navigateur. Veuillez garder &agrave; l&#39;esprit que la suppression ou le blocage des Cookies peut avoir un impact n&eacute;gatif sur votre exp&eacute;rience utilisateur et peut faire en sorte que certains des Services, y compris
            certaines fonctionnalit&eacute;s et fonctionnalit&eacute;s g&eacute;n&eacute;rales, fonctionnent incorrectement ou ne soient plus disponibles. De plus, bloquer les Cookies peut ne pas emp&ecirc;cher compl&egrave;tement la mani&egrave;re
            dont nous partageons les informations avec des tiers tels que nos partenaires publicitaires.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span>Vous pouvez choisir de ne pas partager vos donn&eacute;es d&#39;utilisation du Service avec Google Analytics en installant leur extension de navigateur d&#39;opt-out. Cette extension emp&ecirc;che le JavaScript de Google
            Analytics (ga.js, analytics.js et dc.js) d&#39;envoyer vos informations de visite &agrave; Google Analytics. Pour plus d&#39;informations sur les politiques de confidentialit&eacute; de Google, veuillez consulter leur page
            web</span><span>&nbsp;Privacy &amp; Terms </span><span class="c8"><a class="c9"
                href="https://policies.google.com/privacy?hl=fr-CA" target="_blank">ici</a></span><span class="c1">.</span></p>
    <h2 class="c0" id="h.ljrd3lo0s90p"><span class="c6">7. Comment nous divulguons les informations personnelles</span></h2>
    <p class="c2"><span class="c1">Dans certaines circonstances, nous pouvons divulguer vos informations personnelles &agrave; des tiers &agrave; des fins l&eacute;gitimes, sous r&eacute;serve de cette Politique de Confidentialit&eacute;. Ces
            circonstances peuvent inclure :</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <ul class="c15 lst-kix_g187jgfw7d8u-0 start">
        <li class="c2 c3 li-bullet-0"><span class="c1">Avec des fournisseurs ou d&#39;autres tiers qui fournissent des services en notre nom (par exemple, gestion informatique, analyse de donn&eacute;es, support client, stockage en nuage,
                ex&eacute;cution et exp&eacute;dition).</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Avec des partenaires commerciaux et marketing, pour fournir des services et vous faire de la publicit&eacute;. Nos partenaires commerciaux et marketing utiliseront vos informations
                conform&eacute;ment &agrave; leurs propres avis de confidentialit&eacute;.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Lorsque vous nous dirigez, nous demandez ou consentez autrement &agrave; notre divulgation de certaines informations &agrave; des tiers, telles que pour vous exp&eacute;dier des produits ou par
                votre utilisation de widgets de m&eacute;dias sociaux ou d&#39;int&eacute;grations de connexion, avec votre consentement.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Avec nos filiales ou autrement au sein de notre groupe corporatif, dans notre int&eacute;r&ecirc;t l&eacute;gitime pour diriger une entreprise prosp&egrave;re.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">En relation avec une transaction commerciale telle qu&#39;une fusion ou une faillite, pour se conformer &agrave; toute obligation l&eacute;gale applicable (y compris pour r&eacute;pondre &agrave;
                des assignations &agrave; compara&icirc;tre, des mandats de perquisition et des demandes similaires), pour faire respecter tout terme de service applicable, et pour prot&eacute;ger ou d&eacute;fendre les Services, nos droits, et les
                droits de nos utilisateurs ou d&#39;autres.</span></li>
    </ul>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Nous avons, au cours des 12 derniers mois, divulgu&eacute; les cat&eacute;gories suivantes d&#39;informations personnelles et d&#39;informations personnelles sensibles (d&eacute;sign&eacute;es par *) sur les
            utilisateurs aux fins &eacute;nonc&eacute;es ci-dessus dans &quot;Comment nous collectons et utilisons vos informations personnelles&quot; et &quot;Comment nous divulguons les informations personnelles&quot; :</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2 c7"><span class="c1"></span></p><a id="t.5b65e489e5d88b6a00ee598e31820fa1ce020edb"></a><a id="t.0"></a>
    <table class="c19">
        <tr class="c17">
            <td class="c4" colspan="1" rowspan="1">
                <p class="c14"><span class="c1">Cat&eacute;gorie</span></p>
            </td>
            <td class="c4" colspan="1" rowspan="1">
                <p class="c14"><span class="c1">Cat&eacute;gories de destinataires</span></p>
            </td>
        </tr>
        <tr class="c17">
            <td class="c4" colspan="1" rowspan="1">
                <ul class="c15 lst-kix_4gb6erjva6gm-0 start">
                    <li class="c14 c3 li-bullet-0"><span class="c1">Identifiants tels que les coordonn&eacute;es de base et certaines informations de commande et de compte</span></li>
                    <li class="c3 c14 li-bullet-0"><span class="c1">Informations commerciales telles que les informations de commande, les informations d&#39;achat et les informations de support client</span></li>
                    <li class="c14 c3 li-bullet-0"><span class="c1">Activit&eacute; Internet ou autre activit&eacute; de r&eacute;seau similaire, telles que les Donn&eacute;es d&#39;utilisation</span></li>
                </ul>
            </td>
            <td class="c4" colspan="1" rowspan="1">
                <ul class="c15 lst-kix_w9fja5od708-0 start">
                    <li class="c14 c3 li-bullet-0"><span class="c1">Fournisseurs et tiers qui fournissent des services en notre nom (tels que les fournisseurs de services Internet, les partenaires d&#39;ex&eacute;cution, les partenaires de support
                            client et les fournisseurs d&#39;analyse de donn&eacute;es)</span></li>
                    <li class="c14 c3 li-bullet-0"><span class="c1">Partenaires commerciaux et marketing</span></li>
                    <li class="c14 c3 li-bullet-0"><span class="c1">Filiales</span></li>
                </ul>
            </td>
        </tr>
    </table>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Nous n&#39;utilisons ni ne divulguons d&#39;informations personnelles sensibles dans le but d&#39;inf&eacute;rer des caract&eacute;ristiques &agrave; votre sujet.</span></p>
    <h2 class="c0" id="h.c79wur25e5fl"><span class="c6">8. Contenu g&eacute;n&eacute;r&eacute; par l&#39;utilisateur</span></h2>
    <p class="c2"><span class="c1">Les Services peuvent vous permettre de publier des avis sur des produits et d&#39;autres contenus g&eacute;n&eacute;r&eacute;s par les utilisateurs. Si vous choisissez de soumettre du contenu
            g&eacute;n&eacute;r&eacute; par l&#39;utilisateur &agrave; toute zone publique des Services, ce contenu sera public et accessible par n&#39;importe qui.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Nous ne contr&ocirc;lons pas qui aura acc&egrave;s aux informations que vous choisissez de rendre disponibles aux autres, et ne pouvons pas garantir que les parties qui ont acc&egrave;s &agrave; de telles
            informations respecteront votre vie priv&eacute;e ou la garderont s&eacute;curis&eacute;e. Nous ne sommes pas responsables de la confidentialit&eacute; ou de la s&eacute;curit&eacute; de toute information que vous rendez publique, ni de
            l&#39;exactitude, de l&#39;utilisation ou du m&eacute;susage de toute information que vous divulguez ou recevez de tiers.</span></p>
    <h2 class="c0" id="h.r6n0qpxz0d15"><span class="c6">9. Sites web tiers et liens</span></h2>
    <p class="c2"><span class="c1">Notre Site peut fournir des liens vers des sites web ou d&#39;autres plateformes en ligne exploit&eacute;es par des tiers. Si vous suivez des liens vers des sites non affili&eacute;s ou contr&ocirc;l&eacute;s par
            nous, vous devriez examiner leurs politiques de confidentialit&eacute; et de s&eacute;curit&eacute; ainsi que d&#39;autres termes et conditions. Nous ne garantissons pas et ne sommes pas responsables de la confidentialit&eacute; ou de la
            s&eacute;curit&eacute; de tels sites, y compris de l&#39;exactitude, de l&#39;exhaustivit&eacute; ou de la fiabilit&eacute; des informations trouv&eacute;es sur ces sites. Les informations que vous fournissez sur des lieux publics ou
            semi-publics, y compris les informations que vous partagez sur des plateformes de r&eacute;seautage social tiers, peuvent &eacute;galement &ecirc;tre visibles par d&#39;autres utilisateurs des Services et/ou utilisateurs de ces
            plateformes tierces sans limitation quant &agrave; son utilisation par nous ou par un tiers. Notre inclusion de tels liens n&#39;implique pas, en soi, une approbation du contenu sur ces plateformes ou de leurs propri&eacute;taires ou
            op&eacute;rateurs, sauf tel qu&#39;indiqu&eacute; sur les Services.</span></p>
    <h2 class="c0" id="h.pc24yoyuyn7n"><span class="c6">10. Donn&eacute;es sur les enfants</span></h2>
    <p class="c2"><span class="c1">Les Services ne sont pas destin&eacute;s &agrave; &ecirc;tre utilis&eacute;s par des enfants, et nous ne collectons pas sciemment d&#39;informations personnelles sur les enfants. Si vous &ecirc;tes le parent ou le
            tuteur d&#39;un enfant qui nous a fourni ses informations personnelles, vous pouvez nous contacter en utilisant les coordonn&eacute;es ci-dessous pour demander qu&#39;elles soient supprim&eacute;es.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">&Agrave; compter de la date d&#39;entr&eacute;e en vigueur de cette Politique de Confidentialit&eacute;, nous n&#39;avons pas connaissance r&eacute;elle que nous &laquo; partageons &raquo; ou &laquo; vendons &raquo;
            (comme ces termes sont d&eacute;finis dans la loi applicable) des informations personnelles d&#39;individus de moins de 16 ans.</span></p>
    <h2 class="c0" id="h.krggciwjke7q"><span class="c6">11. S&eacute;curit&eacute; et conservation de vos informations</span></h2>
    <p class="c2"><span class="c1">Sachez qu&#39;aucune mesure de s&eacute;curit&eacute; n&#39;est parfaite ou imp&eacute;n&eacute;trable, et nous ne pouvons garantir une &laquo; s&eacute;curit&eacute; parfaite &raquo;. De plus, toute information que
            vous nous envoyez peut ne pas &ecirc;tre s&eacute;curis&eacute;e pendant le transit. Nous vous recommandons de ne pas utiliser de canaux non s&eacute;curis&eacute;s pour communiquer des informations sensibles ou confidentielles avec
            nous.</span></p>
    <p class="c2"><span class="c1">La dur&eacute;e pendant laquelle nous conservons vos informations personnelles d&eacute;pend de diff&eacute;rents facteurs, tels que si nous avons besoin des informations pour maintenir votre compte, pour fournir
            les Services, se conformer aux obligations l&eacute;gales, r&eacute;soudre des litiges ou faire respecter d&#39;autres contrats et politiques applicables.</span></p>
    <h2 class="c0" id="h.nzjyyxf7lkqw"><span class="c6">12. Vos droits et choix</span></h2>
    <p class="c2"><span class="c1">Selon votre lieu de r&eacute;sidence, vous pouvez avoir certains ou tous les droits &eacute;num&eacute;r&eacute;s ci-dessous en relation avec vos informations personnelles. Cependant, ces droits ne sont pas absolus,
            peuvent s&#39;appliquer uniquement dans certaines circonstances et, dans certains cas, nous pouvons refuser votre demande comme le permet la loi.</span></p>
    <ul class="c15 lst-kix_4qb661u38zts-0 start">
        <li class="c2 c3 li-bullet-0"><span class="c11">Droit d&#39;acc&egrave;s / de connaissance. </span><span class="c1">Vous pouvez avoir le droit de demander l&#39;acc&egrave;s aux informations personnelles que nous d&eacute;tenons &agrave;
                votre sujet, y compris des d&eacute;tails relatifs aux mani&egrave;res dont nous utilisons et partageons vos informations.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Droit de suppression.</span><span class="c1">&nbsp;Vous pouvez avoir le droit de demander que nous supprimions les informations personnelles que nous maintenons &agrave; votre sujet.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Droit de correction. </span><span class="c1">Vous pouvez avoir le droit de demander que nous corrigions les informations personnelles inexactes que nous maintenons &agrave; votre sujet.</span>
        </li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Droit de portabilit&eacute;.</span><span class="c1">&nbsp;Vous pouvez avoir le droit de recevoir une copie des informations personnelles que nous d&eacute;tenons &agrave; votre sujet et de
                demander que nous les transf&eacute;rions &agrave; un tiers, dans certaines circonstances et avec certaines exceptions.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Droit de refuser la vente ou le partage ou la publicit&eacute; cibl&eacute;e. </span><span class="c1">Vous pouvez avoir le droit de nous dire de ne pas &laquo; vendre &raquo; ou &laquo; partager
                &raquo; vos informations personnelles ou de refuser le traitement de vos informations personnelles &agrave; des fins consid&eacute;r&eacute;es comme de la &laquo; publicit&eacute; cibl&eacute;e &raquo;, telles que d&eacute;finies dans
                les lois sur la vie priv&eacute;e applicables. Veuillez noter que si vous visitez notre Site avec le signal de pr&eacute;f&eacute;rence d&#39;opt-out de Global Privacy Control activ&eacute;, selon votre emplacement, nous traiterons
                automatiquement cela comme une demande d&#39;opt-out de la &laquo; vente &raquo; ou du &laquo; partage &raquo; d&#39;informations pour l&#39;appareil et le navigateur que vous utilisez pour visiter le Site.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Droit de limiter et/ou de refuser l&#39;utilisation et la divulgation d&#39;informations personnelles sensibles.</span><span class="c1">&nbsp;Vous pouvez avoir le droit de nous demander de
                limiter notre utilisation et/ou divulgation d&#39;informations personnelles sensibles &agrave; ce qui est n&eacute;cessaire pour effectuer les Services ou fournir les biens raisonnablement attendus par un individu moyen.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Restrictions de traitement. </span><span class="c1">Vous pouvez avoir le droit de nous demander d&#39;arr&ecirc;ter ou de restreindre notre traitement de vos informations personnelles.</span>
        </li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Retrait du consentement.</span><span class="c1">&nbsp;Lorsque nous nous appuyons sur le consentement pour traiter vos informations personnelles, vous pouvez avoir le droit de retirer ce
                consentement.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Appel.</span><span class="c1">&nbsp;Vous pouvez avoir le droit de faire appel de notre d&eacute;cision si nous refusons de traiter votre demande. Vous pouvez le faire en r&eacute;pondant
                directement &agrave; notre refus.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c11">Gestion des pr&eacute;f&eacute;rences de communication.</span><span class="c1">&nbsp;Nous pouvons vous envoyer des courriels promotionnels, et vous pouvez vous d&eacute;sabonner de ceux-ci
                &agrave; tout moment en utilisant l&#39;option de d&eacute;sabonnement affich&eacute;e dans nos courriels &agrave; vous. Si vous vous d&eacute;sabonnez, nous pouvons encore vous envoyer des courriels non promotionnels, tels que ceux
                concernant votre compte ou les commandes que vous avez pass&eacute;es.</span></li>
    </ul>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Vous pouvez exercer l&#39;un de ces droits l&agrave; o&ugrave; il est indiqu&eacute; sur notre Site ou en nous contactant en utilisant les coordonn&eacute;es fournies ci-dessous.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Nous ne ferons pas de discrimination &agrave; votre &eacute;gard pour l&#39;exercice de l&#39;un de ces droits. Nous pouvons avoir besoin de collecter des informations aupr&egrave;s de vous pour v&eacute;rifier
            votre identit&eacute;, telles que votre adresse e-mail ou les informations de votre compte, avant de fournir une r&eacute;ponse substantielle &agrave; la demande. Conform&eacute;ment aux lois applicables, vous pouvez d&eacute;signer un
            agent autoris&eacute; pour faire des demandes en votre nom pour exercer vos droits. Avant d&#39;accepter une telle demande d&#39;un agent, nous exigerons que l&#39;agent fournisse une preuve que vous l&#39;avez autoris&eacute; &agrave;
            agir en votre nom, et nous pourrions avoir besoin que vous v&eacute;rifiiez votre identit&eacute; directement avec nous. Nous r&eacute;pondrons &agrave; votre demande de mani&egrave;re opportune, comme l&#39;exige la loi
            applicable.</span></p>
    <h2 class="c0" id="h.vpmsy3r98toi"><span class="c6">13. Plaintes</span></h2>
    <p class="c2"><span class="c1">Si vous avez des plaintes concernant la fa&ccedil;on dont nous traitons vos informations personnelles, veuillez nous contacter en utilisant les coordonn&eacute;es fournies ci-dessous. Si vous n&#39;&ecirc;tes pas
            satisfait de notre r&eacute;ponse &agrave; votre plainte, selon votre lieu de r&eacute;sidence, vous pouvez avoir le droit de faire appel de notre d&eacute;cision en nous contactant &agrave; l&#39;aide des coordonn&eacute;es
            indiqu&eacute;es ci-dessous, ou de d&eacute;poser votre plainte aupr&egrave;s de votre autorit&eacute; locale de protection des donn&eacute;es.</span></p>
    <h2 class="c0" id="h.pbm170gt91ng"><span class="c6">14. Utilisateurs internationaux</span></h2>
    <p class="c2"><span class="c1">Veuillez noter que nous pouvons transf&eacute;rer, stocker et traiter vos informations personnelles dans des pays autres que le v&ocirc;tre, y compris au Canada et aux &Eacute;tats-Unis. Notre personnel et les
            prestataires de services tiers et partenaires, qui peuvent &ecirc;tre situ&eacute;s dans ces pays, traitent &eacute;galement vos informations personnelles.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Nous prenons les mesures appropri&eacute;es pour garantir que tout transfert d&#39;informations personnelles est conforme aux lois de protection des donn&eacute;es applicables et est ad&eacute;quatement
            sauvegard&eacute;.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
    <ul class="c15 lst-kix_vl80fq9xj0zy-0 start">
        <li class="c2 c3 li-bullet-0"><span class="c1">Pour les utilisateurs au Canada, nous adh&eacute;rons aux principes de la Loi sur la protection des renseignements personnels et les documents &eacute;lectroniques (LPRPDE) lors du transfert de
                donn&eacute;es personnelles.</span></li>
        <li class="c2 c3 li-bullet-0"><span class="c1">Pour les utilisateurs aux &Eacute;tats-Unis, nous assurons la conformit&eacute; avec les lois f&eacute;d&eacute;rales et &eacute;tatiques pertinentes sur la confidentialit&eacute; lors des
                transferts transfrontaliers de donn&eacute;es.</span></li>
    </ul>
    <p class="c2 c7"><span class="c1"></span></p>
    <p class="c2"><span class="c1">Dans tous les cas, notre objectif est de fournir un niveau de protection de vos informations personnelles qui soit coh&eacute;rent avec et respectueux des lois sur la confidentialit&eacute; applicables dans votre
            juridiction.</span></p>
    <h2 class="c0" id="h.2lf7vwlnukqv"><span class="c6">15. Contact</span></h2>
    <p class="c2"><span>Si vous avez des questions sur nos pratiques de confidentialit&eacute; ou cette Politique de Confidentialit&eacute;, ou si vous souhaitez exercer l&#39;un des droits qui vous sont disponibles, veuillez appeler au +1 514 885
            1361 ou nous envoyer un courriel &agrave; </span><span class="c8"><a class="c9" href="mailto:info@pakville.ca">info@pakville.ca</a></span><span class="c1">.</span></p>
    <p class="c2 c7"><span class="c1"></span></p>
</body>

</html>
`