import { Container, Row, Col, Image } from "react-bootstrap";
import i18n from "../../i18n";
import { AnimationOnScroll } from 'react-animation-on-scroll';


const PakVertPanelsScreen: React.FC = () => {

    const { t, language} = i18n;

    return (<>
        <Container fluid className="screenContainer infoDarkContainer" style={{ minHeight: '50vh' }}>

            <Container className="flexContainer pakvertContainer">
                <Row className="gx-5">
                    <Col xs={12} lg={7}>
                        <div className="flexContainer" style={{ gap: '8px' }}>
                            <h2>{t('_technology_screen.1.title')}</h2>
                            <p>{t('_technology_screen.1.description')}</p>
                        </div>
                    </Col>

                    <Col xs={12} lg={5}>
                        <div className="flexContainer">
                            <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce>
                                <Image src={`/images/technology/PakVert_Panel_Layers_${language}.png`} rounded className="mw-100" alt={`${t('_technology_screen.1.title')}`} />
                            </AnimationOnScroll>
                        </div>
                    </Col>
                </Row>

            </Container>

        </Container>
    </>)
}

export default PakVertPanelsScreen;