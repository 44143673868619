import i18n from "../i18n"
import { Container } from "react-bootstrap";
import { termsEn } from "../data/terms-en.html"
import { termsFr } from "../data/terms-fr.html"

export default function TermsOfService() {

    const { language } = i18n;

    return (<>

        <Container>

            {language === 'fr' ?
                <div
                    dangerouslySetInnerHTML={{ __html: termsFr }}
                />
                :
                <div
                    dangerouslySetInnerHTML={{ __html: termsEn }}
                />
            }

        </Container>

    </>)
}
