import { Container, Row, Col, Button } from "react-bootstrap";
import i18n from "../i18n";

const calendarLink = process.env.REACT_APP_CALENDAR_LINK || '';

const ScheduleComponent: React.FC = () => {

    const { t } = i18n;

    return (<>

        <Container style={{ backgroundColor: '#F9F8F8', textAlign: 'center', padding: '2.5rem 2rem', margin:'3rem auto', borderRadius: '20px' }}>
            <Row >
                <Col>
                    <h1>{t('schedule_title', { name: 'PakVille' })}</h1>
                    <p className="mt-4 mb-4">{t('schedule_description')}</p>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                    <Button ><a href={calendarLink} target="_blank" rel="noreferrer">{t('schedule_a_call')}</a></Button>
                </Col>
            </Row>
        </Container>


    </>)
}

export default ScheduleComponent;