import { Container } from "react-bootstrap";

interface ContainerDividerComponentProps {
    title: string,
    class?: string
}

const ContainerDivider: React.FC<ContainerDividerComponentProps> = (props) => {

    return (<>
        <Container fluid className={props.class || 'container-divider'}>
            <h2 className="h2">{props.title}</h2>
        </Container>
    </>)
}

export default ContainerDivider;