import BannerComponent from "../components/Banner.component";
import DocumentComponent from "../components/Document.component";
import ScheduleComponent from "../components/Schedule.component";
import i18n from "../i18n"
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import { IPress } from "../interfaces/IPress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

export default function MediaCoverage() {

    const { t, language } = i18n;

    const items: IPress[] = [

        {
            date: new Date(2024, 6, 12),
            logo: "/images/press/betakit.png",
            publisher: "Betakit",
            color: "#000000"
        },

        {
            date: new Date(2024, 1, 10),
            logo: "/images/press/la-presse.svg",
            publisher: "La Presse",
            color: "#ed1c23"
        },

        {
            date: new Date(2024, 0, 16),
            logo: "/images/press/noovomoi.svg",
            publisher: "Noovo Moi",
            color: "#fcfbf6"
        },

        {
            date: new Date(2024, 0, 9),
            logo: "/images/press/quoifaireenfamille.svg",
            publisher: "Quoi faire en famille",
            color: "#f5f5f5"
        },

        {
            date: new Date(2024, 0, 9),
            logo: "/images/press/sortirauquebec.svg",
            publisher: "Sortir au Québec",
            color: "#f5f5f5"
        },

        {
            date: new Date(2024, 0, 8),
            logo: "/images/press/city-news.jpg",
            publisher: "City News",
            color: "#fff"
        },

        {
            date: new Date(2024, 0, 8),
            logo: "/images/press/be-one-radio.png",
            publisher: "BE ONE | RADIO",
            color: "#000"
        },
    ]


    return (<>

        <DocumentComponent title={t('media_coverage')} />

        <BannerComponent title={t('media_coverage')} description={t('media_coverage_description')} />

        <Container>

            <Row>

                {items.map((item: IPress, index: number) => {
                    return (
                        <Col key={index} xs={12} sm={6} xl={4} className="mb-4">

                            <Card className="d-flex align-items-center justify-content-center h-100 shadow flex-column rounded p-1" >

                                <Card.Header className="w-100 d-flex align-items-center" style={{ gap: '2rem', background: '#FFF', marginTop: '0.35rem', borderColor: '#EEEBE9' }}>
                                    <div className="rounded d-flex align-items-center justify-content-center" style={{ minWidth: '100px', minHeight: '100px', maxWidth: '100px', maxHeight: '100px', backgroundColor: item.color }}>
                                        <Image className="mw-100" rounded src={item.logo} alt={t(`_press.${index}.title`)}  />
                                    </div>

                                    <div>
                                        <h2 className="modelSubHeader fw-bold fs-3">{item.publisher}</h2>
                                    </div>

                                </Card.Header>

                                <Card.Body className="h-100 p-3 d-flex align-items-start flex-column rounded-end" style={{ backgroundColor: "#FFFFFF" }}>

                                    <Card.Title className="fs-4 mt-3 mb-3" style={{ color: 'var(--primary-color)' }}>{t(`_press.${index}.title`)}</Card.Title>

                                    <Card.Text className="mb-4">
                                        {t(`_press.${index}.description`)}
                                    </Card.Text>

                                </Card.Body>

                                <Card.Footer className="d-flex align-items-center justify-content-between w-100 flex-wrap" style={{ fontSize: '13px', background: '#FFF', border: 'none' }}>

                                    <a href={t(`_press.${index}.link`)} target="_blank" rel="noreferrer">

                                        <Button size="sm" variant="ps-4 pe-2 " className="btn-outline border-0" style={{ marginLeft: '-0.5rem' }}>
                                            {t('read_more')}
                                            <FontAwesomeIcon icon={faExternalLink} className="ms-2" />
                                        </Button>
                                    </a>

                                    <div className="ms-md-auto">
                                        {item.date.toLocaleDateString(language, {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric"
                                        })}
                                    </div>

                                </Card.Footer>
                            </Card>


                        </Col>
                    )
                })}

            </Row>


        </Container>

        <ScheduleComponent />

        <br />
        <br />
        <br />

    </>)
}
