import { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Modal, Image } from "react-bootstrap";
import i18n from "../i18n";

const calendarLink = process.env.REACT_APP_CALENDAR_LINK || '';

const ScheduleCallModalComponent: React.FC = () => {

    const { t } = i18n;

    const [modalShow, setModalShow] = useState(false);

    const handleOpen = () => {
        const timer = setTimeout(() => {
            setModalShow(true);
        }, 30000)
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        handleOpen();
    }, [])

    return (<>

        <Modal
            size="lg"
            centered
            show={modalShow}
            onHide={() => setModalShow(false)}
            className='scheduleCallModal'
        >
            <Modal.Header closeButton>

            </Modal.Header>

            <Modal.Body>
                <Container className='text-center'>
                    <Row className='mt-4 mb-4'>
                        <Col xs={12}>
                            <h6 style={{ color: 'var(--primary-color)', fontSize: '1.75rem' }}>{t('_modal_schedule.title')}</h6>
                            <p style={{ fontSize: '18px' }}>{t('_modal_schedule.body')}</p>
                        </Col>

                        <Col xs={12} className='mt-4 mb-4'>
                            <Image src='/images/customer_support.png' rounded className='mw-100' title={t('_modal_schedule.title')} alt={t('_modal_schedule.title')} />
                        </Col>
                    </Row>
                    <Row className='mt-4 mb-4'>
                        <Col xs={12}>
                            <Button ><a href={calendarLink} target="_blank" rel="noreferrer">{t('_modal_schedule.button')}</a></Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

        </Modal>

    </>)
}

export default ScheduleCallModalComponent;