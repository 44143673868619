import { Helmet } from 'react-helmet-async';
import i18n from '../i18n';


interface IDocumentProps {
    title: string
    og?: {
        description: string,
        image: {
            src: string,
            type: string,
            width: number,
            height: number
        }
    }
}


const DocumentComponent: React.FC<IDocumentProps> = ({ title, og }) => {

    const { t } = i18n

    let pageTitle = 'PakVille'
    if (title !== '') {
        pageTitle += ' | ' + title;
    }

    const currentURL = window.location.href;
    const protocol = window.location.protocol;
    const host = window.location.hostname;
    const baseUrl = `${protocol}//${host}`;

    if (og) {

        return (
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={t(`${og.description}`)} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={t(`${og.description}`)} />
                <meta property="og:url" content={currentURL} />

                <meta property="og:image" content={`${baseUrl}/${og.image.src}`} />
                <meta property="og:image:type" content={og.image.type} />
                <meta property="og:image:width" content={String(og.image.width)} />
                <meta property="og:image:height" content={String(og.image.height)} />
                <meta property="og:image:alt" content={pageTitle} />

            </Helmet>
        )
    } else {

        return (
            <Helmet >
                <title>{pageTitle}</title>
                <meta name="description" content={t('home_list.title')} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={t('home_list.title')} />
                <meta property="og:url" content={currentURL} />

                <meta property="og:image" content={`${baseUrl}/images/models/pak-alps/PakAlps_exterior01.png`} />
                <meta property="og:image:type" content="image/jpg" />
                <meta property="og:image:width" content="1500" />
                <meta property="og:image:height" content="844" />
                <meta property="og:image:alt" content={pageTitle} />

            </Helmet>
        )
    }


}

export default DocumentComponent;