import { ICartItem } from "../interfaces/ICart"
import { formatter } from "./Toolbox"

export const displayItemTotal = (item: ICartItem) => {

    if (item.list === item.price) {
        return (
            <div>{formatter.format(item.quantity * (item.price / 100))}</div>
        )
    }

    return (
        <>
            <div className='text-decoration-line-through'>{formatter.format(item.quantity * (item.list / 100))}</div>
            <div className='text-danger'>{formatter.format(item.quantity * (item.price / 100))}</div>
        </>
    )
}