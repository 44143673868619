import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { NavLink } from "react-router-dom";
import i18n from "../i18n";
import { getUrlLangPrefix } from "../utils/Local";

const CookieConsentComponent: React.FC = () => {

    const cookieName = 'cookie-consent-2024-04-14'
    const [cookies, setCookie] = useCookies([cookieName]);

    if (cookies[cookieName]) {
        return (<></>)
    }

    const handleAcceptCookie = () => {
        setCookie(cookieName, true, {
            sameSite: false,
            path: '/', 
            maxAge: 31536000
        })
    }
    const { t } = i18n;
    const langPrefix = getUrlLangPrefix()

    return (<>

        <Container fluid className="position-fixed" style={{ bottom: 0, zIndex: 2000 }}>
            <Alert variant='light'>
                <Row>
                    <Col>
                        {t('_cookie_messages.0')}
                        {t('_cookie_messages.1')}
                        <NavLink to={`${langPrefix}/${t('_route.privacy-policy')}`} className='text-center text-lg-end'>
                            <span style={{ color: 'var(--info-color)' }}> {t('privacy_policy')}</span>.
                        </NavLink>

                        <Button className="btn-primary mt-2" style={{ padding: '2px 10px', fontSize: '1rem', float: 'right' }} onClick={() => handleAcceptCookie()}>{t('accept')}</Button>
                    </Col>
                </Row>
            </Alert>
        </Container>
    </>)
}

export default CookieConsentComponent;