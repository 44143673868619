import { Navigate, useNavigate } from 'react-router-dom';
import useCart from '../hooks/useCart';
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from 'react';
import CheckoutFormComponent from '../components/CheckoutForm.component';
import apiService from '../services/api';
import { getCurrentLang, getUrlLangPrefix } from '../utils/Local';
import i18n from '../i18n';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '', {
    locale: getCurrentLang()
});

export default function Checkout() {


    const { t } = i18n
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState("");
    const { dispatch, REDUCER_ACTIONS, cart } = useCart()


    useEffect(() => {
        preparePayment()

        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, []);

    const preparePayment = async () => {

        if (cart.items && cart.items.length > 0) {
            try {
                const response = await apiService.checkout(cart)
                if (response && response.data && response.data.cart) {
                    dispatch({ type: REDUCER_ACTIONS.UPDATE, updatedCart: response.data.cart })
                    setClientSecret(response.data.cart.clientSecret)
                }
            } catch (error) {
                navigate(`${getUrlLangPrefix()}/${t('_route.error')}`)
            }
        }
    }

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    } as StripeElementsOptions;

    if (!cart.items || cart.items.length < 1) {
        return (<Navigate to={`${getUrlLangPrefix()}/${t('_route.shop')}`} />)
    }

    return (
        <>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutFormComponent />
                </Elements>
            )}
        </>
    )

}
