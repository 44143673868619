import { Modal, Image } from "react-bootstrap"
import i18n from "../i18n"
import { LayersControl, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Icon } from 'leaflet'
import useCustomCookies from "../hooks/useCustomCookies";
import { CookieNames, MapTileLayer } from "../enums/App.enum";
import { ReactComponent as FaMail } from '../assets/icons/faMail.svg';
import { ReactComponent as FaPhone } from '../assets/icons/faPhone.svg';
import { ReactComponent as FaMapMarker } from '../assets/icons/faMapMarker.svg';
import { ReactComponent as FaClock } from '../assets/icons/faClock.svg';
import { flatPhoneNumber } from "../utils/Toolbox";


interface StoreInfoModalComponentProps {
    show: boolean;
    onClose: () => void;

}

const StoreInfoModalComponent: React.FC<StoreInfoModalComponentProps> = ({ show, onClose }) => {

    const { t, language } = i18n
    const position: any = [45.454068094127244, -73.72716881697887]
    const { getCookie, setCustomCookies } = useCustomCookies()
    const phoneNumber = process.env.REACT_APP_SALES_PHONE_NUMBER || '';
    const email = process.env.REACT_APP_SALES_EMAIL || '';
    const address = '618 Av. Meloche, Dorval, QC H9P 2P4'
    const googleMap = 'https://maps.app.goo.gl/hLsSuZKwaGbW984R6'

    const leafletProperties = {
        tileLayer: {
            attributes: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 19
        },
        icons: {
            default: new Icon({
                iconUrl: '/marker.png',
                // iconSize: [40, 62], // size of the icon
                iconAnchor: [18, 40], // point of the icon which will correspond to marker's location
                popupAnchor: [-3, -30] // point from which the popup should open relative to the iconAnchor
            }),
        }
    }

    const handleTileLayerChange = (tileLayer: MapTileLayer) => {
        setCustomCookies(CookieNames.MAP_TILE_LAYER, tileLayer)
    }

    const preferredMapTileLayer = () => {

        const layer = getCookie(CookieNames.MAP_TILE_LAYER)

        if (!layer || !Object.values(MapTileLayer).includes(layer)) {
            return MapTileLayer.STANDARD
        }
        return layer
    }

    const workingHours = [

        {
            'label': t('monday'),
            'value': '9a.m. - 5p.m.'
        },
        {
            'label': t('tuesday'),
            'value': '9a.m. - 5p.m.'
        },
        {
            'label': t('wednesday'),
            'value': '9a.m. - 5p.m.'
        },
        {
            'label': t('thursday'),
            'value': '9a.m. - 5p.m.'
        },
        {
            'label': t('friday'),
            'value': '9a.m. - 5p.m.'
        },
        {
            'label': t('monday'),
            'value': '9a.m. - 5p.m.'
        },
        {
            'label': t('saturday'),
            'value': t('closed')
        }
    ]

    // Handle first day of week
    if (language === 'fr') {
        workingHours.push({
            'label': t('sunday'),
            'value': t('closed')
        },)
    } else {
        workingHours.unshift({
            'label': t('sunday'),
            'value': t('closed')
        },)
    }

    return (


        <Modal show={show} onHide={onClose} animation={true} centered size='lg'>
            <Modal.Header closeButton className='border-bottom-0'>
                <Modal.Title>
                    <Image src="/images/logo/pakville.svg" style={{ maxWidth: '150px' }} alt="PakVille"></Image>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex flex-column flex-lg-row justify-content-between gap-4 p-4'>
                <div className="d-flex flex-column gap-2 store-info-modal-details" style={{ minWidth: '50%' }}>
                    <div className="d-flex align-items-center">
                        <FaMail /><a href={`mailto:${email}`} rel="noreferrer">{email}</a>
                    </div>
                    <div className="d-flex align-items-center">
                        <FaPhone /><a href={`tel:${flatPhoneNumber(phoneNumber)}`} rel="noreferrer">{phoneNumber}</a>
                    </div>
                    <div className="d-flex align-items-center">
                        <FaMapMarker /><a href={googleMap} target="_blank" rel="noreferrer">{address}</a>
                    </div>
                    <div className="d-flex align-items-center">
                        <FaClock />{t('hours_of_operation')}
                    </div>
                    <div>
                        <ul className="ps-4 ms-3">
                            {workingHours.map((workingHour, index) => {
                                return (
                                    <li key={index} className="d-flex justify-content-between">
                                        <span>
                                            {workingHour.label}
                                        </span>

                                        <span>
                                            {workingHour.value}
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

                <MapContainer
                    center={position}
                    scrollWheelZoom={true}
                    style={{ height: '350px', width: '100%', borderRadius: '8px' }}
                    zoomControl={true}
                    zoom={13}
                >

                    <LayersControl position="topright">

                        <LayersControl.BaseLayer checked={preferredMapTileLayer() === MapTileLayer.STANDARD} name={t('_leaflet.standard')}>
                            <TileLayer
                                attribution={leafletProperties.tileLayer.attributes}
                                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                                maxZoom={leafletProperties.tileLayer.maxZoom}
                                eventHandlers={{
                                    add: () => {
                                        handleTileLayerChange(MapTileLayer.STANDARD)
                                    }
                                }}
                            />

                        </LayersControl.BaseLayer>

                        <LayersControl.BaseLayer checked={preferredMapTileLayer() === MapTileLayer.GREYSCALE} name={t('_leaflet.greyscale')}>
                            <TileLayer
                                attribution={leafletProperties.tileLayer.attributes}
                                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                maxZoom={leafletProperties.tileLayer.maxZoom}
                                eventHandlers={{
                                    add: () => {
                                        handleTileLayerChange(MapTileLayer.GREYSCALE)
                                    }
                                }}
                            />
                        </LayersControl.BaseLayer>
                    </LayersControl>

                    <Marker position={position}>
                        <Popup>
                            PakVille
                        </Popup>
                    </Marker>
                </MapContainer>


            </Modal.Body>

        </Modal>

    )
}

export default StoreInfoModalComponent