import { Container, Row, Col, Button } from "react-bootstrap";
import i18n from "../../i18n";

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { IBlog } from "../../interfaces/IBlog";
import apiService from "../../services/api";
import { useEffect, useState } from "react";
import BlogComponent from "../Blog.component";
import { NavLink } from "react-router-dom";
import { getUrlLangPrefix } from "../../utils/Local";

const BlogScreen: React.FC = () => {

    const { t } = i18n;
    const [blogs, setBlogs] = useState<IBlog[]>([]);
    const langPrefix = getUrlLangPrefix()

    const fetchBlogs = async () => {
        try {

            const response = await apiService.fetchBlogs(3)
            if (response.status === 200 && response.data && response.data.blogs) {
                const blogs = response.data.blogs as IBlog[];
                setBlogs(blogs);
            }

        } catch (err: any) {

        }
    }


    useEffect(() => {
        fetchBlogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (<>

        <Container fluid className="screenContainer">

            <Container>

                <Row className="mb-5">
                    <Col xs={12} md={{ span: 10, offset: 1 }}>
                        <h2>{t('_blog_screen_title')}</h2>
                        <p>{t('_blog_screen_description')}</p>
                    </Col>
                </Row>

                <Row className="blogs-container text-start">

                    {blogs.filter((blog) => 'title' in blog && 'date' in blog && 'author' in blog).map((blog, index) => {
                        return (
                            <Col key={index} xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} xl={4} className="mb-4">
                                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                                    <BlogComponent blog={blog} />
                                </AnimationOnScroll>
                            </Col>
                        )
                    })}

                    <Col xs={12} className='text-center mt-4'>
                        <NavLink to={`${langPrefix}/${t('_route.blog')}`} >
                            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                                <Button size="sm" variant="outline-success ps-4 pe-4 " className="btn-outline">{t('see_all')}</Button>
                            </AnimationOnScroll>
                        </NavLink>
                    </Col>

                </Row>


            </Container>

        </Container>
    </>)
}

export default BlogScreen;