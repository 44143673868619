import { NavLink } from "react-router-dom";
import DocumentComponent from '../components/Document.component';
import i18n from '../i18n';
import BannerComponent from '../components/Banner.component';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';
import { getUrlLangPrefix } from "../utils/Local";

export default function Shop() {

    const { t } = i18n;
    const langPrefix = getUrlLangPrefix()

    const items = [

        {
            title: t('_shop.pakvert_panels.title'),
            description: t('_shop.pakvert_panels.description'),
            image: '/images/shop/pakvert.png',
            url: `${langPrefix}/${t('_route.shop')}/pakvert`,
            btn_label: t('_shop.pakvert_panels.btn_label')
        },

        {
            title: t('_shop.pakville.title'),
            description: t('schedule_description'),
            image: '/images/shop/pakville.png',
            url: `${langPrefix}/${t('_route.contact-us')}`,
            btn_label: t('_shop.pakville.btn_label')
        }
    ]

    return (

        <>

            <DocumentComponent title={t('shop')} />

            <BannerComponent title={t('shop')} description={t('shop_description')} />

            <Container>

                <Row>
                    <Col xs={{ span: 10, offset: 1 }}>
                        <div className="d-flex flex-column flex-lg-row justify-content-center gap-5 mb-5">

                            {items.map((item, index) => {
                                return (

                                    <div key={index} className='d-flex flex-column text-start gap-2'>

                                        <NavLink to={item.url}>
                                            <Image src={item.image} className='rounded m-auto mt-0 mb-2 mw-100' />
                                        </NavLink>

                                        <h2 className="fs-4">{item.title}</h2>
                                        <p>{item.description}</p>

                                        <NavLink to={item.url} style={{ marginTop: 'auto' }}>
                                            <div className="d-grid gap-2 mt-3">
                                                <Button variant="outline-primary"  >
                                                    <span>{item.btn_label}</span>
                                                </Button>
                                            </div>
                                        </NavLink>

                                    </div>
                                )
                            })}

                        </div>
                    </Col>
                </Row>


            </Container>
        </>
    )

}