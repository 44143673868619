import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        resources: {
            en: {
                translations: require('./locales/en/translations.json')
            },
            fr: {
                translations: require('./locales/fr/translations.json')
            }
        },
        ns: ['translations'],
        defaultNS: 'translations',
        saveMissing: true
    }).then(r => {
}).catch(e => console.error(e));

i18n.languages = ['en', 'fr'];

export default i18n;