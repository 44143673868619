import { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
    AddressElement
} from "@stripe/react-stripe-js";
import { Layout } from "@stripe/stripe-js";
import { Button, Card, Col, Container, Form, Row, Spinner, Accordion } from "react-bootstrap";
import i18n from "../i18n";
import { ReactComponent as FaWarehouse } from '../assets/icons/faWarehouse.svg';
import { ReactComponent as FaTruck } from '../assets/icons/faTruck.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { formatDistance, formatter, validateEmail } from "../utils/Toolbox";
import useCart from "../hooks/useCart";
import { ICart, ICartItem, IShippingInfo, ITaxDetails, } from "../interfaces/ICart";
import CartItemInfo from "./CartItemInfo.component";
import { ReactComponent as FaMapMarker } from '../assets/icons/faMapMarker.svg';
import { ReactComponent as FaInfoCircle } from '../assets/icons/faInfoCircle.svg';
import apiService from "../services/api";
import StoreInfoModalComponent from "./StoreInfoModal.component";
import { DELIVERY_METHOD } from "../enums/App.enum";
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useNavigate } from "react-router-dom";
import { getUrlLangPrefix } from "../utils/Local";

const CheckoutFormComponent = () => {

    const stripe = useStripe();
    const elements = useElements();
    const { cart, dispatch, REDUCER_ACTIONS } = useCart()

    const [isLoading, setIsLoading] = useState(false);

    const [contact, setContact] = useState(cart.contact)
    const { t } = i18n
    const [shippingInfo, setShippingInfo] = useState<IShippingInfo>({} as IShippingInfo)
    const pickupAddress = '618 Av. Meloche, Dorval, QC H9P 2P4'
    const pickupGoogleMap = 'https://maps.app.goo.gl/hLsSuZKwaGbW984R6'
    const [showStoreInformationModal, setShowStoreInformationModal] = useState(false)
    const handleStoreInfoModalClose = () => setShowStoreInformationModal(false);
    const navigate = useNavigate();

    const delivery_methods = [
        {
            id: DELIVERY_METHOD.PICKUP,
            title: `${t('pick_up')} - ${t('free')}`,
            icon: <FaWarehouse />
        },
        {
            id: DELIVERY_METHOD.SHIPPING,
            title: t('get_it_shipped'),
            icon: <FaTruck />,
        }
    ]


    const isPayButtonDisabled = () => {
        return isLoading || !stripe || !elements || !isPhoneValid() || !validateEmail(contact.email) || (cart.delivery_method === DELIVERY_METHOD.SHIPPING && !cart.shipping.is_available)
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (isPayButtonDisabled()) {
            return
        }

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${process.env.REACT_APP_SITE_URL}/success`,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.message && (error.type === "card_error" || error.type === "validation_error")) {
            console.error(error.message);
        } else {
            console.error("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs" as Layout,
    }

    const handleAddressChange = async (event: any) => {

        setShippingInfo(event.value)

        if (event.complete && (

            !shippingInfo.address
            ||
            (shippingInfo.address.line1 !== event.value.address.line1)
            ||
            (shippingInfo.address.state !== event.value.address.state)
        )) {
            await updateShippingInfo(event.value)
        }
    }

    const handleAddressFocusOut = async () => {
        // await delay(500)
        // await updateShippingInfo(shippingInfo)
    }

    const updateDeliveryMethod = async (method: string) => {

        if (method === cart.delivery_method) {
            return
        }

        const cartData = { ...cart }
        cartData.delivery_method = method
        await handleUpdateCart(cartData)
    }

    const handleUpdateCart = async (cartData: ICart) => {

        if (isLoading) {
            return
        }

        try {
            setIsLoading(true);
            const response = await apiService.checkout(cartData)
            dispatch({ type: REDUCER_ACTIONS.UPDATE, updatedCart: response.data.cart })
        } catch (error) {
            navigate(`${getUrlLangPrefix()}/${t('_route.error')}`)
        }
    }

    const updateShippingInfo = async (info: any) => {

        const cartData = { ...cart }
        cartData.delivery_method = DELIVERY_METHOD.SHIPPING
        cartData.shipping.info = info
        await handleUpdateCart(cartData)
    }

    const isCompanyValid = () => {
        if (contact.company.length > 120) {
            return false
        }
        return true
    }

    const handleUpdateContact = async () => {


        if (!validateEmail(contact.email)) {
            return
        }

        if (contact.phone && !isPhoneValid()) {
            return
        }

        if (contact.company && !isCompanyValid()) {
            return
        }

        const cartData = { ...cart }
        cartData.contact = contact

        setIsLoading(true);
        const response = await apiService.checkout(cartData)
        dispatch({ type: REDUCER_ACTIONS.UPDATE, updatedCart: response.data.cart })
    }

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = () => {

        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(contact.phone));
        } catch (error) {
            return false;
        }

    };


    useEffect(() => {
        setIsLoading(false);
    }, [cart]);

    return (
        <Container fluid className="checkout-container">
            <Container className="pt-4" >
                <h2 className="mb-5">{t('checkout')}</h2>
                <form id="payment-form" onSubmit={handleSubmit}>

                    <div className="d-flex flex-column flex-lg-row gap-3">

                        <div className="flex-grow-1 order-2 order-lg-1" style={{ minWidth: '60%' }}>
                            <Card >
                                <Card.Body>
                                    <Card.Title>{t('_checkout.contact_info_title')}</Card.Title>
                                    <Row>
                                        <Col xs={12} lg={6} className="mb-4 mb-lg-0">

                                            <Form.Label htmlFor="name">{t('name')}</Form.Label>
                                            <Form.Control
                                                id="name"
                                                type="text"
                                                value={contact.name}
                                                onChange={(e) => setContact({ ...contact, name: e.target.value })}
                                                required={true}
                                                onBlur={() => handleUpdateContact()}
                                                isInvalid={contact.name === ''}
                                            />
                                            <Form.Text id="nameHelpBlock" muted>

                                            </Form.Text>
                                        </Col>


                                        <Col xs={12} lg={6} className="mb-4 mb-lg-0">

                                            <Form.Label htmlFor="email">{t('email_address')}</Form.Label>
                                            <Form.Control
                                                id="email"
                                                type="email"
                                                value={contact.email}
                                                required={true}
                                                onChange={(e) => setContact({ ...contact, email: e.target.value })}
                                                onBlur={() => handleUpdateContact()}
                                                isInvalid={!validateEmail(contact.email)}
                                            />
                                            <Form.Text id="emailHelpBlock" muted>
                                                {t('_checkout.email_help')}
                                            </Form.Text>
                                        </Col>

                                        <Col xs={12} lg={6} className="mb-4 mb-lg-0">

                                            <Form.Label htmlFor="phone">{t('phone_number')}</Form.Label>

                                            <PhoneInput
                                                defaultCountry="ca"
                                                value={contact.phone}
                                                required={true}
                                                onChange={(phone) => setContact({ ...contact, phone: phone })}
                                                inputClassName={`form-control ps-5 ${contact.phone && !isPhoneValid() ? 'is-invalid' : ''}`}
                                                inputStyle={{ height: '38px', fontSize: '1rem', borderRadius: 'var(--bs-border-radius)' }}
                                                countrySelectorStyleProps={{
                                                    buttonClassName: 'm-0 p-0',
                                                    buttonStyle: { height: '35px', position: 'absolute', border: 'none', left: '2px', top: '2px' },
                                                    flagStyle: { height: '16px' }

                                                }}
                                                onBlur={() => handleUpdateContact()}
                                                forceDialCode={true}
                                                dialCodePreviewStyleProps={{
                                                    style: { border: '21px solid red', display: 'none' }
                                                }}
                                            />

                                            <Form.Text id="companyHelpBlock" muted>

                                            </Form.Text>

                                        </Col>


                                        <Col xs={12} lg={6} className="mb-4 mb-lg-0">

                                            <Form.Label htmlFor="company">{t('company')} ({t('optional')})</Form.Label>
                                            <Form.Control
                                                id="company"
                                                type="text"
                                                value={contact.company}
                                                onChange={(e) => setContact({ ...contact, company: e.target.value })}
                                                onBlur={() => handleUpdateContact()}
                                                isInvalid={!isCompanyValid()}
                                            />
                                            <Form.Text id="companyHelpBlock" muted>

                                            </Form.Text>
                                        </Col>


                                    </Row>

                                </Card.Body>
                            </Card>

                            <Card >
                                <Card.Body>
                                    <Card.Title>{t('_checkout.shipping_method_title')}</Card.Title>
                                    <Row>

                                        {delivery_methods.map((delivery_method, index) => {
                                            return (
                                                <Col xs={12} lg={6} key={index} onClick={() => updateDeliveryMethod(delivery_method.id)}>
                                                    <div className={`d-flex flex-row align-items-center gap-2 rounded pakville-option-value-container ${(cart.delivery_method === delivery_method.id) ? 'pakville-option-selected' : ''}`} >
                                                        {delivery_method.icon}
                                                        {delivery_method.title}

                                                        {cart.delivery_method === delivery_method.id ?
                                                            <FontAwesomeIcon icon={faCheckCircle} color='var(--info-color)' style={{ marginLeft: 'auto' }} />
                                                            :
                                                            <FontAwesomeIcon icon={faCircle as IconProp} color='#A1A1A1' style={{ marginLeft: 'auto' }} />
                                                        }
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>


                                    {cart.delivery_method === DELIVERY_METHOD.SHIPPING && cart.shipping.info && cart.shipping.info.address && cart.shipping.info.address.line1 && !cart.shipping.is_available ?

                                        <Row>
                                            <div className="d-flex align-items-center justify-content-center text-muted text-danger" style={{ fontSize: '15px' }}>
                                                <div className="d-flex align-items-start gap-2 text-danger">
                                                    <FaInfoCircle width={'1rem'} height={'1rem'} className="mt-1" style={{ filter: 'brightness(0) saturate(100%) invert(20%) sepia(83%) saturate(5401%) hue-rotate(357deg) brightness(95%) contrast(129%)' }} />{t('_checkout.shipping_not_available_note')}
                                                </div>
                                            </div>
                                        </Row>

                                        :

                                        <Row>



                                            {cart.delivery_method === DELIVERY_METHOD.SHIPPING && cart.shipping.is_available && cart.shipping.cost === 0 ?

                                                <div className="d-flex align-items-center justify-content-center text-muted" style={{ fontSize: '15px' }}>

                                                    <div className="d-flex align-items-start gap-2">

                                                        <FaInfoCircle width={'1rem'} height={'1rem'} className="mt-1" />
                                                        {t('_checkout.free_shipping_note')}

                                                    </div>

                                                </div>
                                                :

                                                <>

                                                    <div className="d-flex align-items-center justify-content-center text-muted" style={{ fontSize: '15px' }}>

                                                        <div className="d-flex align-items-start gap-2">

                                                            {cart.delivery_method === DELIVERY_METHOD.PICKUP ?
                                                                <>
                                                                    <FaMapMarker width={'1rem'} height={'1rem'} className="mt-1" /><a href={pickupGoogleMap} target="_blank" rel="noreferrer">{pickupAddress}</a>
                                                                </>
                                                                :
                                                                <>
                                                                    <FaInfoCircle width={'1rem'} height={'1rem'} className="mt-1" />{t('_checkout.shipping_availability_note', { x: 100 })}
                                                                </>
                                                            }
                                                        </div>
                                                        <Button variant="link" className='m-0 p-0 ms-auto text-end' onClick={() => setShowStoreInformationModal(true)}>{t('view_store_information')}</Button>
                                                    </div>
                                                </>
                                            }

                                        </Row>

                                    }

                                    <Row>
                                        <Col>
                                            {cart.delivery_method === DELIVERY_METHOD.SHIPPING ?

                                                <AddressElement
                                                    className="mt-3"
                                                    options={{
                                                        mode: 'shipping',
                                                        allowedCountries: ['CA'],
                                                        fields: {
                                                            phone: 'always'
                                                        },
                                                        defaultValues: cart.shipping.info && cart.shipping.info.address ? cart.shipping.info : {
                                                            address: {
                                                                state: 'QC',
                                                                country: 'CA'
                                                            }
                                                        }
                                                    }}

                                                    onChange={(event) => handleAddressChange(event)}
                                                    onBlur={() => handleAddressFocusOut()}
                                                />
                                                :
                                                <>
                                                </>
                                            }
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>


                            <Card>
                                <Card.Body>
                                    <Card.Title>{t('_checkout.payment_options_title')}</Card.Title>
                                    <Row>
                                        <Col xs={12}>
                                            <PaymentElement id="payment-element" options={paymentElementOptions} />
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>

                            <div className="d-block d-lg-none d-grid mt-5 mb-5">
                                <Button variant="primary d-flex justify-content-center" disabled={isPayButtonDisabled()} type="submit" >
                                    {isLoading ?
                                        <>
                                            <Spinner animation="border" size="sm" className="p-2" />
                                            <span style={{ opacity: 0 }}>.</span>
                                        </>
                                        :
                                        t('pay_now')
                                    }
                                </Button>
                            </div>

                        </div>


                        <div className="order-1 order-lg-2" style={{ minWidth: '40%' }}>

                            <Card>
                                <Card.Body>
                                    <Card.Title className='mb-4'>{t('order_summary')}</Card.Title>

                                    <div className='d-flex justify-content-between mb-3'>
                                        <span>{t('sub_total')}</span>
                                        <span>{formatter.format(cart.subtotal / 100)}</span>
                                    </div>

                                    {cart.discount > 0 &&

                                        <div className='d-flex justify-content-between mb-3'>
                                            <span>{t('discount')}</span>
                                            <span className="text-danger">-{formatter.format(cart.discount / 100)}</span>
                                        </div>
                                    }

                                    <div className='d-flex justify-content-between mb-3'>
                                        {isLoading ?
                                            <>
                                                <span></span>
                                            </>

                                            :
                                            <>
                                                {cart.delivery_method === DELIVERY_METHOD.SHIPPING ?

                                                    <span>
                                                        {t('Shipping')}
                                                        {cart.shipping.distance > 0 &&
                                                            <span> ({formatDistance(cart.shipping.distance)})</span>
                                                        }
                                                    </span>

                                                    :

                                                    <span>
                                                        {t('pickup')}
                                                    </span>
                                                }
                                            </>

                                        }
                                        {isLoading ?
                                            <>
                                                <span>
                                                    {t('calculating')}
                                                </span>
                                            </>

                                            :

                                            cart.delivery_method === DELIVERY_METHOD.SHIPPING && !cart.shipping.is_available ?

                                                <>

                                                    <span>
                                                        {formatter.format(cart.shipping.cost / 100)}
                                                    </span>

                                                </>

                                                :

                                                <>
                                                    <span>
                                                        {cart.shipping.cost > 0 ? formatter.format(cart.shipping.cost / 100) : t('free')}
                                                    </span>
                                                </>
                                        }


                                    </div>

                                    {!isLoading && cart.taxes && cart.taxes.details && cart.taxes.details.map((tax: ITaxDetails, index: number) => {

                                        return (
                                            <div key={index} className='d-flex justify-content-between mb-3'>
                                                <span>Tax {tax.label} ({tax.percentage}%)</span>
                                                <span>{formatter.format(tax.value / 100)}</span>
                                            </div>
                                        )
                                    })}

                                    <hr style={{ margin: '1rem 0' }}></hr>

                                    <div className='d-flex justify-content-between mb-3 fw-bold'>
                                        <span>{t('total')}</span>
                                        {isLoading ?
                                            <>
                                                {t('calculating')}
                                            </>
                                            :
                                            <>
                                                <span>{formatter.format(cart.calculated_total / 100)}</span>
                                            </>
                                        }

                                    </div>

                                    <div className="d-grid mt-5 mb-5">
                                        <Button variant="primary d-flex justify-content-center" disabled={isPayButtonDisabled()} type="submit" >
                                            {isLoading ?
                                                <>
                                                    <Spinner animation="border" size="sm" className="p-2" />
                                                    <span style={{ opacity: 0 }}>.</span>
                                                </>
                                                :
                                                t('pay_now')
                                            }
                                        </Button>

                                    </div>



                                    <Accordion defaultActiveKey="0" flush>
                                        <Accordion.Item eventKey="0" className="border-0 ">
                                            <Accordion.Header> {t('items')}</Accordion.Header>
                                            <Accordion.Body>
                                                {cart.items.map((item: ICartItem, index: number) => {
                                                    return (
                                                        <div key={index} >
                                                            <CartItemInfo item={item} />
                                                            <hr style={{ margin: '1rem 0' }}></hr>
                                                        </div>
                                                    )
                                                })}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                            </Card>

                        </div>

                    </div>


                </form>
            </Container>


            <StoreInfoModalComponent show={showStoreInformationModal} onClose={handleStoreInfoModalClose} />

        </Container >
    );
}

export default CheckoutFormComponent