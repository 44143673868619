import { Container, Row, Col, Image, Button } from "react-bootstrap";
import i18n from "../../i18n";
import { ReactComponent as FaMarker } from '../../assets/icons/faMarker.svg';
import { ReactComponent as FaCalendar } from '../../assets/icons/faCalendar.svg';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

const ExhibitionScreen: React.FC = () => {

    const { t } = i18n;

    const info = {
        address: t('_exhibition.address'),
        date: t('_exhibition.date'),
        btnLabel: t('_exhibition.btn_label'),
        link: t('_exhibition.link'),
        googleMap: 'https://maps.app.goo.gl/wCeQ5mtcyN4mNnER7',
        startDate: '2024-11-08',
        endDate: '2024-11-10',
        title: t('_exhibition.title'),
        description: t('_exhibition.description'),
        title_active: t('_exhibition.title_active'),
        description_active: t('_exhibition.description_active'),
        image: '/images/exhibitions/2024-10-Complexe-sportif-CN.png',
        name: t('_exhibition.name'),
        promoCode: 'PAKVILLE',
        promoLink: t('_exhibition.promo_link')
    }

    const startDateValues = info.startDate.split('-')
    const endDateValues = info.endDate.split('-')

    const startDate = new Date(`${startDateValues[0]}/${startDateValues[1]}/${startDateValues[2]}`).setHours(0, 0, 0, 0)
    const endDate = new Date(`${endDateValues[0]}/${endDateValues[1]}/${endDateValues[2]}`).setHours(0, 0, 0, 0)

    const currentDate = new Date().setHours(0, 0, 0, 0)
    const expired = currentDate > endDate;
    const active = startDate <= currentDate;

    return (<>

        <Container fluid className="screenContainer exhibitionContainer" style={{ backgroundColor: '#F9F8F8' }}>

            <Container className="flexContainer gap-0 gap-lg-3">
                <Row>
                    <Col className="text-center text-md-start">
                        {expired ?
                            <>
                                <span>{t('show_time_is_over')}...</span>
                                <h2>{t('the_journey_continue')}</h2>
                                <p>{t('cant_wait_to_see_you_next_event')}</p>
                            </>
                            :
                            <>
                                {active ?
                                    <>
                                        <span>{t('it_s_show_time')}...</span>
                                        <h2>{info.title_active}</h2>
                                        <p>{info.description_active}</p>
                                    </>

                                    :

                                    <>
                                        <span>{t('coming_soon')}...</span>
                                        <h2>{info.title}</h2>
                                        <p>{info.description}</p>
                                    </>
                                }

                            </>
                        }

                    </Col>
                </Row>

                <Row>

                    {expired ?
                        <>

                        </>
                        :
                        <>
                            <Col xs={12} lg={6}>
                                <div className="flexContainer text-center text-lg-start align-items-lg-start" style={{ gap: '2rem' }}>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <AnimationOnScroll animateIn="animate__pulse" animateOnce>
                                                <div className="svgContainer">
                                                    <FaCalendar />
                                                </div>
                                            </AnimationOnScroll>

                                            <h4>{t('where_to_meet_us')}</h4>
                                            <p className="m-0">{t('booth_no')} 335</p>
                                            <a href={info.googleMap} target="_blank" rel="noreferrer"><p>{info.address}</p></a>
                                        </Col>

                                        <Col xs={12} lg={6}>
                                            <AnimationOnScroll animateIn="animate__pulse" animateOnce>
                                                <div className="svgContainer" >
                                                    <FaMarker />
                                                </div>
                                            </AnimationOnScroll>

                                            <h4>{t('when_to_meet_us')}</h4>
                                            <p>{info.date}</p>
                                        </Col>

                                        <Col xs={12}>
                                            <a href={info.link} target="_blank" rel="noreferrer">
                                                <Button className="btn-outline-primary" >
                                                    {info.btnLabel}
                                                    <FontAwesomeIcon icon={faExternalLink} className="ms-2" />
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>

                                    <Row className="mt-2">
                                        <Col xs={12}>
                                        <h5>
                                            {t('_exhibition.promo_description')}
                                            <b className="ms-2">{info.promoCode}</b>
                                        </h5>
                                           
                                        </Col>

                                        <Col xs={12} className="mt-2">
                                            <a href={info.promoLink} target="_blank" rel="noreferrer">
                                                <Button className="btn-primary">
                                                    {t('tickets')}
                                                    <FontAwesomeIcon icon={faExternalLink} className="ms-2" />
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                    <Row>
                                        
                                    </Row>
                                </div>
                            </Col>
                        </>}



                    <Col xs={12} lg={{ span: 6, offset: expired ? 3 : 0 }} className="mt-4 mt-lg-0">
                        <div className="flexContainer">
                            <Image src={info.image} rounded className="mw-100" title={`PakVille - ${info.name}`} alt={`PakVille - ${info.name}`} />
                            {expired &&
                                <p>{t('past_event', { expo: info.name, date: info.date })}</p>
                            }
                        </div>
                    </Col>

                </Row>

            </Container>

        </Container>
    </>)
}

export default ExhibitionScreen;