import { Container, Row, Col, Image, Button } from "react-bootstrap";
import i18n from "../../i18n";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { NavLink } from "react-router-dom";
import { getUrlLangPrefix } from "../../utils/Local";

const IntroScreen: React.FC = () => {

    const { t } = i18n;
    const langPrefix = getUrlLangPrefix()

    return (<>
        <Container fluid className="screenContainer introContainer" style={{ backgroundColor: '#FFFFFF' }}>

            <Container className="flexContainer" >

                <Row>
                    <Col>
                        <h1 className="text-center">
                            {t('build_your_escape_room')} {t('from')} <span>{t('recycled_water_bottles')}</span>
                        </h1>
                        <p className="mt-4">
                            {t('_home_intro_description')}
                        </p>
                    </Col>
                </Row>

                <Row>

                    <Col xs={12} lg={6} className="mb-4 mb-lg-0">
                        <div className="flexContainer" style={{ flexDirection: 'row' }}>
                            <div style={{ flex: '3', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
                                    <Image src="/images/home/PakVille-interior01.png" rounded className="mw-100" alt='PakVille' />
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
                                    <Image src="/images/home/PakVille-exterior01.png" rounded className="mw-100" alt='PakVille' />
                                </AnimationOnScroll>
                            </div>
                            <div style={{ flex: '2' }}>
                                <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
                                    <Image src="/images/home/PakVille-interior02.png" rounded className="mw-100" alt='PakVille' />
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} lg={6}>
                        <div className="flexContainer introContent" style={{ gap: '8px' }}>
                            <h2>{t('_screen.0.title')}</h2>
                            <h3>{t('_screen.0.subtitle')}</h3>
                            <p>{t('_screen.0.description')}</p>
                            <NavLink to={`${langPrefix}/${t('_route.models')}/overview`}>
                                <Button className="btn-primary">{t('learn_more')}</Button>
                            </NavLink>
                        </div>
                    </Col>

                </Row>

            </Container>

        </Container>
    </>)
}

export default IntroScreen;