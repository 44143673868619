export const refundPolicyEn = `
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ul.lst-kix_ibqveulz93g8-8 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-7 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-6 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-5 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-4 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-3 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-2 {
            list-style-type: none
        }

        .lst-kix_y75wbynhs0cx-0>li:before {
            content: "\x025cf   "
        }

        .lst-kix_jjocaga77yvy-1>li:before {
            content: "\x025cb   "
        }

        ul.lst-kix_ibqveulz93g8-1 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-0 {
            list-style-type: none
        }

        .lst-kix_y75wbynhs0cx-1>li:before {
            content: "\x025cb   "
        }

        .lst-kix_jjocaga77yvy-0>li:before {
            content: "\x025cf   "
        }

        .lst-kix_jjocaga77yvy-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_jjocaga77yvy-2>li:before {
            content: "\x025a0   "
        }

        .lst-kix_jjocaga77yvy-6>li:before {
            content: "\x025cf   "
        }

        .lst-kix_ibqveulz93g8-8>li:before {
            content: "\x025a0   "
        }

        .lst-kix_ibqveulz93g8-7>li:before {
            content: "\x025cb   "
        }

        .lst-kix_jjocaga77yvy-3>li:before {
            content: "\x025cf   "
        }

        .lst-kix_jjocaga77yvy-4>li:before {
            content: "\x025cb   "
        }

        .lst-kix_ibqveulz93g8-6>li:before {
            content: "\x025cf   "
        }

        .lst-kix_ibqveulz93g8-3>li:before {
            content: "\x025cf   "
        }

        .lst-kix_ibqveulz93g8-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_ibqveulz93g8-0>li:before {
            content: "\x025cf   "
        }

        .lst-kix_ibqveulz93g8-4>li:before {
            content: "\x025cb   "
        }

        .lst-kix_y75wbynhs0cx-8>li:before {
            content: "\x025a0   "
        }

        .lst-kix_jjocaga77yvy-8>li:before {
            content: "\x025a0   "
        }

        .lst-kix_y75wbynhs0cx-7>li:before {
            content: "\x025cb   "
        }

        ul.lst-kix_1mea951yss04-0 {
            list-style-type: none
        }

        .lst-kix_ibqveulz93g8-1>li:before {
            content: "\x025cb   "
        }

        .lst-kix_jjocaga77yvy-7>li:before {
            content: "\x025cb   "
        }

        .lst-kix_ibqveulz93g8-2>li:before {
            content: "\x025a0   "
        }

        .lst-kix_y75wbynhs0cx-4>li:before {
            content: "\x025cb   "
        }

        .lst-kix_y75wbynhs0cx-3>li:before {
            content: "\x025cf   "
        }

        .lst-kix_y75wbynhs0cx-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_y75wbynhs0cx-2>li:before {
            content: "\x025a0   "
        }

        .lst-kix_y75wbynhs0cx-6>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_kbka666puyyd-2 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-1 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-0 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-6 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-5 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-0 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-4 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-1 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-3 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-2 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-8 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-7 {
            list-style-type: none
        }

        .lst-kix_bdqgnzvk5mdq-2>li:before {
            content: "\x025a0   "
        }

        ul.lst-kix_1mea951yss04-5 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-6 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-8 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-7 {
            list-style-type: none
        }

        .lst-kix_bdqgnzvk5mdq-1>li:before {
            content: "\x025cb   "
        }

        ul.lst-kix_1mea951yss04-8 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-6 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-1 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-7 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-2 {
            list-style-type: none
        }

        .lst-kix_bdqgnzvk5mdq-0>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_jjocaga77yvy-4 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-3 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-5 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-4 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-2 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-3 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-0 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-1 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-7 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-8 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-3 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-4 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-5 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-6 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-2 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-2>li:before {
            content: "\x025a0   "
        }

        .lst-kix_kbka666puyyd-0>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_3sw0yshborkn-3 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-4 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-1>li:before {
            content: "\x025cb   "
        }

        .lst-kix_dfd3xiy9wao-3>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_3sw0yshborkn-5 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-4>li:before {
            content: "\x025cb   "
        }

        .lst-kix_kbka666puyyd-2>li:before {
            content: "\x025a0   "
        }

        .lst-kix_dfd3xiy9wao-0>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_3sw0yshborkn-0 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-1 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-5 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-6>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_dfd3xiy9wao-4 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-3 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_dfd3xiy9wao-7>li:before {
            content: "\x025cb   "
        }

        ul.lst-kix_dfd3xiy9wao-2 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-6 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-1 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-1>li:before {
            content: "\x025cb   "
        }

        ul.lst-kix_3sw0yshborkn-7 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-0 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-8 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-7>li:before {
            content: "\x025cb   "
        }

        .lst-kix_kbka666puyyd-8>li:before {
            content: "\x025a0   "
        }

        .lst-kix_kbka666puyyd-6>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_dfd3xiy9wao-8 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-7 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-6 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-3>li:before {
            content: "\x025cf   "
        }

        .lst-kix_kbka666puyyd-4>li:before {
            content: "\x025cb   "
        }

        .lst-kix_kbka666puyyd-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-8>li:before {
            content: "\x025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-3>li:before {
            content: "\x025cf   "
        }

        .lst-kix_bdqgnzvk5mdq-4>li:before {
            content: "\x025cb   "
        }

        .lst-kix_bdqgnzvk5mdq-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-7>li:before {
            content: "\x025cb   "
        }

        .lst-kix_bdqgnzvk5mdq-6>li:before {
            content: "\x025cf   "
        }

        .lst-kix_1mea951yss04-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_1mea951yss04-6>li:before {
            content: "\x025cf   "
        }

        .lst-kix_1mea951yss04-4>li:before {
            content: "\x025cb   "
        }

        .lst-kix_1mea951yss04-8>li:before {
            content: "\x025a0   "
        }

        .lst-kix_3sw0yshborkn-0>li:before {
            content: "\x025cf   "
        }

        .lst-kix_3sw0yshborkn-1>li:before {
            content: "\x025cb   "
        }

        .lst-kix_1mea951yss04-7>li:before {
            content: "\x025cb   "
        }

        .lst-kix_3sw0yshborkn-4>li:before {
            content: "\x025cb   "
        }

        .lst-kix_3sw0yshborkn-3>li:before {
            content: "\x025cf   "
        }

        .lst-kix_3sw0yshborkn-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_3sw0yshborkn-2>li:before {
            content: "\x025a0   "
        }

        .lst-kix_3sw0yshborkn-6>li:before {
            content: "\x025cf   "
        }

        .lst-kix_akpzl7ddoxyx-8>li:before {
            content: "\x025a0   "
        }

        .lst-kix_1mea951yss04-0>li:before {
            content: "\x025cf   "
        }

        .lst-kix_akpzl7ddoxyx-6>li:before {
            content: "\x025cf   "
        }

        .lst-kix_akpzl7ddoxyx-7>li:before {
            content: "\x025cb   "
        }

        .lst-kix_1mea951yss04-1>li:before {
            content: "\x025cb   "
        }

        .lst-kix_1mea951yss04-2>li:before {
            content: "\x025a0   "
        }

        .lst-kix_akpzl7ddoxyx-5>li:before {
            content: "\x025a0   "
        }

        .lst-kix_1mea951yss04-3>li:before {
            content: "\x025cf   "
        }

        .lst-kix_akpzl7ddoxyx-2>li:before {
            content: "\x025a0   "
        }

        .lst-kix_akpzl7ddoxyx-3>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_y75wbynhs0cx-0 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-1 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-2 {
            list-style-type: none
        }

        .lst-kix_akpzl7ddoxyx-4>li:before {
            content: "\x025cb   "
        }

        ul.lst-kix_y75wbynhs0cx-3 {
            list-style-type: none
        }

        .lst-kix_akpzl7ddoxyx-0>li:before {
            content: "\x025cf   "
        }

        ul.lst-kix_y75wbynhs0cx-4 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-5 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-6 {
            list-style-type: none
        }

        .lst-kix_3sw0yshborkn-8>li:before {
            content: "\x025a0   "
        }

        ul.lst-kix_y75wbynhs0cx-7 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-8 {
            list-style-type: none
        }

        .lst-kix_3sw0yshborkn-7>li:before {
            content: "\x025cb   "
        }

        .lst-kix_akpzl7ddoxyx-1>li:before {
            content: "\x025cb   "
        }

        ul.lst-kix_akpzl7ddoxyx-0 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-1 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-8>li:before {
            content: "\x025a0   "
        }

        ul.lst-kix_akpzl7ddoxyx-6 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-7 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-8 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-2 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-3 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-4 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-5 {
            list-style-type: none
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c3 {
            color: #ff0000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c6 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c1 {
            padding-top: 20pt;
            padding-bottom: 6pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c5 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 20pt;
            font-family: "Arial";
            font-style: normal
        }

        .c0 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c4 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c2 {
            height: 11pt
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c4 doc-content">
    <h1 class="c1" id="h.t1v5c6zqark"><span class="c5">Refund Policy</span></h1>
    <p class="c0 c2"><span class="c6"></span></p>
    <p class="c0"><span class="c6">Last updated: 2024-06-20</span></p>
    <p class="c0 c2"><span class="c6"></span></p>
    <p class="c0"><span class="c3">All sales are final. </span></p>
</body>

</html>
`