import { Card, Image } from "react-bootstrap";
import i18n from "../i18n";
import { IBlog } from "../interfaces/IBlog";
import { getCurrentLang, getUrlLangPrefix } from "../utils/Local";
import { formatDate } from "../utils/Toolbox";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface BlogProps {
    blog: IBlog
}


const BlogComponent: React.FC<BlogProps> = ({ blog }) => {

    const { t } = i18n;
    const lang = getCurrentLang()
    const langPrefix = getUrlLangPrefix()

    const blogLink = () => {
        const title = blog.title[lang].replaceAll('?', '').replaceAll(' ', '_');
        return `${langPrefix}/${t('_route.blog')}/${title}?id=${blog.id}`
    }

    return (
        <>
            <NavLink to={blogLink()} >

                <Card className="d-flex align-items-center justify-content-center h-100 flex-column rounded border-0 gap-1 blog-card" >

                    <Card.Header className="border-0 p-0" style={{ background: '#FFF', marginTop: '0.35rem' }}>
                        <Image src={blog.thumbnail} alt={blog.title[lang]} rounded className="mw-100" />
                    </Card.Header>

                    <Card.Body className="w-100 h-100 p-2 d-flex align-items-start flex-column rounded-end gap-3">
                        <div className="d-flex align-items-center justify-content-between w-100 flex-wrap" style={{ fontSize: '14px' }}>
                            <div className="d-flex align-items-center justify-content-between gap-1 author-container">
                                <div className="text-center author-avatar">
                                    <Image src="/images/logo/PakVille-vrt.png" className="rounded" alt="PakVille"></Image>
                                </div>
                                {`${t('published_by')}: ${blog.author}`}
                            </div>
                            <div className="ms-md-auto">
                                {formatDate(blog.date, lang)}
                            </div>
                        </div>
                        <h2 className="m-0">{blog.title[lang]}</h2>
                    </Card.Body>

                    <Card.Footer className=" w-100 border-0 p-2" style={{ fontSize: '13px', background: '#FFF' }}>
                        {t('read_article')}
                        <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </Card.Footer>

                </Card>
            </NavLink>
        </>
    )
}

export default BlogComponent;
