export const termsFr = `
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c3 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Arial";
            font-style: normal
        }

        .c2 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c5 {
            padding-top: 16pt;
            padding-bottom: 4pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c6 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 20pt;
            font-family: "Arial";
            font-style: normal
        }

        .c10 {
            padding-top: 20pt;
            padding-bottom: 6pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c0 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c8 {
            text-decoration-skip-ink: none;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c1 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c7 {
            color: inherit;
            text-decoration: inherit
        }

        .c4 {
            height: 11pt
        }

        .c9 {
            background-color: #ffff00
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c1 doc-content">
    <h1 class="c10" id="h.utr2ldg2fsnf"><span class="c6">Conditions de service </span></h1>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Derni&egrave;re mise &agrave; jour : 2024-06-20</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Veuillez noter : En cas d&#39;informations ambigu&euml;s ou de divergences entre les versions, la version anglaise de cette politique pr&eacute;vaut sur la version fran&ccedil;aise. Nous vous encourageons &agrave;
            consulter la version anglaise pour les d&eacute;tails les plus pr&eacute;cis et complets.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.r2vfffk6y27s"><span class="c3">APER&Ccedil;U</span></h3>
    <p class="c0"><span class="c2">PakVille.ca est exploit&eacute; par PakVille Construction Corp. (&laquo; PakVille &raquo;), desservant principalement les utilisateurs au Canada et aux &Eacute;tats-Unis. Sur le site, les termes &laquo; nous &raquo;,
            &laquo; notre &raquo; et &laquo; nos &raquo; font r&eacute;f&eacute;rence &agrave; PakVille. PakVille offre ce site web, y compris toutes les informations, outils et Services disponibles sur ce site &agrave; vous, l&#39;utilisateur,
            sous r&eacute;serve de votre acceptation de tous les termes, conditions, politiques et avis &eacute;nonc&eacute;s ici.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">En visitant notre site et/ou en achetant quelque chose de notre part, vous participez &agrave; notre &laquo; Service &raquo; et acceptez d&#39;&ecirc;tre li&eacute; par les termes et conditions suivants (&laquo;
            Conditions de Service &raquo;, &laquo; Conditions &raquo;), y compris les termes et conditions et politiques suppl&eacute;mentaires mentionn&eacute;s ici et/ou disponibles par hyperlien. Ces Conditions de Service s&#39;appliquent &agrave;
            tous les utilisateurs du site, y compris sans limitation les utilisateurs qui sont des navigateurs, fournisseurs, clients, commer&ccedil;ants et/ou contributeurs de contenu.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Veuillez lire attentivement ces Conditions de Service avant d&#39;acc&eacute;der ou d&#39;utiliser notre site web. En acc&eacute;dant ou en utilisant une partie du site, vous acceptez d&#39;&ecirc;tre li&eacute; par
            ces Conditions de Service. Si vous n&#39;acceptez pas tous les termes et conditions de cet accord, alors vous ne pouvez pas acc&eacute;der au site web ou utiliser les Services. Si ces Conditions de Service sont consid&eacute;r&eacute;es
            comme une offre, l&#39;acceptation est express&eacute;ment limit&eacute;e &agrave; ces Conditions de Service.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Toutes nouvelles fonctionnalit&eacute;s ou outils qui sont ajout&eacute;s au site web actuel seront &eacute;galement soumis aux Conditions de Service. Vous pouvez consulter la version la plus r&eacute;cente des
            Conditions de Service &agrave; tout moment sur cette page. Nous nous r&eacute;servons le droit de mettre &agrave; jour, de modifier ou de remplacer une partie de ces Conditions de Service en publiant des mises &agrave; jour et/ou des
            modifications sur notre site web. Il est de votre responsabilit&eacute; de v&eacute;rifier cette page p&eacute;riodiquement pour les changements. Votre utilisation continue ou votre acc&egrave;s au site web apr&egrave;s la publication de
            tout changement constitue l&#39;acceptation de ces changements.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Notre site web est h&eacute;berg&eacute; sur Amazon Web Services (AWS).</span></p>
    <p class="c2"><span class="c0"></span></p>
    <h3 class="c5" id="h.gvocguvf2jjx"><span class="c3">SECTION 1 - TERMES</span></h3>
    <p class="c0"><span class="c2">En acceptant ces Conditions de Service, vous d&eacute;clarez que vous avez au moins l&#39;&acirc;ge de la majorit&eacute; dans votre &Eacute;tat ou province de r&eacute;sidence, ou que vous avez l&#39;&acirc;ge de
            la majorit&eacute; dans votre &Eacute;tat ou province de r&eacute;sidence et que vous nous avez donn&eacute; votre consentement pour permettre &agrave; l&#39;un de vos d&eacute;pendants mineurs d&#39;utiliser ce site.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Vous ne pouvez pas utiliser nos produits &agrave; des fins ill&eacute;gales ou non autoris&eacute;es ni, dans l&#39;utilisation du Service, violer les lois de votre juridiction (y compris, mais sans s&#39;y limiter,
            les lois sur le droit d&#39;auteur).</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Vous ne devez pas transmettre de vers ou de virus ou tout code de nature destructrice. Une violation de l&#39;un des Termes entra&icirc;nera une r&eacute;siliation imm&eacute;diate de vos Services.&quot;</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.vehxcxuuiuh5"><span class="c3">SECTION 2 - CONDITIONS G&Eacute;N&Eacute;RALES</span></h3>
    <p class="c0"><span class="c2">Nous nous r&eacute;servons le droit de refuser le Service &agrave; quiconque pour quelque raison que ce soit &agrave; tout moment.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Vous comprenez que votre contenu (&agrave; l&#39;exception des informations de carte de cr&eacute;dit) peut &ecirc;tre transf&eacute;r&eacute; non crypt&eacute; et impliquer (a) des transmissions sur divers r&eacute;seaux ; et (b) des changements pour se conformer et s&#39;adapter aux
            exigences techniques des r&eacute;seaux ou appareils connect&eacute;s. Les informations de carte de
            cr&eacute;dit sont g&eacute;r&eacute;es de mani&egrave;re s&eacute;curis&eacute;e par Stripe et sont toujours crypt&eacute;es lors du transfert &agrave; travers les r&eacute;seaux.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter une partie du Service, l&#39;utilisation du Service, l&#39;acc&egrave;s au Service ou tout contact sur le site web par lequel le
            Service est fourni, sans autorisation &eacute;crite expresse de notre part.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Les titres utilis&eacute;s dans cet accord sont inclus pour la commodit&eacute; seulement et ne limiteront ni n&#39;affecteront autrement ces Termes.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.3uirellp0aco"><span class="c3">SECTION 3 - PR&Eacute;CISION, EXHAUSTIVIT&Eacute; ET ACTUALIT&Eacute; DES INFORMATIONS</span></h3>
    <p class="c0"><span class="c2">Nous ne sommes pas responsables si les informations disponibles sur ce site ne sont pas pr&eacute;cises, compl&egrave;tes ou actuelles. Le mat&eacute;riel sur ce site est fourni &agrave; titre d&#39;information
            g&eacute;n&eacute;rale seulement et ne devrait pas &ecirc;tre utilis&eacute; comme seule base pour prendre des d&eacute;cisions sans consulter des sources d&#39;information primaires, plus pr&eacute;cises, plus compl&egrave;tes ou plus
            opportunes. Toute confiance en le mat&eacute;riel sur ce site est &agrave; vos propres risques.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Ce site peut contenir certaines informations historiques. Les informations historiques, n&eacute;cessairement, ne sont pas actuelles et sont fournies pour votre r&eacute;f&eacute;rence seulement. Nous nous
            r&eacute;servons le droit de modifier le contenu de ce site &agrave; tout moment, mais nous n&#39;avons aucune obligation de mettre &agrave; jour les informations sur notre site. Vous acceptez qu&#39;il est de votre responsabilit&eacute;
            de surveiller les changements sur notre site.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.qv70fo4lqvfi"><span class="c3">SECTION 4 - MODIFICATIONS DU SERVICE ET DES PRIX</span></h3>
    <p class="c0"><span class="c2">Les prix de nos produits sont sujets &agrave; changement sans pr&eacute;avis. Nous nous r&eacute;servons le droit &agrave; tout moment de modifier ou d&#39;interrompre le Service (ou toute partie ou contenu de
            celui-ci) sans pr&eacute;avis. Nous ne serons pas responsables envers vous ou tout tiers pour toute modification, changement de prix, suspension ou interruption du Service.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.x7zsnwei89g4"><span class="c3">SECTION 5 - PRODUITS OU SERVICES</span></h3>
    <p class="c2"><span class="c0">Certains produits ou services peuvent &ecirc;tre disponibles exclusivement en ligne via le site Web. Ces produits ou services peuvent avoir des quantit&eacute;s limit&eacute;es et peuvent &ecirc;tre
            retourn&eacute;s ou &eacute;chang&eacute;s uniquement conform&eacute;ment &agrave; notre politique de remboursement.</span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nous avons fait tout notre possible pour afficher aussi pr&eacute;cis&eacute;ment que possible les couleurs et les images de nos produits qui apparaissent dans la boutique. Nous ne pouvons pas garantir que
            l&#39;affichage de toute couleur par l&#39;&eacute;cran de votre ordinateur sera pr&eacute;cis.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nous nous r&eacute;servons le droit, mais ne sommes pas oblig&eacute;s, de limiter les ventes de nos produits ou Services &agrave; toute personne, r&eacute;gion g&eacute;ographique ou juridiction. Nous pouvons
            exercer ce droit au cas par cas. Nous nous r&eacute;servons le droit de limiter les quantit&eacute;s de tout produit ou Service que nous offrons. Toutes les descriptions de produits ou les prix des produits sont sujets &agrave; changement
            &agrave; tout moment sans pr&eacute;avis, &agrave; la seule discr&eacute;tion de notre part. Nous nous r&eacute;servons le droit de discontinuer tout produit &agrave; tout moment. Toute offre pour tout produit ou Service fait sur ce site
            est nulle l&agrave; o&ugrave; interdite.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nous ne garantissons pas que la qualit&eacute; de tout produit, Service, information ou autre mat&eacute;riel achet&eacute; ou obtenu par vous r&eacute;pondra &agrave; vos attentes, ou que toute erreur dans le
            Service sera corrig&eacute;e.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.os9orlve3kro"><span class="c3">SECTION 6 - EXACTITUDE DES INFORMATIONS DE FACTURATION ET DE COMPTE</span></h3>
    <p class="c5"><span class="c4 c18">Nous nous r&eacute;servons le droit de refuser toute commande que vous passez chez nous. Nous pouvons, &agrave; notre seule discr&eacute;tion, limiter ou annuler les quantit&eacute;s achet&eacute;es par
            personne, par foyer ou par commande. Ces restrictions peuvent inclure des commandes pass&eacute;es par ou sous le m&ecirc;me compte client, la m&ecirc;me carte de cr&eacute;dit et/ou des commandes utilisant la m&ecirc;me adresse de
            facturation et/ou de livraison. Dans le cas o&ugrave; nous modifions ou annulons une commande, nous pouvons tenter de vous en informer en contactant l&#39;adresse courriel et/ou l&#39;adresse de facturation/le num&eacute;ro de
            t&eacute;l&eacute;phone fournis au moment de la commande. Nous nous r&eacute;servons le droit de limiter ou d&#39;interdire les commandes qui, &agrave; notre seul jugement, semblent &ecirc;tre pass&eacute;es par des concessionnaires,
            revendeurs ou distributeurs.</span></p>
    <p class="c5"><span class="c4">Vous acceptez de fournir des informations d&#39;achat et de compte actuelles, compl&egrave;tes et exactes pour tous les achats effectu&eacute;s dans notre magasin. Vous acceptez de mettre &agrave; jour rapidement
            votre compte et d&#39;autres informations, y compris votre adresse e-mail et vos num&eacute;ros de carte de cr&eacute;dit et dates d&#39;expiration, afin que nous puissions effectuer vos transactions et vous contacter si
            n&eacute;cessaire.</span></p>
    <h3 class="c5" id="h.os9orlve3kro"><span class="c3">SECTION 7 - OUTILS FACULTATIFS</span></h3>
    <p class="c0"><span class="c2">Nous pouvons vous donner acc&egrave;s &agrave; des outils tiers sur lesquels nous n&#39;exer&ccedil;ons ni surveillance ni contr&ocirc;le ni influence. Vous reconnaissez et acceptez que nous fournissions
            l&#39;acc&egrave;s &agrave; de tels outils &laquo; tels quels &raquo; et &laquo; selon la disponibilit&eacute; &raquo; sans aucune garantie, repr&eacute;sentation ou condition de quelque nature que ce soit et sans aucune approbation. Nous
            ne serons en aucun cas responsables des cons&eacute;quences d&eacute;coulant de ou li&eacute;es &agrave; votre utilisation d&#39;outils tiers facultatifs.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Toute utilisation par vous des outils facultatifs offerts par le site se fait &agrave; vos propres risques et discr&eacute;tion et vous devriez vous assurer que vous connaissez et approuvez les termes selon lesquels
            les outils sont fournis par le(s) fournisseur(s) tiers concern&eacute;(s).</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nous pourrions &eacute;galement, &agrave; l&#39;avenir, offrir de nouveaux Services et/ou fonctionnalit&eacute;s via le site web (y compris la sortie de nouveaux outils et ressources). De telles nouvelles
            fonctionnalit&eacute;s et/ou Services seront &eacute;galement soumis &agrave; ces Conditions de Service.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.mw0pv21j6gm0"><span class="c3">SECTION 8 - LIENS TIERS</span></h3>
    <p class="c0"><span class="c2">Certains contenus, produits et Services disponibles via notre Service peuvent inclure des mat&eacute;riaux de tiers.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Les liens de tiers sur ce site peuvent vous diriger vers des sites web de tiers qui ne sont pas affili&eacute;s avec nous. Nous ne sommes pas responsables d&#39;examiner ou d&#39;&eacute;valuer le contenu ou
            l&#39;exactitude et nous ne garantissons pas et n&#39;aurons aucune responsabilit&eacute; pour tout mat&eacute;riel, site web de tiers, ou pour tout autre mat&eacute;riel, produit ou Service de tiers.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nous ne sommes pas responsables des dommages ou pr&eacute;judices li&eacute;s &agrave; l&#39;achat ou &agrave; l&#39;utilisation de biens, Services, ressources, contenu ou de toute autre transaction effectu&eacute;e
            en relation avec des sites web de tiers. Veuillez examiner attentivement les politiques et pratiques du tiers et assurez-vous de les comprendre avant de vous engager dans toute transaction. Les plaintes, r&eacute;clamations,
            pr&eacute;occupations ou questions concernant les produits de tiers doivent &ecirc;tre adress&eacute;es au tiers concern&eacute;.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.6i6717bbqlvh"><span class="c3">SECTION 9 - COMMENTAIRES DES UTILISATEURS, RETOURS ET AUTRES SOUMISSIONS</span></h3>
    <p class="c0"><span class="c2">Si, &agrave; notre demande, vous envoyez certaines soumissions sp&eacute;cifiques (par exemple des participations &agrave; des concours) ou sans demande de notre part, vous envoyez des id&eacute;es cr&eacute;atives,
            suggestions, propositions, plans ou autres mat&eacute;riaux, que ce soit en ligne, par courriel, par courrier postal ou autrement (collectivement, &#39;commentaires&#39;), vous acceptez que nous puissions, &agrave; tout moment, sans
            restriction, &eacute;diter, copier, publier, distribuer, traduire et utiliser dans tout m&eacute;dia tout commentaire que vous nous envoyez. Nous n&#39;avons et ne sommes pas oblig&eacute;s (1) de maintenir la confidentialit&eacute; des
            commentaires ; (2) de payer une compensation pour tout commentaire ; ou (3) de r&eacute;pondre &agrave; tout commentaire.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nous pouvons, mais n&#39;avons aucune obligation de, surveiller, &eacute;diter ou supprimer le contenu que nous d&eacute;terminons, &agrave; notre seule discr&eacute;tion, &ecirc;tre ill&eacute;gal, offensant,
            mena&ccedil;ant, diffamatoire, pornographique, obsc&egrave;ne ou autrement r&eacute;pr&eacute;hensible ou en violation des droits de propri&eacute;t&eacute; intellectuelle d&#39;une partie ou de ces Conditions de Service.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Vous acceptez que vos commentaires ne violeront aucun droit d&#39;un tiers, y compris le droit d&#39;auteur, la marque, la vie priv&eacute;e, la personnalit&eacute; ou tout autre droit personnel ou de
            propri&eacute;t&eacute;. Vous acceptez en outre que vos commentaires ne contiendront pas de mat&eacute;riel diffamatoire ou autrement ill&eacute;gal, abusif ou obsc&egrave;ne, ou contiendront un virus informatique ou autre malware qui
            pourrait affecter le fonctionnement du Service ou de tout site web connexe. Vous ne pouvez pas utiliser une fausse adresse e-mail, pr&eacute;tendre &ecirc;tre quelqu&#39;un d&#39;autre que vous-m&ecirc;me, ou autrement induire en erreur
            nous ou des tiers quant &agrave; l&#39;origine de tout commentaire. Vous &ecirc;tes seul responsable de tout commentaire que vous faites et de leur exactitude. Nous n&#39;assumons aucune responsabilit&eacute; et d&eacute;clinons toute
            responsabilit&eacute; pour tout commentaire publi&eacute; par vous ou tout tiers.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.pxtuqhuleouq"><span class="c3">SECTION 10 - INFORMATIONS PERSONNELLES</span></h3>
    <p class="c0"><span>Votre soumission d&#39;informations personnelles via le site web est r&eacute;gie par notre Politique de confidentialit&eacute;, que vous pouvez consulter </span><span class="c8"><a class="c7"
                href="/fr/politique-de-confidentialite">ici</a></span><span>.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.txkasr8v7if9"><span class="c3">SECTION 11 - ERREURS, INEXACTITUDES ET OMISSIONS</span></h3>
    <p class="c0"><span class="c2">Il peut arriver que des informations sur notre site ou dans le Service contiennent des erreurs typographiques, des inexactitudes ou des omissions qui peuvent concerner des descriptions de produits, des prix, des
            promotions, des offres, des frais d&#39;exp&eacute;dition de produits, des d&eacute;lais de transit et la disponibilit&eacute;. Nous nous r&eacute;servons le droit de corriger toute erreur, inexactitude ou omission, et de changer ou
            mettre &agrave; jour des informations ou d&#39;annuler des commandes si une information dans le Service ou sur tout site web connexe est inexacte &agrave; tout moment sans pr&eacute;avis (y compris apr&egrave;s que vous ayez soumis votre
            commande).</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nous n&#39;avons aucune obligation de mettre &agrave; jour, modifier ou clarifier des informations dans le Service ou sur tout site web connexe, y compris, sans limitation, les informations de prix, sauf tel que
            requis par la loi. Aucune date de mise &agrave; jour ou de rafra&icirc;chissement sp&eacute;cifi&eacute;e dans le Service ou sur tout site web connexe ne doit &ecirc;tre interpr&eacute;t&eacute;e comme indiquant que toutes les
            informations dans le Service ou sur tout site web connexe ont &eacute;t&eacute; modifi&eacute;es ou mises &agrave; jour.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.qnppgt650it9"><span class="c3">SECTION 12 - UTILISATIONS INTERDITES</span></h3>
    <p class="c0"><span class="c2">En plus des autres interdictions &eacute;nonc&eacute;es dans les Conditions de Service, il vous est interdit d&#39;utiliser le site ou son contenu : (a) &agrave; des fins ill&eacute;gales ; (b) pour solliciter
            d&#39;autres personnes &agrave; commettre ou participer &agrave; des actes ill&eacute;gaux ; (c) pour violer toute r&eacute;glementation, r&egrave;gle, loi internationale, f&eacute;d&eacute;rale, provinciale ou &eacute;tatique, ou
            ordonnance locale ; (d) pour porter atteinte ou violer nos droits de propri&eacute;t&eacute; intellectuelle ou ceux d&#39;autrui ; (e) pour harceler, abuser, insulter, blesser, diffamer, calomnier, discr&eacute;diter, intimider ou
            discriminer sur la base du genre, de l&#39;orientation sexuelle, de la religion, de l&#39;ethnie, de la race, de l&#39;&acirc;ge, de l&#39;origine nationale ou du handicap ; (f) pour soumettre des informations fausses ou trompeuses ; (g)
            pour t&eacute;l&eacute;charger ou transmettre des virus ou tout autre type de code malveillant qui sera ou pourra &ecirc;tre utilis&eacute; d&#39;une mani&egrave;re qui affectera la fonctionnalit&eacute; ou le fonctionnement du Service ou
            de tout site web connexe, d&#39;autres sites web ou d&#39;Internet ; (h) pour collecter ou suivre les informations personnelles d&#39;autrui ; (i) pour spammer, hame&ccedil;onner, pharming, pr&eacute;texter, explorer, ramper, ou gratter ;
            (j) &agrave; des fins obsc&egrave;nes ou immorales ; ou (k) pour interf&eacute;rer avec ou contourner les fonctionnalit&eacute;s de s&eacute;curit&eacute; du Service ou de tout site web connexe, d&#39;autres sites web ou d&#39;Internet.
            Nous nous r&eacute;servons le droit de r&eacute;silier votre utilisation du Service ou de tout site web connexe pour violation de l&#39;une des utilisations interdites.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.wc1ogv1in9tb"><span class="c3">SECTION 13 - AVERTISSEMENT DE GARANTIES ; LIMITATION DE RESPONSABILIT&Eacute;</span></h3>
    <p class="c0"><span class="c2">Nous ne garantissons pas, ne repr&eacute;sentons pas ou n&#39;assurons pas que votre utilisation de notre Service sera ininterrompue, opportune, s&eacute;curis&eacute;e ou sans erreur.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nous ne garantissons pas que les r&eacute;sultats qui peuvent &ecirc;tre obtenus de l&#39;utilisation du Service seront pr&eacute;cis ou fiables.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Vous acceptez que de temps &agrave; autre nous puissions retirer le Service pour des p&eacute;riodes ind&eacute;termin&eacute;es ou annuler le Service &agrave; tout moment, sans pr&eacute;avis &agrave; vous.</span>
    </p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Vous convenez express&eacute;ment que votre utilisation, ou incapacit&eacute; &agrave; utiliser, le Service est &agrave; votre seul risque. Le Service et tous les produits et Services livr&eacute;s &agrave; vous par
            le Service sont (sauf indication expresse de notre part) fournis &laquo; tels quels &raquo; et &laquo; selon la disponibilit&eacute; &raquo; pour votre utilisation, sans aucune repr&eacute;sentation, garantie ou condition de quelque
            nature que ce soit, expresse ou implicite, y compris toutes les garanties ou conditions implicites de qualit&eacute; marchande, qualit&eacute; marchande, aptitude &agrave; un usage particulier, durabilit&eacute;, titre et
            non-contrefa&ccedil;on.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">En aucun cas PakVille, nos directeurs, officiers, employ&eacute;s, affili&eacute;s, agents, contractants, stagiaires, fournisseurs, prestataires de services ou conc&eacute;dants de licence ne seront
            responsables de tout dommage, perte, r&eacute;clamation, ou de tout dommage direct, indirect, accessoire, punitif, sp&eacute;cial ou cons&eacute;cutif de toute sorte, y compris, sans limitation les profits perdus, les revenus perdus, les
            &eacute;conomies perdues, la perte de donn&eacute;es, les co&ucirc;ts de remplacement, ou tout dommage similaire, que ce soit bas&eacute; sur un contrat, un d&eacute;lit (y compris la n&eacute;gligence), une responsabilit&eacute; stricte
            ou autre, d&eacute;coulant de votre utilisation de l&#39;un des Services ou de tout produit acquis en utilisant le Service, ou pour toute autre r&eacute;clamation li&eacute;e de quelque mani&egrave;re que ce soit &agrave; votre
            utilisation du Service ou de tout produit, y compris, mais sans s&#39;y limiter, &agrave; toute erreur ou omission dans tout contenu, ou &agrave; toute perte ou dommage de quelque nature que ce soit r&eacute;sultant de l&#39;utilisation
            du Service ou de tout contenu (ou produit) publi&eacute;, transmis ou autrement rendu disponible via le Service, m&ecirc;me si conseill&eacute; de leur possibilit&eacute;. Parce que certains &Eacute;tats ou juridictions n&#39;autorisent
            pas l&#39;exclusion ou la limitation de responsabilit&eacute; pour les dommages cons&eacute;cutifs ou accessoires, dans ces &Eacute;tats ou juridictions, notre responsabilit&eacute; sera limit&eacute;e dans la mesure maximale permise par
            la loi.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.v9pje6qlngl5"><span class="c3">SECTION 14 - INDEMNISATION</span></h3>
    <p class="c0"><span class="c2">Vous acceptez d&#39;indemniser, d&eacute;fendre et d&eacute;gager de toute responsabilit&eacute; PakVille et notre soci&eacute;t&eacute; m&egrave;re, filiales, affili&eacute;s, partenaires, dirigeants,
            administrateurs, agents, contractants, conc&eacute;dants, prestataires de services, sous-traitants, fournisseurs, stagiaires et employ&eacute;s, de toute r&eacute;clamation ou demande, y compris les frais d&#39;avocats raisonnables, faite
            par un tiers en raison de ou d&eacute;coulant de votre violation de ces Conditions de Service ou des documents qu&#39;ils incorporent par r&eacute;f&eacute;rence, ou de votre violation de toute loi ou des droits d&#39;un tiers.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.6f6iju33jwdk"><span class="c3">SECTION 15 - DIVISIBILIT&Eacute;</span></h3>
    <p class="c0"><span class="c2">Dans le cas o&ugrave; une disposition de ces Conditions de Service est jug&eacute;e ill&eacute;gale, nulle ou inapplicable, cette disposition sera n&eacute;anmoins ex&eacute;cutable dans toute la mesure permise par
            la loi applicable, et la partie inapplicable sera consid&eacute;r&eacute;e comme s&eacute;par&eacute;e de ces Conditions de Service, une telle d&eacute;termination n&#39;affectera pas la validit&eacute; et l&#39;applicabilit&eacute; des
            autres dispositions restantes.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.3fexiyfvn5wc"><span class="c3">SECTION 16 - R&Eacute;SILIATION</span></h3>
    <p class="c0"><span class="c2">Les obligations et responsabilit&eacute;s des parties encourues avant la date de r&eacute;siliation survivront &agrave; la r&eacute;siliation de cet accord &agrave; toutes fins.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Ces Conditions de Service sont effectives sauf si elles sont r&eacute;sili&eacute;es par vous ou par nous. Vous pouvez r&eacute;silier ces Conditions de Service &agrave; tout moment en nous informant que vous ne
            souhaitez plus utiliser nos Services, ou lorsque vous cessez d&#39;utiliser notre site.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Si, selon notre seul jugement, vous &eacute;chouez, ou nous soup&ccedil;onnons que vous avez &eacute;chou&eacute;, &agrave; respecter tout terme ou disposition de ces Conditions de Service, nous pouvons
            &eacute;galement r&eacute;silier cet accord &agrave; tout moment sans pr&eacute;avis et vous resterez responsable de tous les montants dus jusqu&#39;&agrave; et y compris la date de r&eacute;siliation ; et/ou en cons&eacute;quence, nous
            pouvons vous refuser l&#39;acc&egrave;s &agrave; nos Services (ou &agrave; toute partie de ceux-ci).</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.1ec0xp1lr1v0"><span class="c3">SECTION 17 - ACCORD COMPLET</span></h3>
    <p class="c0"><span class="c2">Le fait que nous n&#39;exercions pas ou n&#39;appliquions pas un droit ou une disposition de ces Conditions de Service ne constitue pas une renonciation &agrave; ce droit ou &agrave; cette disposition.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Ces Conditions de Service et toutes les politiques ou r&egrave;gles d&#39;exploitation publi&eacute;es par nous sur ce site ou en ce qui concerne le Service constituent l&#39;accord complet et la
            compr&eacute;hension entre vous et nous et r&eacute;gissent votre utilisation du Service, rempla&ccedil;ant tous les accords, communications et propositions ant&eacute;rieurs ou contemporains, qu&#39;ils soient oraux ou &eacute;crits,
            entre vous et nous (y compris, mais sans s&#39;y limiter, les versions ant&eacute;rieures des Conditions de Service).</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Toute ambigu&iuml;t&eacute; dans l&#39;interpr&eacute;tation de ces Conditions de Service ne sera pas interpr&eacute;t&eacute;e contre la partie r&eacute;dactrice.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.vs2wousf2e12"><span class="c3">SECTION 18 - LOI APPLICABLE</span></h3>
    <p class="c0"><span class="c2">Ces Conditions de Service et tous les accords s&eacute;par&eacute;s par lesquels nous vous fournissons des Services seront r&eacute;gis et interpr&eacute;t&eacute;s conform&eacute;ment aux lois du
            Qu&eacute;bec.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.fn7cxv9xgnbf"><span class="c3">SECTION 19 - MODIFICATIONS DES CONDITIONS DE SERVICE</span></h3>
    <p class="c0"><span class="c2">Vous pouvez consulter la version la plus r&eacute;cente des Conditions de Service &agrave; tout moment sur cette page. Nous nous r&eacute;servons le droit, &agrave; notre seule discr&eacute;tion, de mettre &agrave;
            jour, modifier ou remplacer une partie de ces Conditions de Service en publiant des mises &agrave; jour et des modifications sur notre site web. Il est de votre responsabilit&eacute; de v&eacute;rifier notre site web p&eacute;riodiquement
            pour les changements. Votre utilisation continue ou votre acc&egrave;s &agrave; notre site web ou au Service apr&egrave;s la publication de tout changement &agrave; ces Conditions de Service constitue l&#39;acceptation de ces
            changements.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <h3 class="c5" id="h.3pftbrmfw1vd"><span class="c3">SECTION 20 - COORDONN&Eacute;ES</span></h3>
    <p class="c0"><span>Les questions concernant les Conditions de Service doivent nous &ecirc;tre envoy&eacute;es &agrave; </span><span class="c8"><a class="c7" href="mailto:info@pakville.ca">info@pakville.ca</a></span><span class="c2">.</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">Nos coordonn&eacute;es sont publi&eacute;es ci-dessous :</span></p>
    <p class="c0 c4"><span class="c2"></span></p>
    <p class="c0"><span class="c2">&#x1f3e2; PakVille.ca exploit&eacute; par PakVille Construction Corp.</span></p>
    <p class="c0"><span class="c2">&#x1f4e7; info@pakville.ca</span></p>
    <p class="c0"><span class="c2">&#x1f4cd; 618 Av. Meloche, Dorval, Qu&eacute;bec H9P 2P4, Canada</span></p>
    <p class="c0"><span class="c2">&#x1f4de; +1 (514) 885-1361</span></p>
    <p class="c0"><span class="c2">&#x1f522; Num&eacute;ro BN : 729384610 RC0001</span></p>
    <p class="c0"><span class="c2">&#x1f194; Num&eacute;ro NEQ : 1179148227</span></p>
    <p class="c0 c4"><span class="c2"></span></p>

    <h3 class="c5" id="h.3pftbrmfw1vd"><span class="c3">SECTION 21 - CONDITIONS G&Eacute;N&Eacute;RALES SUPPL&Eacute;MENTAIRES</span></h3>

    <p class="c5"><strong>P&eacute;nurie de mat&eacute;riel.</strong><span class="c7">&nbsp;En raison d&#39;une p&eacute;nurie de mat&eacute;riel de construction pour une dur&eacute;e ind&eacute;termin&eacute;e en Am&eacute;rique du Nord et
            de retards dans la production de mat&eacute;riel de construction par les fournisseurs, nous nous r&eacute;servons le droit d&#39;ajuster le calendrier si nous sommes incapable de se procurer le mat&eacute;riel n&eacute;cessaire &agrave;
            une commande selon le calendrier convenu. Si la p&eacute;nurie mentionn&eacute;e ci-dessus affecte l&#39;approvisionnement en mat&eacute;riel pour cette commande, nous ne serons pas tenus responsables de tout dommage ou retard
            r&eacute;sultant d&#39;un ajustement du calendrier.</span></p>
    <p class="c5"><strong>Garanties.</strong><span class="c7">&nbsp;Tous les produits que nous fabriquons sont garantis pendant une p&eacute;riode d&#39;un (1) an suivant la date de leur livraison contre tout d&eacute;faut de fabrication et
            de mat&eacute;riau. Si des produits tombent sous les termes de cette garantie, PakVille devra, sans frais pour vous, au choix de PakVille, soit : (i) r&eacute;parer ces mat&eacute;riaux d&eacute;fectueux ; ou (ii) fournir des
            produits de remplacement. Les dommages aux produits caus&eacute;s par vous ou toute personne qui n&#39;est pas sous le contr&ocirc;le de PakVille, un &eacute;v&eacute;nement de force majeure, une installation d&eacute;fectueuse, une
            utilisation autre que celle pour laquelle le produit est con&ccedil;u, une utilisation anormale ou abusive ou d&#39;autres causes non li&eacute;es aux activit&eacute;s de PakVille sont sp&eacute;cifiquement exclus de la couverture de
            cette garantie.</span></p>
    <p class="c5"><span class="c7">Les garanties pr&eacute;c&eacute;dentes sont exclusives et sont donn&eacute;es et accept&eacute;es &agrave; la place de toute autre garantie, expresse ou implicite, dans la mesure permise par les lois
            applicables. Vos recours pour toute violation de la garantie seront limit&eacute;s &agrave; ceux fournis dans les pr&eacute;sentes.</span></p>
    <p class="c5"><strong>Limitation de responsabilit&eacute;. </strong><span class="c7">La responsabilit&eacute; de PakVille pour une r&eacute;clamation ou une perte ou un dommage d&eacute;coulant d&#39;un produit d&eacute;fectueux est
            limit&eacute;e aux co&ucirc;ts de remplacement ou de correction, au choix de PakVille, du produit d&eacute;fectueux. et ne doit en aucun cas d&eacute;passer le prix d&#39;achat pay&eacute; pour le produit d&eacute;fectueux. En aucun
            cas, PakVille ne pourra &ecirc;tre tenue responsable des dommages accessoires, cons&eacute;cutifs ou indirects, y compris toute perte de profit ou perte de revenus, ou des dommages-int&eacute;r&ecirc;ts liquid&eacute;s ou
            punitifs.</span></p>
    <p class="c5"><strong>D&eacute;lai de traitement de la commande.</strong><span class="c7">&nbsp;Une fois la commande pass&eacute;e, nous vous contacterons dans les deux jours ouvrables pour confirmer le calendrier de fabrication et la
            date d&#39;exp&eacute;dition, le cas &eacute;ch&eacute;ant.</span></p>
    <p class="c5"><strong>Livraison, titre et risque de perte.</strong><span class="c7">&nbsp;Le titre et le risque de perte pour tous les produits exp&eacute;di&eacute;s par PakVille sont d&eacute;volus &agrave; l&#39;Acheteur lors de
            la livraison &agrave; l&#39;Acheteur. La livraison est r&eacute;put&eacute;e avoir eu lieu lorsque le produit a &eacute;t&eacute; d&eacute;charg&eacute; du camion de livraison au site de livraison convenu ou, si l&#39;acc&egrave;s au site
            convenu est entrav&eacute; ou jug&eacute; impossible par le chauffeur du camion, &agrave; tout autre site convenu entre vous et PakVille et raisonnablement accessible par le camion de livraison.</span></p>
    <p class="c5"><span class="c7">Le prix d&#39;exp&eacute;dition des produits comprend deux (2) heures d&#39;attente et de d&eacute;chargement sur le site de livraison. PakVille peut, &agrave; son gr&eacute;, vous facturer 120,00 $ de
            l&#39;heure pour le temps de d&eacute;chargement et d&#39;attente exc&eacute;dant les deux (2) heures initialement allou&eacute;es.</span></p>
    <p class="c5"><span class="c7">PakVille vous avisera de la date et de l&#39;heure de livraison au plus tard la veille de la livraison.PakVille acceptera vos r&eacute;clamations pour manquants sur l&#39;envoi &agrave; condition que les
            manquants soient not&eacute;s sur les documents d&#39;exp&eacute;dition au moment de la livraison</span></p>
    <p class="c5"><strong>Force Majeure.</strong><span class="c7">&nbsp;PakVille ne sera pas responsable des retards de livraison et d&#39;ex&eacute;cution, en tout ou en partie, de toute cause hors du contr&ocirc;le raisonnable de
            PakVille ou de force majeure (force sup&eacute;rieure), y compris , mais sans s&#39;y limiter : pand&eacute;mie de COVID-19, conflits de travail (l&eacute;gaux ou ill&eacute;gaux) ; p&eacute;nurie de transporteurs, de mat&eacute;riaux
            ou de fournitures. En cas de retard, le d&eacute;lai d&#39;ex&eacute;cution sera prolong&eacute; en cons&eacute;quence.</span></p>
    <p class="c5"><strong>R&eacute;clamations et indemnisation. </strong><span class="c7">Les r&eacute;clamations pour manquants, marchandises endommag&eacute;es pendant le transport et produits d&eacute;fectueux doivent &ecirc;tre
            signal&eacute;es &agrave; PakVille, par &eacute;crit, dans les 48 heures suivant la livraison de l&#39;article.</span></p>
    <p class="c7"><span class="c7">Vous acceptez d&#39;indemniser et de d&eacute;gager PakVille de toutes les r&eacute;clamations, demandes et frais, y compris les frais d&#39;avocat raisonnables, faits par des tiers contre PakVille
            relativement &agrave; votre utilisation ou installation de produits, et de payer tous frais et d&eacute;penses encourus par PakVille pour faire respecter vos obligations, y compris, sans s&#39;y limiter, les frais de recouvrement de
            tout montant impay&eacute; que vous devez &agrave; PakVille.</span></p>
    <p class="c2"><span class="c0 c7"></span></p>
</body>

</html>`