import { Container, Row, Col } from "react-bootstrap";
import i18n from "../../i18n";
import data from '../../data/benefits.json'

import { ReactComponent as FaBolt } from '../../assets/icons/faBolt.svg';
import { ReactComponent as FaCost } from '../../assets/icons/faCost.svg';
import { ReactComponent as FaLeaf } from '../../assets/icons/faLeaf.svg';
import { ReactComponent as FaMouse } from '../../assets/icons/faMouse.svg';
import { ReactComponent as FaMuscle } from '../../assets/icons/faMuscle.svg';
import { ReactComponent as FaPuzzle } from '../../assets/icons/faPuzzle.svg';
import { ReactComponent as FaRecycle } from '../../assets/icons/faRecycle.svg';
import { ReactComponent as FaRocket } from '../../assets/icons/faRocket.svg';
import { ReactComponent as FaThermometer } from '../../assets/icons/faThermometer.svg';
import { ReactComponent as FaVirus } from '../../assets/icons/faVirus.svg';
import { ReactComponent as FaWater } from '../../assets/icons/faWater.svg';
import { ReactComponent as FaWood } from '../../assets/icons/faWood.svg';

import { AnimationOnScroll } from 'react-animation-on-scroll';

interface IBenefit {
    title: string,
    description: string
}

const BenefitsScreen: React.FC = () => {

    const { t } = i18n;

    const icon = (key: number) => {

        switch (key) {
            case 0:
                return (<FaCost />);
            case 1:
                return (<FaRecycle />);
            case 2:
                return (<FaPuzzle />);
            case 3:
                return (<FaVirus />);
            case 4:
                return (<FaMuscle />);
            case 5:
                return (<FaWood />);
            case 6:
                return (<FaWater />);
            case 7:
                return (<FaMouse />);
            case 8:
                return (<FaRocket />);
            case 9:
                return (<FaBolt />);
            case 10:
                return (<FaThermometer />);
            case 11:
                return (<FaLeaf />);
            default:
                return (<FaRecycle />);

        }
    }

    return (<>

        <Container fluid className="screenContainer benefitsContainer">

            <Container>

                <Row className="mb-5">
                    <Col xs={12} md={{ span: 10, offset: 1 }}>
                        <span>{t('benefits')}</span>
                        <h2>{t('_screen.3.title')}</h2>
                        <p>{t('_screen.3.description')}</p>
                    </Col>
                </Row>

                <Row>
                    {
                        data.map((benefit: IBenefit, index: number) => (
                            <Col xs={12} sm={6} md={4} xl={3} key={index}>
                                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                                    <div className="svgContainer">
                                        {icon(index)}
                                    </div>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                                    <h3>{t(benefit.title)}</h3>
                                    <p>{t(benefit.description)}</p>
                                </AnimationOnScroll>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

        </Container>
    </>)
}

export default BenefitsScreen;