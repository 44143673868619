import { Spinner } from "react-bootstrap"
const LoaderComponent = () => {

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center position-absolute h-100 w-100">
                <Spinner animation="border"></Spinner>
            </div>
        </>
    )
}

export default LoaderComponent