import { useEffect, useState } from "react";
import useCart from "../hooks/useCart";
import { Button, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import i18n from "../i18n";
import CartItemInfo from "./CartItemInfo.component";
import { getUrlLangPrefix } from "../utils/Local";

const RecentCartItem = () => {

    const { t } = i18n;
    const [showRecentCartItem, setShowRecentCartItem] = useState(false);
    const langPrefix = getUrlLangPrefix()
    const { recentCartItem, } = useCart();

    useEffect(() => {

        if (Object.keys(recentCartItem).length) {
            setShowRecentCartItem(true)
        }

    }, [recentCartItem]);


    return (
        <>
            {showRecentCartItem &&
                <>
                    <Modal show={showRecentCartItem} onHide={() => setShowRecentCartItem(false)} animation={true} backdropClassName={'recent-item-backdrop'} className="recent-item-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>{t('item_has_been_added_to_your_card')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CartItemInfo item={recentCartItem} />
                        </Modal.Body>
                        <Modal.Footer>
                            <NavLink to={`${langPrefix}/${t('_route.cart')}`} className='d-grid gap-2' onClick={() => setShowRecentCartItem(false)}>
                                <Button variant="outline-primary" >
                                    {t('view_cart')}
                                </Button>
                            </NavLink>
                            <NavLink to={`${langPrefix}/${t('_route.shop')}`} className='d-grid gap-2 mt-2' onClick={() => setShowRecentCartItem(false)}>
                                <Button variant="link" >
                                    {t('continue_shopping')}
                                </Button>
                            </NavLink>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    )
}

export default RecentCartItem