import DocumentComponent from "../components/Document.component";
import i18n from "../i18n"
import ScheduleComponent from "../components/Schedule.component";
import TechnologyScreen from "../components/screens/Technology.component";
import PakVertPanelsScreen from "../components/screens/PakVertPanels.component";
import ConstructionSolutionScreen from "../components/screens/ConstructionSolution.component";
import BottleCycleScreen from "../components/screens/BottleCycle.component";

export default function Technology() {

    const { t } = i18n;


    return (<>
        <DocumentComponent title={t('technology')} />
        
        <TechnologyScreen />

        <PakVertPanelsScreen />

        <ConstructionSolutionScreen />

        <BottleCycleScreen />

        <ScheduleComponent />

    </>)
}