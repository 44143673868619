import { Container, Row, Col, Image } from "react-bootstrap";
import i18n from "../../i18n";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const ConstructionSolutionScreen: React.FC = () => {

    const { t } = i18n;

    return (<>

        <Container fluid className="screenContainer" style={{ backgroundColor: 'var(--bg-light-color)', minHeight: '50vh' }}>

            <Container className="flexContainer">
                <Row className="gx-5">
                    <Col xs={12} lg={5} className="order-2 order-lg-1 mt-5 mt-lg-0">
                        <div className="flexContainer" style={{ flexDirection: 'row' }}>
                            <Row>
                                <Col xs={8}>
                                    <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
                                        <Image src="/images/technology/PakVert_Wall_Layers.png" rounded className="mw-100" alt={`${t('old_walls_structure')}`} />
                                        <figcaption>{t('old_walls_structure')}</figcaption>
                                    </AnimationOnScroll>
                                </Col>

                                <Col xs={4}>
                                    <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
                                        <Image src="/images/technology/PakVert_Panel.png" rounded className="mw-100" alt={`${t('pakvert_all_in_one_solution')}`} />
                                        <figcaption>{t('pakvert_all_in_one_solution')}</figcaption>
                                    </AnimationOnScroll>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12} lg={7} className="order-1 order-lg-2">
                        <div className="flexContainer installationContent" style={{ gap: '8px' }}>
                            <h2>{t('_technology_screen.2.title')}</h2>
                            <p>{t('_technology_screen.2.description')}</p>
                        </div>
                    </Col>
                </Row>

            </Container>

        </Container>
    </>)
}

export default ConstructionSolutionScreen;