import { useSearchParams, NavLink } from 'react-router-dom';
import ls from 'localstorage-slim';
import i18n from "../i18n"
import apiService from '../services/api';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import DocumentComponent from '../components/Document.component';
import Error from '../components/Error.component'
import Loader from '../components/Loader.component'
import 'react-image-gallery/styles/css/image-gallery.css';
import { getCurrentLang, getUrlLangPrefix } from '../utils/Local';
import { IBlog } from '../interfaces/IBlog';
import { calculateReadTime, formatDate } from '../utils/Toolbox';
import { ReactComponent as FaClock } from '../assets/icons/faClock.svg';
import ScheduleComponent from '../components/Schedule.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function Blog() {

    const { t } = i18n;
    const lang = getCurrentLang()
    const [searchParams] = useSearchParams()
    const blogId = (searchParams.get('id') || '').toLowerCase()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [blog, setBlog] = useState<IBlog>()
    const langPrefix = getUrlLangPrefix()

    useEffect(() => {
        fetchBlog()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogId])

    const cleanTitle = blog ? blog.title[lang].replaceAll('?', '').replaceAll(' ', '_') : '';

    const blogLink = (blog: IBlog) => {
        return `${langPrefix}/${t('_route.blog')}/${cleanTitle}?id=${blog.id}`
    }

    const fetchBlog = async () => {
        try {

            const cacheBlogKey = `blog:${blogId}#`

            const cachedBlog = ls.get(cacheBlogKey, { decrypt: true }) as IBlog;
            if (cachedBlog) {
                setBlog(cachedBlog)
            } else {
                const response = await apiService.fetchBlog(blogId)
                if (response.status === 200 && response.data && response.data.blog) {
                    const blogData = response.data.blog as IBlog;
                    ls.set(cacheBlogKey, blogData, { ttl: 1810, encrypt: true });
                    setBlog(blogData)
                }
            }

        } catch (err: any) {

            if (err.response && err.response.status && err.response.status === 404) {
                // not found
            } else {
                setError(true)
            }

        } finally {
            setIsLoading(false)
        }
    }

    return (

        <>
            {blog &&
                <DocumentComponent title={`${t('blog')} > ${blog.title[lang]}`} og={{
                    description: cleanTitle,
                    image: {
                        src: blog.image,
                        type: 'image/jpg',
                        width: 800,
                        height: 450
                    }
                }
                } />
            }

            {isLoading && <Loader />}

            {error && <Error />}

            {!isLoading && !error && !blog && 'blog not found'}

            {blog &&

                <>
                    <Container className='mb-4 blog-container'>
                        <Row>
                            <Col xs={12} lg={{ span: 10, offset: 1 }} className='mt-5 mb-3'>
                                <Row>
                                    <Col xs={12} className='mb-4'>
                                        <h1>{blog.title[lang]}</h1>
                                    </Col>
                                    <Col xs={12} className='mb-5'>
                                        <div className='d-flex flex-column flex-lg-row align-items-start align-items-lg-center  gap-3'>

                                            <div className="d-flex align-items-center justify-content-between gap-1 author-container">
                                                <div className="text-center author-avatar">
                                                    <Image src="/images/logo/PakVille-vrt.png" rounded alt="PakVille"></Image>
                                                </div>
                                                {`${t('published_by')}: ${blog.author}`}
                                            </div>

                                            <span className='d-none d-lg-block'>
                                                |
                                            </span>

                                            <span>
                                                {`${t('posted_on')} ${formatDate(blog.date, lang)}`}
                                            </span>

                                            <span className='d-none d-lg-block'>
                                                |
                                            </span>

                                            <span>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <FaClock />
                                                    <span>
                                                        {`${calculateReadTime(blog.content[lang])} ${t('min')}`}
                                                    </span>
                                                    <span>
                                                        {t('read')}
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </Col>

                                    <Col xs={12} className='mb-4'>
                                        <Image src={blog.image} rounded alt={blog.title[lang]} className="mw-100" />
                                    </Col>


                                    <Col xs={12} className='mb-4'>
                                        <div dangerouslySetInnerHTML={{ __html: blog.content[lang] }} />
                                    </Col>


                                    {blog.related_blog &&
                                        <NavLink to={blogLink(blog.related_blog)} >
                                            <Col xs={12} className='mt-5 mb-5 '>
                                                <div className='d-flex flex-column flex-lg-row gap-4 p-4' style={{ backgroundColor: '#F9F8F8', borderRadius: '20px' }}>
                                                    <div className='related-blog-img-container'>
                                                        <Image src={blog.related_blog.image} rounded alt={blog.related_blog.title[lang]} className="mw-100" />
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <h5>{t('next_article')}: {blog.related_blog.title[lang]}</h5>
                                                        <div>
                                                            {t('read_article')}
                                                            <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </NavLink>
                                    }
                                </Row>

                            </Col>
                        </Row>


                    </Container>


                    <ScheduleComponent />

                </>

            }

        </>
    )
}