export const privacyPolicyEn: string = `

<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ul.lst-kix_ibqveulz93g8-8 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-7 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-6 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-5 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-4 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-3 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-2 {
            list-style-type: none
        }

        .lst-kix_y75wbynhs0cx-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_jjocaga77yvy-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_ibqveulz93g8-1 {
            list-style-type: none
        }

        ul.lst-kix_ibqveulz93g8-0 {
            list-style-type: none
        }

        .lst-kix_y75wbynhs0cx-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jjocaga77yvy-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_jjocaga77yvy-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_jjocaga77yvy-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_jjocaga77yvy-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_ibqveulz93g8-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_ibqveulz93g8-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jjocaga77yvy-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_jjocaga77yvy-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_ibqveulz93g8-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_ibqveulz93g8-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_ibqveulz93g8-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_ibqveulz93g8-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_ibqveulz93g8-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_y75wbynhs0cx-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_jjocaga77yvy-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_y75wbynhs0cx-7>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_1mea951yss04-0 {
            list-style-type: none
        }

        .lst-kix_ibqveulz93g8-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jjocaga77yvy-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_ibqveulz93g8-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_y75wbynhs0cx-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_y75wbynhs0cx-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_y75wbynhs0cx-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_y75wbynhs0cx-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_y75wbynhs0cx-6>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_kbka666puyyd-2 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-1 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-0 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-6 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-5 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-0 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-4 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-1 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-3 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-2 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-8 {
            list-style-type: none
        }

        ul.lst-kix_kbka666puyyd-7 {
            list-style-type: none
        }

        .lst-kix_bdqgnzvk5mdq-2>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_1mea951yss04-5 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-6 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-8 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-7 {
            list-style-type: none
        }

        .lst-kix_bdqgnzvk5mdq-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_1mea951yss04-8 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-6 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-1 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-7 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-2 {
            list-style-type: none
        }

        .lst-kix_bdqgnzvk5mdq-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_jjocaga77yvy-4 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-3 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-5 {
            list-style-type: none
        }

        ul.lst-kix_1mea951yss04-4 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-2 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-3 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-0 {
            list-style-type: none
        }

        ul.lst-kix_jjocaga77yvy-1 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-7 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-8 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-3 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-4 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-5 {
            list-style-type: none
        }

        ul.lst-kix_bdqgnzvk5mdq-6 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-2 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_kbka666puyyd-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_3sw0yshborkn-3 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-4 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_dfd3xiy9wao-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_3sw0yshborkn-5 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kbka666puyyd-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_dfd3xiy9wao-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_3sw0yshborkn-0 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-1 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-5 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-6>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_dfd3xiy9wao-4 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-3 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_dfd3xiy9wao-7>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_dfd3xiy9wao-2 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-6 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-1 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_3sw0yshborkn-7 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-0 {
            list-style-type: none
        }

        ul.lst-kix_3sw0yshborkn-8 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kbka666puyyd-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_kbka666puyyd-6>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_dfd3xiy9wao-8 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-7 {
            list-style-type: none
        }

        ul.lst-kix_dfd3xiy9wao-6 {
            list-style-type: none
        }

        .lst-kix_kbka666puyyd-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_kbka666puyyd-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kbka666puyyd-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_bdqgnzvk5mdq-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_bdqgnzvk5mdq-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_bdqgnzvk5mdq-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_bdqgnzvk5mdq-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_1mea951yss04-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_1mea951yss04-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_1mea951yss04-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_1mea951yss04-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_3sw0yshborkn-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_3sw0yshborkn-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_1mea951yss04-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_3sw0yshborkn-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_3sw0yshborkn-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_3sw0yshborkn-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_3sw0yshborkn-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_3sw0yshborkn-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_akpzl7ddoxyx-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_1mea951yss04-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_akpzl7ddoxyx-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_akpzl7ddoxyx-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_1mea951yss04-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_1mea951yss04-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_akpzl7ddoxyx-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_1mea951yss04-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_akpzl7ddoxyx-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_akpzl7ddoxyx-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_y75wbynhs0cx-0 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-1 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-2 {
            list-style-type: none
        }

        .lst-kix_akpzl7ddoxyx-4>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_y75wbynhs0cx-3 {
            list-style-type: none
        }

        .lst-kix_akpzl7ddoxyx-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_y75wbynhs0cx-4 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-5 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-6 {
            list-style-type: none
        }

        .lst-kix_3sw0yshborkn-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_y75wbynhs0cx-7 {
            list-style-type: none
        }

        ul.lst-kix_y75wbynhs0cx-8 {
            list-style-type: none
        }

        .lst-kix_3sw0yshborkn-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_akpzl7ddoxyx-1>li:before {
            content: "\\0025cb   "
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        ul.lst-kix_akpzl7ddoxyx-0 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-1 {
            list-style-type: none
        }

        .lst-kix_dfd3xiy9wao-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_akpzl7ddoxyx-6 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-7 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-8 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-2 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-3 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-4 {
            list-style-type: none
        }

        ul.lst-kix_akpzl7ddoxyx-5 {
            list-style-type: none
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c9 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #000000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000000;
            vertical-align: top;
            border-right-color: #000000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 234pt;
            border-top-color: #000000;
            border-bottom-style: solid
        }

        .c18 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 20pt;
            font-family: "Arial";
            font-style: normal
        }

        .c0 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c1 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 16pt;
            font-family: "Arial";
            font-style: normal
        }

        .c15 {
            padding-top: 16pt;
            padding-bottom: 4pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c5 {
            padding-top: 18pt;
            padding-bottom: 6pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c16 {
            padding-top: 20pt;
            padding-bottom: 6pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c11 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Arial";
            font-style: normal
        }

        .c8 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c2 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c19 {
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }

        .c12 {
            text-decoration-skip-ink: none;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c17 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c10 {
            color: inherit;
            text-decoration: inherit
        }

        .c4 {
            margin-left: 36pt;
            padding-left: 0pt
        }

        .c7 {
            padding: 0;
            margin: 0
        }

        .c22 {
            margin-left: 72pt;
            padding-left: 0pt
        }

        .c23 {
            background-color: #ff0000
        }

        .c13 {
            background-color: #00ff00
        }

        .c21 {
            background-color: #ffff00
        }

        .c3 {
            height: 11pt
        }

        .c20 {
            background-color: #f9cb9c
        }

        .c14 {
            font-weight: 700
        }

        .c6 {
            height: 0pt
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c17 doc-content">
    <h1 class="c16" id="h.t1v5c6zqark"><span class="c18">Privacy Policy</span></h1>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Last updated: 2024-06-20</span></p>
    <h2 class="c5" id="h.m8imekvozlsk"><span class="c1">1. Privacy Policy Overview</span></h2>
    <p class="c2"><span class="c0">PakVille.ca is operated by PakVille Construction Corp, primarily serving users in Canada and the United States. This Privacy Policy describes how PakVille Construction Corp (the &quot;Site&quot;, &quot;we&quot;, &quot;us&quot;, or
            &quot;our&quot;) collects, uses, and discloses your personal information when you visit, use our services, or make a request for quotation or contact us from pakville.ca (the &quot;Site&quot;) or otherwise communicate with us
            (collectively, the &quot;Services&quot;). For purposes of this Privacy Policy, &quot;you&quot; and &quot;your&quot; means you as the user of the Services, whether you are a customer, website visitor, or another individual whose
            information we have collected pursuant to this Privacy Policy.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Please read this Privacy Policy carefully. By using and accessing any of the Services, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy. If you do not agree to
            this Privacy Policy, please do not use or access any of the Services.</span></p>
    <h2 class="c5" id="h.3kvi1wre715u"><span class="c1">2. Changes to This Privacy Policy</span></h2>
    <p class="c2"><span class="c0">We may update this Privacy Policy from time to time, including to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will post the revised Privacy Policy on the Site, update
            the &quot;Last updated&quot; date and take any other steps required by applicable law.</span></p>
    <h2 class="c5" id="h.yxm2ceikzo52"><span class="c1">3. How We Collect and Use Your Personal Information</span></h2>
    <p class="c2"><span class="c0">To provide the Services, we collect and have collected over the past 12 months personal information about you from a variety of sources, as set out below. The information that we collect and use varies depending on
            how you interact with us.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span>In addition to the specific uses set out below, we may use information we collect about you to communicate with you, provide the Services, comply with any applicable legal obligations, enforce any applicable terms of service,
            and to protect or defend the Services, our rights, and the rights of our users or others.</span></p>
    <p class="c2 c3"><span class="c0 c13"></span></p>
    <p class="c2"><span>We employ adequate organizational, technical, and administrative safeguards to secure information within our company. We implement encryption for data at rest, in storage, and during transmission to ensure the protection of
            our databases. You can examine Amazon Web Services&#39; security safeguards </span><span class="c12"><a class="c10"
                href="https://aws.amazon.com/compliance/iso-certified" target="_blank">here</a></span><span>&nbsp;and explore their Data
            Protection and Privacy information</span><span class="c12"><a class="c10"
                href="https://aws.amazon.com/compliance/data-protection" target="_blank">&nbsp;here</a></span><span class="c0">.</span></p>
    <h2 class="c5" id="h.3uanmk6dfs9d"><span class="c1">4. What Personal Information We Collect</span></h2>
    <p class="c2"><span class="c0">The types of personal information we obtain about you depends on how you interact with our Site and use our Services. When we use the term &quot;personal information&quot;, we are referring to information that
            identifies, relates to, describes or can be associated with you. The following sections describe the categories and specific types of personal information we collect.</span></p>
    <h3 class="c15" id="h.9u06dubum38a"><span class="c11">4.1 Information We Collect Directly from You</span></h3>
    <p class="c2"><span class="c0">Information that you directly submit to us through our Services may include:</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <ul class="c7 lst-kix_bdqgnzvk5mdq-0 start">
        <li class="c2 c4 li-bullet-0"><span class="c0">Basic contact details including your name, address, phone number, email.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">Quotation information including your name, shipping address, email address, phone number.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">Shopping information including the items you view or add to your wishlist.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">Customer support information including the information you choose to include in communications with us, for example, when sending a message through the Contact-Us.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">Some features of the Services may require you to directly provide us with certain information about yourself. You may elect not to provide this information, but doing so may prevent you from
                using or accessing these features.</span></li>
    </ul>
    <h3 class="c15" id="h.75fsiv6hb1qo"><span class="c11">4.2 Information We Collect through Cookies</span></h3>
    <p class="c2"><span class="c0">We also automatically collect certain information about your interaction with the Services (&quot;Usage Data&quot;). To do this, we may use cookies, pixels and similar technologies (&quot;Cookies&quot;). Usage Data
            may include information about how you access and use our Site and your account, including device information, browser information, information about your network connection, your IP address and other information regarding your interaction
            with the Services.</span></p>
    <h3 class="c15" id="h.3pz88ujuivg5"><span class="c11">4.3 Information We Obtain from Third Parties</span></h3>
    <p class="c2"><span class="c0">Finally, we may obtain information about you from third parties, including from vendors and service providers who may collect information on our behalf, such as:</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <ul class="c7 lst-kix_kbka666puyyd-0 start">
        <li class="c2 c4 li-bullet-0"><span>Companies who support our Site and Services, such as AWS, Google Analytics, Meta Business Suite, Calendly.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">When you visit our Site, open or click on emails we send you, or interact with our Services or advertisements, we, or third parties we work with, may automatically collect certain information
                using online tracking technologies such as pixels, web beacons, software developer kits, third-party libraries, and cookies.</span></li>
    </ul>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Any information we obtain from third parties will be treated in accordance with this Privacy Policy. We are not responsible or liable for the accuracy of the information provided to us by third parties and are not
            responsible for any third party&#39;s policies or practices. For more information, see the section below, Third Party Websites and Links.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Amazon Web Services:</span></p>
    <p class="c2"><span>We employ Amazon Web Services to dispatch transactional emails, which include quotations and order confirmations. During this procedure, we provide AWS with the recipient&#39;s email address and an overview of the order. For
            detailed information about AWS&#39; privacy practices, You can consult their privacy policy </span><span class="c12"><a class="c10"
                href="https://aws.amazon.com/compliance/data-protection" target="_blank">here</a></span><span class="c0">.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Google Analytics:</span></p>
    <p class="c2"><span>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Services. This data is shared with other Google
            services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can consult their privacy policy </span><span class="c12"><a class="c10"
                href="https://policies.google.com/privacy" target="_blank">here</a></span><span class="c0">.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Meta Business Suite:</span></p>
    <p class="c2"><span>Meta Business Suite is a comprehensive tool provided by Meta Platforms, Inc. that allows businesses to manage their social media interactions, advertising campaigns, and analytics across Facebook and Instagram. It helps streamline communications and marketing efforts from a single interface. We use Meta Business Suite to enhance our marketing strategies and engage more effectively with our audience. For detailed information about the privacy practices of Meta Business Suite, please consult their privacy policy</span><span class="c12"><a class="c10"
                href="https://developers.facebook.com/docs/meta-pixel/guides/terms-and-policies/" target="_blank">here</a></span><span class="c0">.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Calendly:</span></p>
    <p class="c2"><span>Calendly is a software company that develops a business communication platform used for teams to schedule, prepare and follow up on external meetings. You can consult their privacy policy </span><span class="c12"><a
                class="c10" href="https://calendly.com/privacy" target="_blank">here</a></span><span>.</span></p>

    <h3 class="c15" id="h.3pz88ujuivg5"><span class="c11">4.4 Payment Processing Information</span></h3>
    <p class="c2"><span class="c0">We utilize Stripe, a leading payment processing service, to handle all transactions in our online store securely. When you make a purchase, Stripe manages the processing of your payment details, including credit card information. This process involves encrypting your credit card data to ensure its safety and confidentiality as it is transmitted over the internet. Stripe's advanced security measures comply with the highest industry standards to protect your personal information from unauthorized access and use. For more details on how Stripe processes and protects your personal data, please consult Stripe's privacy policy <span class="c12"><a class="c10"
    href="https://stripe.com/en-ca/privacy" target="_blank">here</a></span><span class="c0">.</span></p></span></p>
    <p class="c2 c3"><span class="c0"></span></p>

    <h2 class="c5" id="h.ya7gpgng4anl"><span class="c1">5. How We Use Your Personal Information</span></h2>
    <ul class="c7 lst-kix_akpzl7ddoxyx-0 start">
        <li class="c2 c4 li-bullet-0"><span class="c14">Providing Products and Services. </span><span class="c0">We use your personal information to provide you with the Services in order to perform our contract with you, including to fulfill your
                orders, to send notifications to you related to your account, purchases, returns, exchanges or other transactions, to create, maintain and otherwise manage your account, to arrange for shipping, facilitate any returns and exchanges
                and to enable you to post reviews.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Marketing and Advertising.</span><span class="c0">&nbsp;We use your personal information for marketing and promotional purposes, such as to send marketing, advertising and promotional
                communications by email, text message or postal mail, and to show you advertisements for products or services. This may include using your personal information to better tailor the Services and advertising on our Site and other
                websites.</span></li>
    </ul>
    <ul class="c7 lst-kix_akpzl7ddoxyx-1 start">
        <li class="c2 c22 li-bullet-0"><span>Be aware that you have the option to unsubscribe from our marketing messages. This can be done by selecting the &#39;unsubscribe&#39; option found at the bottom of our emails. Alternatively, you may reach
                out to us directly to request removal from our mailing list.</span></li>
    </ul>
    <ul class="c7 lst-kix_akpzl7ddoxyx-0">
        <li class="c2 c4 li-bullet-0"><span class="c14">Security and Fraud Prevention.</span><span>&nbsp;</span><span class="c0">We use your personal information to detect, investigate or take action regarding possible fraudulent, illegal or
                malicious activity. </span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Communicating with you. </span><span class="c0">We use your personal information to provide you with customer support and improve our Services. This is in our legitimate interests in order to be
                responsive to you, to provide effective services to you, and to maintain our business relationship with you.</span></li>
    </ul>
    <h2 class="c5" id="h.ewz6v8uuvekb"><span class="c1">6. Cookies</span></h2>
    <p class="c2"><span class="c0">Like many websites, we use Cookies on our Site. We use Cookies to power and improve our Site and our Services (including to remember your actions and preferences), to run analytics and better understand user
            interaction with the Services (in our legitimate interests to administer, improve and optimize the Services). We may also permit third parties and services providers to use Cookies on our Site to better tailor the services, products and
            advertising on our Site and other websites.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Most browsers automatically accept Cookies by default, but you can choose to set your browser to remove or reject Cookies through your browser controls. Please keep in mind that removing or blocking Cookies can
            negatively impact your user experience and may cause some of the Services, including certain features and general functionality, to work incorrectly or no longer be available. Additionally, blocking Cookies may not completely prevent how
            we share information with third parties such as our advertising partners.</span></p>
    <p class="c2 c3"><span class="c0 c20"></span></p>
    <p class="c2"><span>You can choose not to share your usage data of the Service with Google Analytics by installing their opt-out browser extension. This extension prevents Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sending
            your visit information to Google Analytics. For more information about Google&#39;s privacy policies, please go to their Privacy &amp; Terms web page </span><span class="c12"><a class="c10"
                href="https://policies.google.com/privacy" target="_blank">here</a></span><span>.</span></p>
    <h2 class="c5" id="h.1bflma86ely0"><span class="c1">7. How We Disclose Personal Information</span></h2>
    <p class="c2"><span class="c0">In certain circumstances, we may disclose your personal information to third parties for legitimate purposes subject to this Privacy Policy. Such circumstances may include:</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <ul class="c7 lst-kix_jjocaga77yvy-0 start">
        <li class="c2 c4 li-bullet-0"><span class="c0">With vendors or other third parties who perform services on our behalf (e.g., IT management, data analytics, customer support, cloud storage, fulfillment and shipping).</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">With business and marketing partners, to provide services and advertise to you. Our business and marketing partners will use your information in accordance with their own privacy notices.</span>
        </li>
        <li class="c2 c4 li-bullet-0"><span class="c0">When you direct, request us or otherwise consent to our disclosure of certain information to third parties, such as to ship you products or through your use of social media widgets or login
                integrations, with your consent.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">With our affiliates or otherwise within our corporate group, in our legitimate interests to run a successful business.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">In connection with a business transaction such as a merger or bankruptcy, to comply with any applicable legal obligations (including to respond to subpoenas, search warrants and similar
                requests), to enforce any applicable terms of service, and to protect or defend the Services, our rights, and the rights of our users or others.</span></li>
    </ul>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We have, in the past 12 months disclosed the following categories of personal information and sensitive personal information (denoted by *) about users for the purposes set out above in &quot;How we Collect and Use
            your Personal Information&quot; and &quot;How we Disclose Personal Information&quot;:</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2 c3"><span class="c0"></span></p><a id="t.38d40c70f725517c52e87a3e69151a1f377bcf44"></a><a id="t.0"></a>
    <table class="c19">
        <tr class="c6">
            <td class="c9" colspan="1" rowspan="1">
                <p class="c8"><span class="c0">Category</span></p>
            </td>
            <td class="c9" colspan="1" rowspan="1">
                <p class="c8"><span class="c0">Categories of Recipients</span></p>
            </td>
        </tr>
        <tr class="c6">
            <td class="c9" colspan="1" rowspan="1">
                <ul class="c7 lst-kix_ibqveulz93g8-0 start">
                    <li class="c4 c8 li-bullet-0"><span class="c0">Identifiers such as basic contact details and certain order and account information</span></li>
                    <li class="c8 c4 li-bullet-0"><span class="c0">Commercial information such as order information, shopping information and customer support information</span></li>
                    <li class="c8 c4 li-bullet-0"><span class="c0">Internet or other similar network activity, such as Usage Data</span></li>
                </ul>
            </td>
            <td class="c9" colspan="1" rowspan="1">
                <ul class="c7 lst-kix_1mea951yss04-0 start">
                    <li class="c8 c4 li-bullet-0"><span class="c0">Vendors and third parties who perform services on our behalf (such as Internet service providers, fulfillment partners, customer support partners and data analytics providers)</span>
                    </li>
                    <li class="c8 c4 li-bullet-0"><span class="c0">Business and marketing partners</span></li>
                    <li class="c8 c4 li-bullet-0"><span class="c0">Affiliates</span></li>
                </ul>
            </td>
        </tr>
    </table>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We do not use or disclose sensitive personal information for the purposes of inferring characteristics about you.</span></p>
    <h2 class="c5" id="h.tly7y65ksnbw"><span class="c1">8. User Generated Content</span></h2>
    <p class="c2"><span class="c0">The Services may enable you to post product reviews and other user-generated content. If you choose to submit user generated content to any public area of the Services, this content will be public and accessible by
            anyone.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We do not control who will have access to the information that you choose to make available to others, and cannot ensure that parties who have access to such information will respect your privacy or keep it secure.
            We are not responsible for the privacy or security of any information that you make publicly available, or for the accuracy, use or misuse of any information that you disclose or receive from third parties.</span></p>
    <h2 class="c5" id="h.2zmxsgn2fhjk"><span class="c1">9. Third Party Websites and Links</span></h2>
    <p class="c2"><span class="c0">Our Site may provide links to websites or other online platforms operated by third parties. If you follow links to sites not affiliated or controlled by us, you should review their privacy and security policies and
            other terms and conditions. We do not guarantee and are not responsible for the privacy or security of such sites, including the accuracy, completeness, or reliability of information found on these sites. Information you provide on public
            or semi-public venues, including information you share on third-party social networking platforms may also be viewable by other users of the Services and/or users of those third-party platforms without limitation as to its use by us or by
            a third party. Our inclusion of such links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators, except as disclosed on the Services.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <h2 class="c5" id="h.q1v4a86elyf"><span class="c1">10. Children&rsquo;s Data</span></h2>
    <p class="c2"><span class="c0">The Services are not intended to be used by children, and we do not knowingly collect any personal information about children. If you are the parent or guardian of a child who has provided us with their personal
            information, you may contact us using the contact details set out below to request that it be deleted.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">As of the Effective Date of this Privacy Policy, we do not have actual knowledge that we &ldquo;share&rdquo; or &ldquo;sell&rdquo; (as those terms are defined in applicable law) personal information of individuals
            under 16 years of age.</span></p>
    <h2 class="c5" id="h.ke85hkbe1dqn"><span class="c1">11. Security and Retention of Your Information</span></h2>
    <p class="c2"><span class="c0">Please be aware that no security measures are perfect or impenetrable, and we cannot guarantee &ldquo;perfect security.&rdquo; In addition, any information you send to us may not be secure while in transit. We
            recommend that you do not use insecure channels to communicate sensitive or confidential information to us.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">How long we retain your personal information depends on different factors, such as whether we need the information to maintain your account, to provide the Services, comply with legal obligations, resolve disputes
            or enforce other applicable contracts and policies.</span></p>
    <h2 class="c5" id="h.f0uvv9f65mqz"><span class="c1">12. Your Rights and Choices</span></h2>
    <p class="c2"><span class="c0">Depending on where you live, you may have some or all of the rights listed below in relation to your personal information. However, these rights are not absolute, may apply only in certain circumstances and, in
            certain cases, we may decline your request as permitted by law.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <ul class="c7 lst-kix_3sw0yshborkn-0 start">
        <li class="c2 c4 li-bullet-0"><span class="c14">Right to Access / Know.</span><span class="c0">&nbsp;You may have a right to request access to personal information that we hold about you, including details relating to the ways in which we use
                and share your information.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Right to Delete.</span><span class="c0">&nbsp;You may have a right to request that we delete personal information we maintain about you.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Right to Correct.</span><span class="c0">&nbsp;You may have a right to request that we correct inaccurate personal information we maintain about you.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Right of Portability.</span><span class="c0">&nbsp;You may have a right to receive a copy of the personal information we hold about you and to request that we transfer it to a third party, in
                certain circumstances and with certain exceptions.</span></li>
    </ul>
    <ul class="c7 lst-kix_dfd3xiy9wao-0 start">
        <li class="c2 c4 li-bullet-0"><span class="c14">Right to Opt out of Sale or Sharing or Targeted Advertising.</span><span class="c0">&nbsp;You may have a right to direct us not to &quot;sell&quot; or &quot;share&quot; your personal information
                or to opt out of the processing of your personal information for purposes considered to be &quot;targeted advertising&quot;, as defined in applicable privacy laws. Please note that if you visit our Site with the Global Privacy Control
                opt-out preference signal enabled, depending on where you are, we will automatically treat this as a request to opt-out of the &quot;sale&quot; or &quot;sharing&quot; of information for the device and browser that you use to visit the
                Site.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Right to Limit and/or Opt out of Use and Disclosure of Sensitive Personal Information.</span><span class="c0">&nbsp;You may have a right to direct us to limit our use and/or disclosure of
                sensitive personal information to only what is necessary to perform the Services or provide the goods reasonably expected by an average individual.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Restriction of Processing</span><span>.</span><span class="c0">&nbsp;You may have the right to ask us to stop or restrict our processing of personal information.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Withdrawal of Consent. </span><span class="c0">Where we rely on consent to process your personal information, you may have the right to withdraw this consent.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Appeal.</span><span class="c0">&nbsp;You may have a right to appeal our decision if we decline to process your request. You can do so by replying directly to our denial.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c14">Managing Communication Preferences.</span><span class="c0">&nbsp;We may send you promotional emails, and you may opt out of receiving these at any time by using the unsubscribe option displayed
                in our emails to you. If you opt out, we may still send you non-promotional emails, such as those about your account or orders that you have made.</span></li>
    </ul>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">You may exercise any of these rights where indicated on our Site or by contacting us using the contact details provided below.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We will not discriminate against you for exercising any of these rights. We may need to collect information from you to verify your identity, such as your email address or account information, before providing a
            substantive response to the request. In accordance with applicable laws, You may designate an authorized agent to make requests on your behalf to exercise your rights. Before accepting such a request from an agent, we will require that
            the agent provide proof you have authorized them to act on your behalf, and we may need you to verify your identity directly with us. We will respond to your request in a timely manner as required under applicable law.</span></p>
    <h2 class="c5" id="h.aegj5r78hfdh"><span class="c1">13. Complaints</span></h2>
    <p class="c2"><span class="c0">If you have complaints about how we process your personal information, please contact us using the contact details provided below. If you are not satisfied with our response to your complaint, depending on where you
            live you may have the right to appeal our decision by contacting us using the contact details set out below, or lodge your complaint with your local data protection authority.</span></p>
    <h2 class="c5" id="h.4ruzvo87s0c0"><span class="c1">14. International Users</span></h2>
    <p class="c2"><span class="c0">Please note that we may transfer, store, and process your personal information in countries other than your own, including in Canada and the United States.Our staff and third-party service providers and partners,
            who may be located in these countries, also process your personal information.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We take appropriate measures to ensure that any personal information transfer complies with applicable data protection laws and is safeguarded adequately.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
    <ul class="c7 lst-kix_y75wbynhs0cx-0 start">
        <li class="c2 c4 li-bullet-0"><span class="c0">For users in Canada, we adhere to the principles of the Personal Information Protection and Electronic Documents Act (PIPEDA) when transferring personal data.</span></li>
        <li class="c2 c4 li-bullet-0"><span class="c0">For users in the United States, we ensure compliance with relevant federal and state privacy laws during cross-border data transfers.</span></li>
    </ul>
    <p class="c2 c3"><span class="c0"></span></p>
    <p class="c2"><span>In all cases, our aim is to provide a level of protection for your personal information that is consistent with and respectful of the privacy laws applicable in your jurisdiction.</span></p>
    <h2 class="c5" id="h.uvgak4q4d2qv"><span class="c1">15. Contact</span></h2>
    <p class="c2"><span>Should you have any questions about our privacy practices or this Privacy Policy, or if you would like to exercise any of the rights available to you, please call +1 514 885 1361 or email us at </span><span class="c12"><a
                class="c10" href="mailto:info@pakville.ca">info@pakville.ca</a></span><span class="c0">.</span></p>
    <p class="c2 c3"><span class="c0"></span></p>
</body>

</html>`