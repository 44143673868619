import ReactGA from "react-ga4";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"

const GaTracker = () => {
    const { pathname } = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || '');
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            const object = { hitType: "pageview", page: pathname };
            ReactGA.ga('send', object);
        }
    }, [initialized, pathname]);
};

export default GaTracker;