import { Alert } from "react-bootstrap"
import { ReactComponent as FaBadgePercent } from '../assets/icons/faBadgePercent.svg';
import i18n from "../i18n";

const DiscountComponent = () => {

    const {t} = i18n

    return (
        <Alert key={'success'} variant={'dark'} className='d-flex gap-2 align-items-center fw-bold p-2 ps-3 pe-4 m-0 ' style={{backgroundColor: 'var(--bg-light-color)', color: 'var(--tertiary-color)'}}>
            <FaBadgePercent/>
            {t('cart_discount_note', { percent: 10, qty: 10 })}
        </Alert>
    )
}


export default DiscountComponent