import i18n from 'i18next';

export function getLocale() {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const locale = params.get('lang');
    const validLocales = i18n.languages;
    if (locale && validLocales && validLocales.includes(locale)) {
        return locale;
    }
    return 'en';
}

export const getCurrentLang = () => {
    const firstPath = window.location.pathname.split('/')[1];
    const lang = firstPath === 'fr' ? 'fr' : 'en';
    return lang
}

export const getUrlLangPrefix = () => {
    return getCurrentLang() === 'fr' ? '/fr' : ''
}