import i18n from "../i18n"
import { Container } from "react-bootstrap";
import { refundPolicyEn } from "../data/refund-policy-en.html"
import { refundPolicyFr } from "../data/refund-policy-fr.html"

export default function RefundPolicy() {

    const { language } = i18n;

    return (<>

        <Container>

            {language === 'fr' ?
                 <div
                 dangerouslySetInnerHTML={{ __html: refundPolicyFr }}
             />
                :
                <div
                    dangerouslySetInnerHTML={{ __html: refundPolicyEn }}
                />
            }

        </Container>

    </>)
}
