import { Container, Row, Col, Image } from "react-bootstrap";
import DocumentComponent from "../components/Document.component";
import i18n from "../i18n"
import ScheduleComponent from "../components/Schedule.component";
import BannerComponent from "../components/Banner.component";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { ReactComponent as FaEnjoy } from '../assets/illustrations/enjoy.svg';
import { ReactComponent as FaInstallation } from '../assets/illustrations/installation.svg';
import { ReactComponent as FaModelSelection } from '../assets/illustrations/model-selection.svg';
import { ReactComponent as FaDesign } from '../assets/illustrations/design.svg';
import { ReactComponent as FaEPermitCheck } from '../assets/illustrations/permit-check.svg';
import { ReactComponent as FaSiteAssessment } from '../assets/illustrations/site-assessment.svg';
import { ReactComponent as FaSitePreparation } from '../assets/illustrations/site-preparation.svg';
import { ReactComponent as FaUtilityConnections } from '../assets/illustrations/utility-connections.svg';


const steps: number[] = [];

for (let i = 0; i < 8; i++) {
    steps.push(i);
}

const illustration = (key: number) => {

    const width = '18rem';
    const height = '18rem';

    switch (key) {
        case 0:
            return (<FaModelSelection width={width} height={height} />);
        case 1:
            return (<FaSiteAssessment width={width} height={height} />);
        case 2:
            return (<FaEPermitCheck width={width} height={height} />);
        case 3:
            return (<FaDesign width={width} height={height} />);
        case 4:
            return (<FaSitePreparation width={width} height={height} />);
        case 5:
            return (<FaInstallation width={width} height={height} />);
        case 6:
            return (<FaUtilityConnections width={width} height={height} />);
        case 7:
            return (<FaEnjoy />);
        default:
            return (<></>);
    }
}

const stepNo = (index: number) => {
    return index.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })
}

export default function HowItWorks() {

    const { t } = i18n;


    return (<>
        <DocumentComponent title={t('how_it_works')} />

        <BannerComponent title={t('how_it_works')} description={t('how_it_works_description')} />

        <Container className="howItWorksContainer">

            {
                steps.map((step: number) => {

                    let svgContainerOrder = 'order-1 order-md-2';
                    let contentContainerOrder = 'order-2 order-md-1';
                    let animateClass = 'animate__fadeInRight';
                    let contentOffset = 1;

                    if (step % 2 === 0) {
                        svgContainerOrder = 'order-1 order-md-1';
                        contentContainerOrder = 'order-2 order-md-2';
                        animateClass = 'animate__fadeInLeft';
                        contentOffset = 0;
                    }


                    // special class for last step - needs more space!
                    let marginClass = ''
                    if (steps.length === step + 1) {
                        marginClass = 'mt-4 mt-md-0'
                    }

                    return (
                        <Row key={step} >
                            <Col xs={12} md={7} lg={{ span: 6, offset: contentOffset }} className={`${contentContainerOrder} text-center`}>
                                <div className="flexContainer">
                                    <div className={`position-relative ${marginClass}`}>
                                        <span className="stepNumber">{stepNo(step + 1)}</span>
                                        <h3 className="position-relative" style={{ zIndex: 600 }}> <p>{t(`_how_it_works_steps.${step}.step`)}</p></h3>
                                    </div>
                                    <p>{t(`_how_it_works_steps.${step}.description`)}</p>
                                </div>

                            </Col>

                            <Col xs={12} md={5} lg={5} className={svgContainerOrder}>
                                <div className="flexContainer" style={{ alignItems: 'center' }}>

                                    {step === 0 ?
                                        illustration(step)
                                        :
                                        <AnimationOnScroll animateIn={animateClass} animateOnce offset={120}>
                                            {illustration(step)}
                                        </AnimationOnScroll>
                                    }
                                </div>
                            </Col>
                        </Row>
                    )
                })
            }

        </Container>

        <Container className="infoContainer">

            <Row>
                <Col xs={12} lg={7}>
                    <div className="flexContainer text-center text-lg-start align-items-center align-items-lg-start" style={{ gap: '8px' }}>
                        <h2>{t('_how_it_works.info.0.title')}</h2>
                        <p>{t('_how_it_works.info.0.descriptions.0')}</p>
                        <p>{t('_how_it_works.info.0.descriptions.1')}</p>
                        <p>{t('_how_it_works.info.0.descriptions.2')}</p>
                    </div>
                </Col>

                <Col xs={12} lg={5}>
                    <div className="flexContainer mt-4" style={{ alignItems: 'center' }}>
                        <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce>
                            <Image src="/images/home/Foundation_Metal_Screw.png" rounded className="mw-100" alt={`${t('_how_it_works.info.0.title')}`} />
                        </AnimationOnScroll>
                    </div>
                </Col>
            </Row>

        </Container>

        <Container fluid className="infoDarkContainer">
            <Container className="infoContainer" >
                <Row>
                    <Col xs={12} lg={6} className="order-2 order-lg-1 mt-4 mt-lg-0">
                        <div className="flexContainer overlappedImagesContainer">
                            <Image src="/images/home/PakVille-interior03.png" rounded className="mainImage" />
                            <Image className="secondaryImage" src="/images/home/PakVille-interior04.png" rounded />
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className="order-1 order-lg-2">
                        <div className="flexContainer align-items-center text-center align-items-lg-start text-lg-start" style={{ gap: '12px' }}>
                            <h2>{t('_how_it_works.info.1.title')}</h2>

                            <h3>{t('_how_it_works.info.1.section.0.title')}</h3>
                            <p>{t('_how_it_works.info.1.section.0.description')}</p>

                            <h3>{t('_how_it_works.info.1.section.1.title')}</h3>
                            <p>{t('_how_it_works.info.1.section.1.description')}</p>

                            <h3>{t('_how_it_works.info.1.section.2.title')}</h3>
                            <p>{t('_how_it_works.info.1.section.2.description')}</p>
                        </div>
                    </Col>
                </Row>
            </Container>

        </Container>

        <ScheduleComponent />

    </>)
}