import { Container, Row, Col, Image } from "react-bootstrap";
import i18n from "../../i18n";
import storyData from '../../data/story.json'

interface IStory {
    title: string,
    descriptions: string[],
    img: {
        src: string
    }
}

const OurStoryScreen: React.FC = () => {

    const { t } = i18n;

    return (<>

        <Container fluid style={{ backgroundColor: 'var(--bg-light-color)' }}>

            <Container className="flexContainer text-center text-lg-start" style={{paddingBottom: '3rem'}}>
                <Row>
                    <Col xs={12} lg={6} className="mt-4 mt-lg-0">
                        <div className="flexContainer mb-4">
                            <Image src="/images/story/our-story.png" rounded className="mw-100" alt={`PakVille ${t('our_story')}`} />
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className="">
                        <div className="flexContainer">
                            {
                                storyData.map((item: IStory, index: number) => (
                                    <Row key={index} className="mb-5">
                                        <Col xs={12}>
                                            <h2 className="itemHeader">{t(item.title)}</h2>

                                            {item.descriptions.map((description: string, index: number) => (
                                                <p key={index} className="m-0">
                                                    {t(description)}
                                                </p>
                                            ))}

                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    </Col>
                </Row>

            </Container>

        </Container>
    </>)
}

export default OurStoryScreen;