import { useState } from "react";
import BannerComponent from "../components/Banner.component";
import DocumentComponent from "../components/Document.component";
import ScheduleComponent from "../components/Schedule.component";
import i18n from "../i18n"
import { Container, Row, Col, Image, Modal } from "react-bootstrap";
import { IGalleryImage } from "../interfaces/IGalleryImage";
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

export default function GalleryPage() {

    const { t } = i18n;
    const [showGallery, setShowGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const images: IGalleryImage[] = []

    for (let i = 1; i < 10; i++) {
        images.push({
            original: `/images/gallery/${i}_Expo_habitation_2024.jpg`,
            thumbnail: `/images/gallery/${i}_Expo_habitation2024_thumbnail.png`,
            description: 'Expo Habitation 2024 - Montréal',
            alt: 'Expo Habitation 2024 - Montréal',
           
        })
    }

    for (let j = 1; j < 7; j++) {
        images.push({
            original: `/images/gallery/2024_District_Habitat_Terrebonne/${j}_District_Habitat_2024-Terrebonne.png`,
            thumbnail: `/images/gallery/2024_District_Habitat_Terrebonne/${j}_District_Habitat_2024-Terrebonne_thumbnail.png`,
            description: 'District Habitat 2024 - Terrebonne',
            alt: 'District Habitat 2024 - Terrebonne',
        })
    }

    const openGallery = (index: number) => {
        setCurrentIndex(index);
        setShowGallery(true);
    };

    return (<>

        <DocumentComponent title={t('gallery')} />

        <BannerComponent title={t('gallery')} description={t('gallery_description')} />

        <Modal show={showGallery} fullscreen={true} onHide={() => setShowGallery(false)} data-bs-theme="dark" className='modalGallery'>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <Gallery
                    items={images}
                    startIndex={currentIndex}
                    showThumbnails={false}
                />
            </Modal.Body>
        </Modal>

        <Container>

            <Row>

                {images.map((item: IGalleryImage, index: number) => {

                    return (
                        <Col key={index} xs={6} md={3} className="mb-4 position-relative text-center">
                            <Image src={item.thumbnail} role="button" className="mw-100" onClick={() => openGallery((index))} alt={'Expo Habitation 2024 - Montréal'} loading="lazy"/>
                            <div className="gallery-thumbnail" onClick={() => openGallery((index))} >{item.alt} </div>
                        </Col>
                    )
                })}

            </Row>

        </Container>

        <ScheduleComponent />

        <br />
        <br />
        <br />

    </>)
}
