import { Container, Row, Col } from "react-bootstrap";
import styles from '../styles/Hero.module.css';

interface HeroComponentProps {
    title: string,
    img?: string,
    style?: object
}

const HeroComponent: React.FC<HeroComponentProps> = ({ title, style }) => {

    return (<>
        <Container fluid className={styles.mainContainer} style={style}>
            <Container>
                <Row>
                    <Col>
                        <h1>{title}</h1>
                        <hr></hr>
                    </Col>
                </Row>
            </Container>

        </Container>
    </>)
}

export default HeroComponent;