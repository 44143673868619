import { Container, Row, Col, Button } from "react-bootstrap";
import i18n from "../../i18n";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { NavLink } from "react-router-dom";
import { getUrlLangPrefix } from "../../utils/Local";


const InstallationScreen: React.FC = () => {

    const { t } = i18n;
    const langPrefix = getUrlLangPrefix()

    return (<>

        <Container fluid className="screenContainer" style={{ backgroundColor: '#FFFFFF' }}>
            <Container className="flexContainer">
                <Row>

                    <Col xs={12} md={7}>
                        <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce>
                            <div className="ratio ratio-16x9 text-center mt-4 mb-4">
                                <iframe className="embed-responsive-item w-100" src={`https://www.youtube.com/embed/Sx4q4cjNVn4?si=vKEncFxvcciqNqSW`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" width="560" height="315"></iframe>
                            </div>
                        </AnimationOnScroll>
                    </Col>
                    
                    <Col xs={12} md={{span: 4, offset: 1}} className="order-1 order-lg-2">
                        <div className="flexContainer installationContent" style={{ gap: '8px' }}>
                            <h2>{t('_screen.2.title')}</h2>
                            <p>{t('_screen.2.description')}</p>
                            <p>{t('_screen.2.description_2')}</p>
                            <NavLink to={`${langPrefix}/${t('_route.how-it-works')}`}>
                                <Button className="btn-primary">{t('learn_more')}</Button>
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

    </>)
}

export default InstallationScreen;