import { useEffect } from "react"
import useCart from "../hooks/useCart"
import { Button, Container } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import i18n from "../i18n"
import { ReactComponent as FaHome } from '../assets/icons/faHome.svg';
import { ReactComponent as FaCheckCircle } from '../assets/icons/faCheckCircle.svg';
import { getUrlLangPrefix } from "../utils/Local"


export default function PaymentSuccess() {

    const { dispatch, REDUCER_ACTIONS } = useCart()
    const { t } = i18n

    useEffect(() => {
        dispatch({ type: REDUCER_ACTIONS.RESET })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Container className=''>
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '70vh' }}>

                    <div className="d-flex flex-column justify-content-between align-items-center gap-3 w-50 text-center">

                        <FaCheckCircle />
                        <h1>{t('_success_payment_header')}</h1>

                        <p>{t('_success_payment_description')}</p>

                        <NavLink to={`${getUrlLangPrefix()}/`} className='mt-2'>
                            <Button variant="outline-primary d-flex justify-content-center gap-2 ps-4 pe-4 pt-2 pb-2" >
                                <FaHome />
                                <span>{t('go_to_homepage')}</span>
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </Container>

        </>
    )
}