import { createContext, ReactElement, useMemo, useReducer } from "react"
import ls from 'localstorage-slim';
import { ICart, ICartItem, IContact, IShipping, ITaxes, IUpdatedCart } from "../interfaces/ICart";

type CartStateType = {
    cart: ICart,
    recentCartItem: ICartItem
}

const cachedCart = ls.get('cart', { decrypt: true })

const initCartState: CartStateType = {
    cart: cachedCart ? cachedCart as ICart : {
        id: '',
        items: [],
        taxes: {} as ITaxes,
        delivery_method: '',
        shipping: {} as IShipping,
        calculated_total: 0,
        subtotal: 0,
        discount: 0,
        contact: {
            name: '',
            email: '',
            phone: '',
            company: ''
        } as IContact

    } as ICart,
    recentCartItem: {} as ICartItem
}

const REDUCER_ACTION_TYPE = {
    ADD: "ADD",
    REMOVE: "REMOVE",
    QUANTITY: "QUANTITY",
    SUBMIT: "SUBMIT",
    UPDATE: "UPDATE",
    RESET: "CLEAR",
}

export type ReducerActionType = typeof REDUCER_ACTION_TYPE

export type ReducerAction = {
    type: string,
    payload?: ICartItem,
    updatedCart?: IUpdatedCart
}

interface IDiscount {
    [key: string]: number;
}

const calculate_discount = (items: ICartItem[]) => {

    const discount_codes: IDiscount = {}

    // Calculate quantity per discount code
    items.map(item => {

        const dc = item.discount_code

        if (dc) {
            if (dc in discount_codes) {
                discount_codes[dc] += item.quantity
            } else {
                discount_codes[dc] = item.quantity
            }
        }

        return item
    })


    items.map(item => {

        const dc = item.discount_code
        if (dc && discount_codes[dc] >= 10) {
            item.price = item.list * ((100 - 10)) / 100
        } else {
            item.price = item.list
        }

        return item
    })


    return items
}

const reducer = (state: CartStateType, action: ReducerAction):
    CartStateType => {

    switch (action.type) {

        case REDUCER_ACTION_TYPE.RESET: {
            return initCartContextState
        }

        case REDUCER_ACTION_TYPE.UPDATE: {

            if (!action.updatedCart) {
                throw new Error('Missing Updated Cart')
            }

            const cartId = action.updatedCart.id
            const newItems = action.updatedCart.items
            const items = state.cart.items
            const taxes = action.updatedCart.taxes
            const calculated_total = action.updatedCart.total
            const subtotal = action.updatedCart.subtotal
            const discount = action.updatedCart.discount
            const delivery_method = action.updatedCart.delivery_method
            const shipping = action.updatedCart.shipping
            const contact = action.updatedCart.contact

            const updatedItems: ICartItem[] = items
                .filter(item => newItems.find(newItem => newItem.sku === item.sku))
                .map(item => {
                    const newItem = newItems.find(newItem => newItem.sku === item.sku);
                    return {
                        ...item,
                        price: newItem ? newItem.price : item.price
                    };
                });

            const updatedCart = {
                id: cartId,
                items: updatedItems,
                taxes: taxes,
                calculated_total: calculated_total,
                subtotal: subtotal,
                discount: discount,
                delivery_method: delivery_method,
                shipping: shipping,
                contact: contact
            }

            return {
                ...state, cart: updatedCart
            }
        }

        case REDUCER_ACTION_TYPE.ADD: {
            if (!action.payload) {
                throw new Error('Missing Payload in ADD action')
            }

            const addedVariant = action.payload
            const filteredCartItems: ICartItem[] = state.cart.items.filter(item => item.sku !== addedVariant.sku)
            const itemExists: ICartItem | undefined = state.cart.items.find(item => item.sku === addedVariant.sku)
            let qty: number = itemExists ? itemExists.quantity + addedVariant.quantity : addedVariant.quantity
            if (qty < 1) {
            } else if (qty > 999) {
                qty = 999
            }

            // Exception for Sample Product
            // if (addedVariant.sku.toLowerCase().endsWith('sample')) {
            //     qty = 1
            // }

            addedVariant.quantity = qty

            let items = [...filteredCartItems, addedVariant]
            items = calculate_discount(items)

            return {
                ...state, cart: {
                    id: state.cart.id,
                    items: items,
                    taxes: {} as ITaxes,
                    calculated_total: 0,
                    subtotal: 0,
                    discount: 0,
                    delivery_method: state.cart.delivery_method,
                    shipping: state.cart.shipping,
                    contact: state.cart.contact
                },
                recentCartItem: action.payload
            }
        }

        case REDUCER_ACTION_TYPE.REMOVE: {

            if (!action.payload) {
                throw new Error('Missing Payload in REMOVE action')
            }

            const { sku } = action.payload
            let filteredItems: ICartItem[] = state.cart.items.filter(item => item.sku !== sku)
            const items = calculate_discount(filteredItems)

            return {
                ...state, cart: {
                    id: state.cart.id,
                    items: items,
                    taxes: {} as ITaxes,
                    calculated_total: 0,
                    subtotal: 0,
                    discount: 0,
                    delivery_method: state.cart.delivery_method,
                    shipping: state.cart.shipping,
                    contact: state.cart.contact
                }
            }
        }

        case REDUCER_ACTION_TYPE.QUANTITY: {
            if (!action.payload) {
                throw new Error('Missing Payload in QUANTITY action')
            }

            const { sku, quantity } = action.payload
            const itemExists: ICartItem | undefined = state.cart.items.find(item => item.sku === sku)

            if (!itemExists) {
                throw new Error('Item not found')
            }

            let qty = quantity;
            if (quantity < 1) {
                qty = 1
            } else if (quantity > 999) {
                qty = 999
            } else {
                qty = quantity
            }

            const updatedItem: ICartItem = { ...itemExists, quantity: qty }

            const filteredCart: ICartItem[] = state.cart.items.filter(item => item.sku !== sku)

            let items = [...filteredCart, updatedItem]
            items = calculate_discount(items)

            return {
                ...state, cart: {
                    id: state.cart.id,
                    items: items,
                    taxes: {} as ITaxes,
                    calculated_total: 0,
                    subtotal: 0,
                    discount: 0,
                    delivery_method: state.cart.delivery_method,
                    shipping: state.cart.shipping,
                    contact: state.cart.contact
                }
            }
        }

        case REDUCER_ACTION_TYPE.SUBMIT: {
            return { ...state, cart: {} as ICart }
        }

        default:
            throw new Error('Invalid Action Type')
    }

}


const useCartContext = (initCartState: CartStateType) => {
    const [state, dispatch] = useReducer(reducer, initCartState)

    const REDUCER_ACTIONS = useMemo(() => {
        return REDUCER_ACTION_TYPE
    }, [])


    const totalItems = state.cart.items.reduce((prevValue, cartItem) => {
        return prevValue + cartItem.quantity
    }, 0)


    const subtotal = state.cart.items.reduce((prevValue, cartItem) => {
        return prevValue + (cartItem.quantity * cartItem.list)
    }, 0)

    const total = state.cart.items.reduce((prevValue, cartItem) => {
        return prevValue + (cartItem.quantity * cartItem.price)
    }, 0)

    const discount = subtotal - total

    const cart = {
        id: state.cart.id,
        items: state.cart.items.sort((a, b) => {
            return ('' + a.sku).localeCompare(b.sku);
        }),
        taxes: state.cart.taxes,
        calculated_total: state.cart.calculated_total,
        subtotal: state.cart.subtotal,
        discount: state.cart.discount,
        delivery_method: state.cart.delivery_method,
        shipping: state.cart.shipping,
        contact: state.cart.contact,
    }

    const recentCartItem = state.recentCartItem

    return { dispatch, REDUCER_ACTIONS, totalItems, subtotal, total, discount, cart, recentCartItem }
}


export type UseCartContextType = ReturnType<typeof useCartContext>

const initCartContextState: UseCartContextType = {
    dispatch: () => { },
    REDUCER_ACTIONS: REDUCER_ACTION_TYPE,
    totalItems: 0,
    subtotal: 0,
    total: 0,
    discount: 0,
    cart: {
        id: '',
        items: [],
        taxes: {} as ITaxes,
        delivery_method: 'pickup',
        shipping: {} as IShipping,
        calculated_total: 0,
        subtotal: 0,
        discount: 0,
        contact: {
            name: '',
            email: '',
            phone: '',
            company: ''
        } as IContact
    },
    recentCartItem: {} as ICartItem
}

const CartContext = createContext<UseCartContextType>(initCartContextState);

type CartProviderProps = { children?: ReactElement | ReactElement[] }

export const CartProvider = ({ children }: CartProviderProps): ReactElement => {
    return (
        <CartContext.Provider value={useCartContext(initCartState)}>
            {children}
        </CartContext.Provider>
    )
}

export default CartContext
