import { ICartItem, ICartItemAttribute } from "../interfaces/ICart"
import { Image } from "react-bootstrap";
import { formatArea, formatter, formatWeight } from "../utils/Toolbox";
import i18n from "../i18n";
import { NavLink } from "react-router-dom";
import { displayItemTotal } from "../utils/Helper";
import { getCurrentLang, getUrlLangPrefix } from "../utils/Local";

interface CartItemInfoComponentProps {
    item: ICartItem
}


const CartItemInfo: React.FC<CartItemInfoComponentProps> = ({ item }) => {

    const { t } = i18n
    const lang = getCurrentLang()
    const langPrefix = getUrlLangPrefix()

    return (
        <>

            <div className="d-flex align-items-stretch gap-1 cart-item-info-container">

                <div style={{ maxWidth: '30%', minWidth: '30%' }}>
                    <NavLink to={`${langPrefix}/${t('_route.shop')}/${item.slug}`} className='d-grid gap-2'>
                        <Image src={item.image} role="button" className='mw-100 m-auto mt-0 mb-0' rounded  />
                    </NavLink>
                </div>

                <ul style={{ listStyle: 'none' }}>
                    <li className="list-group-item">
                        <NavLink to={`${langPrefix}/${t('_route.shop')}/${item.slug}`} className='d-grid gap-2 fw-bold'>
                            {item.name}
                        </NavLink>
                    </li>
                    <li className="mb-2">
                        {item.sku}
                    </li>

                    <li>
                        {displayItemTotal(item)}
                    </li>

                    {
                        item.attributes && item.attributes.map((attribute: ICartItemAttribute, index: number) => {
                            return (
                                <li key={index}>
                                    {`${attribute.option.label[lang].trim()}: ${attribute.option_value.label[lang]}`}
                                </li>
                            )
                        })
                    }

                    <li>
                        {`${t('total_area')}: ${formatArea(item.area * item.quantity, lang)}`}
                    </li>
                    <li>
                        {`${t('total_weight')}: ${formatWeight(item.weight * item.quantity)}`}
                    </li>
                    <li>
                        {`${t('price_per_panel')}: ${formatter.format(item.list / 100)}`}
                    </li>
                </ul>

                <div className="ms-auto">
                    {t('Qty')}:&nbsp;{item.quantity}
                </div>

            </div>
        </>
    )
}

export default CartItemInfo