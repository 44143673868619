import { Container, Row, Col, Image, Button } from "react-bootstrap";
import i18n from "../../i18n";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { NavLink } from "react-router-dom";
import { getUrlLangPrefix } from "../../utils/Local";


const PakVertScreen: React.FC = () => {

    const { t } = i18n;
    const langPrefix = getUrlLangPrefix()

    return (<>
        <Container fluid className="screenContainer p-0 pt-4 pt-lg-0" style={{ background: 'radial-gradient(52.81% 80.66% at 47.32% 56.75%, #E1F9FB 0%, #7DB799 100%)', }}>

            <Container className="flexContainer pakvertContainer">
                <Row>
                    <Col xs={12} lg={7}>
                        <div className="flexContainer" style={{ gap: '8px' }}>
                            <h2>{t('_screen.1.title')}</h2>
                            <h3>{t('_screen.1.subtitle')}</h3>
                            <p>{t('_screen.1.description')}</p>
                            <NavLink to={`${langPrefix}/${t('_route.the-technology')}`}>
                                <Button className="btn-primary">{t('learn_more')}</Button>
                            </NavLink>
                        </div>
                    </Col>

                    <Col xs={12} lg={5}>
                        <div className="flexContainer">
                            <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce>
                                <Image src="/images/home/Eco_Friendly_Innovation.png" rounded className="mw-100" alt={`${t('_screen.1.title')}`} />
                            </AnimationOnScroll>
                        </div>
                    </Col>
                </Row>

            </Container>

        </Container>
    </>)
}

export default PakVertScreen;