import { Container, Row, Col } from "react-bootstrap";
import i18n from "../i18n";


interface IPremiumUpgrades {
    title: string,
    description: string,
    img: {
        src: string
    }
}


const PremiumUpgradesComponent: React.FC = () => {

    const { t } = i18n

    const data = [
        {
            title: t('premium_upgrades_list.cooling_and_heating'),
            description: t('premium_upgrades_list.cooling_and_heating_description'),
            img: {
                src: "/images/models/pu_cooling_and_heating.png"
            }
        },
        {
            title: t('premium_upgrades_list.metal_panels'),
            description: t('premium_upgrades_list.metal_panels_description'),
            img: {
                src: "/images/models/pu_metal_panels.png"
            }
        },
        {
            title: t('premium_upgrades_list.solar_metal_roof'),
            description: t('premium_upgrades_list.solar_metal_roof_description'),
            img: {
                src: "/images/models/pu_solar_metal_roof.png"
            }
        },
    ]

    return (<>
        <Container fluid>

            <Container className="text-center">
                <Row>
                    {
                        data.map((premiumUpgrade: IPremiumUpgrades, index: number) => (
                            <Col key={index} lg={4} className="mb-4">
                                <Row>
                                    <Col xs={12} className="order-2 order-md-1">
                                        <div className='premiumUpgradesImgContainer' style={{ backgroundImage: `url(${premiumUpgrade.img.src})` }}></div>
                                    </Col>
                                    <Col xs={12} className="mt-4 mt-md-0 order-1 order-md-2">
                                        <h2 className="modelHeader">{t(premiumUpgrade.title)}</h2>
                                        <p className="mt-4">{t(premiumUpgrade.description)}</p>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

        </Container>
    </>)
}

export default PremiumUpgradesComponent;