import { useParams, Navigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import i18n from "../i18n"
import { Container, Row, Col, Card, Button, Image, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faGlobe, faEnvelope, faPlus } from "@fortawesome/free-solid-svg-icons";
import { facebookLink, flatPhoneNumber, getBaseUrl, instagramLink, youTubeLink, linkedinLink } from '../utils/Toolbox';
import { faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import DocumentComponent from "../components/Document.component";

export default function VCard() {

    const { t } = i18n;

    const params = useParams();
    const contact = (params.contact || '').toLowerCase();

    if (contact === undefined || contact !== 'parinaz_pakniat') {
        return (<Navigate to="/" />)
    }

    const phoneNumber = '(514) 885-1361';
    const email = 'parinaz@pakville.ca';

    return (<>

        <DocumentComponent title='Parinaz' />

        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Container>
                <Row>
                    <Col xs={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }} className='text-center'>

                        <Card className='vcard'>
                            <Card.Header className='p-0'>
                                <Button variant="primary" style={{position: 'absolute', top: '5px', right: '5px', padding: '0.25rem 0.5rem'}} title={t('add_to_contacts')}>
                                    <NavLink to="/vcards/ParinazPakniat.vcf" target="_blank" download><FontAwesomeIcon icon={faPlus} /></NavLink>
                                </Button>
                                <Image src={'/images/story/ParinazPakniatAvatar.jpg'} style={{ maxWidth: '30%' }} roundedCircle className="mt-4 mb-4" alt={'Parinaz Pakniat'} title={'Parinaz Pakniat'} />
                                <h1 className="modelHeader text-center text-light">Parinaz Pakniat</h1>
                                <h2 style={{ fontSize: '1rem', color: '#e5e5e5' }} >Founder & CEO / Fondatrice et PDG</h2>

                                <div className='d-flex justify-content-around mt-4 p-2 border-top ' >

                                    <a rel="noreferrer" href={`tel:${flatPhoneNumber(phoneNumber)}`} className='border-end' style={{ flex: '2 0' }} >
                                        <FontAwesomeIcon icon={faPhone} />
                                        <br />
                                        {t('call')}
                                    </a>

                                    <a rel="noreferrer" href={`mailto:${email}`} style={{ flex: '2 0' }} >
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <br />
                                        {t('email')}
                                    </a>
                                </div>
                            </Card.Header>

                            <Card.Body className='p-1'>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>
                                        <Card.Link href={`tel:${flatPhoneNumber(phoneNumber)}`}>
                                            <div className='vcardInfoContainer'>
                                                <FontAwesomeIcon icon={faPhone} />
                                                {phoneNumber}
                                            </div>
                                        </Card.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Card.Link href={`mailto:${email}`}>
                                            <div className='vcardInfoContainer'>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                                {email}
                                            </div>
                                        </Card.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Card.Link href={'/'} target='_blank'>
                                            <div className='vcardInfoContainer'>
                                                <FontAwesomeIcon icon={faGlobe} />
                                                {getBaseUrl()}
                                            </div>
                                        </Card.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Card.Link href={youTubeLink()} target='_blank'>
                                            <FontAwesomeIcon icon={faYoutube} />
                                        </Card.Link>
                                        <Card.Link href={facebookLink()} target='_blank'>
                                            <FontAwesomeIcon icon={faFacebook} />
                                        </Card.Link>
                                        <Card.Link href={instagramLink()} target='_blank'>
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </Card.Link>
                                        <Card.Link href={linkedinLink()} target='_blank'>
                                            <FontAwesomeIcon icon={faLinkedin} />
                                        </Card.Link>

                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Card.Text style={{ fontSize: '14px' }}>
                                            Build your Dream Space from Recycled Plastic Bottles. <br/> Construisez votre propre espace d'évasion à partir de en plastique recyclées.
                                        </Card.Text>
                                    </ListGroup.Item>
                                </ListGroup>

                            </Card.Body>

                            <Card.Footer>
                                <Button variant="primary">
                                    <NavLink to="/vcards/ParinazPakniat.vcf" target="_blank" download><FontAwesomeIcon icon={faPlus} /> {t('add_to_contacts')} </NavLink>
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    </>)
}

