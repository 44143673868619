
import useCart from '../hooks/useCart';
import DocumentComponent from '../components/Document.component';
import i18n from '../i18n';
import { Col, Container, Form, Row, Button, Card, Image } from 'react-bootstrap';
import { formatArea, formatter, formatWeight } from '../utils/Toolbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { ChangeEvent } from 'react';
import { ICartItem, ICartItemAttribute } from '../interfaces/ICart';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavLink } from 'react-router-dom';
import { ReactComponent as FaCart } from '../assets/icons/faCart.svg';
import { displayItemTotal } from '../utils/Helper';
import DiscountComponent from '../components/Discount.component';
import { getCurrentLang, getUrlLangPrefix } from '../utils/Local';


export default function Cart() {

    const { t } = i18n
    const langPrefix = getUrlLangPrefix()
    const lang = getCurrentLang()
    const { dispatch, REDUCER_ACTIONS, cart, totalItems, total, subtotal, discount } = useCart()

    const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>, item: ICartItem) => {
        let value = Number(e.target.value)
        if (value < 1) {
            value = 1
        } else if (value > 1000) {
            value = 999
        }

        dispatch({ type: REDUCER_ACTIONS.QUANTITY, payload: { ...item, quantity: value } })
    }

    return (
        <>
            <DocumentComponent title={t('cart')} />

            <Container className='cart-container mt-4 mb-4'>

                {cart && cart.items.length ?

                    <>
                        <Row className='mt-5 mb-5'>
                            <Col>

                                <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center'>
                                    <h1>{t('shopping_cart')} <span className='text-uppercase ms-2'>{`${totalItems}  ${totalItems > 1 ? t('items') : t('item')}`} </span></h1>

                                    <DiscountComponent />

                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-5 mb-5'>
                            <Col lg={9} xl={8}>

                                <Row className='border-bottom cart-list-header'>
                                    <Col xs={6}>{t('item')}</Col>
                                    <Col xs={2} className='ps-4 d-none d-lg-block'>{t('price')}</Col>
                                    <Col xs={2} className='text-start d-none d-lg-block'>{t('quantity')}</Col>
                                    <Col xs={6} lg={1} className='text-end'>{t('total')}</Col>
                                    <Col lg={1}></Col>
                                </Row>

                                {cart.items.map((item: ICartItem, index: number) => {
                                    return (
                                        <Row key={index} className='border-bottom pt-4 pb-4 cart-list-body'>
                                            {/* Mobile Screen */}
                                            <Col className='d-block d-lg-none'>
                                                <Row>
                                                    <Col xs={2}>
                                                        <NavLink to={`${langPrefix}/${t('_route.shop')}/${item.slug}`} className='d-grid gap-2'>
                                                            <Image src={item.image} role='button' className='mw-100 m-auto mt-0 mb-0' rounded />
                                                        </NavLink>
                                                    </Col>

                                                    <Col xs={7}>

                                                        <Row>
                                                            <Col xs={10} className='cart-item cart-item-info'>
                                                                <NavLink to={`${langPrefix}/${t('_route.shop')}/${item.slug}`} className='d-grid gap-2 fw-bold'>
                                                                    {item.name}
                                                                </NavLink>
                                                                {item.sku}
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xs={12}>
                                                                {item.attributes && item.attributes.map((attribute: ICartItemAttribute, index: number) => {
                                                                    return (
                                                                        <p key={index} className='m-0'>
                                                                            {`${attribute.option.label[lang].trim()}: ${attribute.option_value.label[lang]}`}
                                                                        </p>
                                                                    )
                                                                })}

                                                                <p className='m-0'>
                                                                    {`${t('total_area')}: ${formatArea(item.area * item.quantity, lang)}`}
                                                                </p>

                                                                <p className='m-0'>
                                                                    {`${t('total_weight')}: ${formatWeight(item.weight * item.quantity)}`}
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                        <Row className='mb-3'>
                                                            <Col xs={{ span: 12 }} className='cart-item cart-item-price pt-1'>
                                                                <p>{`${t('price_per_panel')}: ${formatter.format(item.list / 100)}`}</p>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xs={{ span: 8 }} className='cart-item cart-item-quantity'>

                                                                <div className='d-flex align-items-center justify-content-between border rounded p-1 ps-2 pe-2'>
                                                                    <FontAwesomeIcon icon={faMinus} onClick={() => item.quantity > 1 ? dispatch({ type: REDUCER_ACTIONS.QUANTITY, payload: { ...item, quantity: (item.quantity - 1) } }) : undefined} style={{ cursor: item.quantity > 1 ? 'pointer' : 'not-allowed' }} />
                                                                    <Form.Control
                                                                        required
                                                                        type="number"
                                                                        maxLength={10}
                                                                        value={item.quantity}
                                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e, item)}
                                                                        className='quantity-input'
                                                                    />
                                                                    <FontAwesomeIcon icon={faPlus} onClick={() => item.quantity < 999 ? dispatch({ type: REDUCER_ACTIONS.QUANTITY, payload: { ...item, quantity: (item.quantity + 1) } }) : undefined} style={{ cursor: item.quantity < 999 ? 'pointer' : 'not-allowed' }} />
                                                                </div>

                                                            </Col>
                                                        </Row>

                                                    </Col>

                                                    <Col xs={3} className='cart-item cart-item-total text-end pt-1'>
                                                        {displayItemTotal(item)}
                                                        <Button variant="link" className='text-start text-decoration-none p-0 cart-item-remove-btn' onClick={() => dispatch({ type: REDUCER_ACTIONS.REMOVE, payload: item })}>
                                                            <FontAwesomeIcon icon={faTrashAlt as IconProp} />
                                                        </Button>
                                                    </Col>

                                                </Row>
                                            </Col>

                                            {/* Large Screen */}
                                            <Col className='d-none d-lg-block'>
                                                <Row>

                                                    <Col xs={2}>
                                                        <NavLink to={`${langPrefix}/${t('_route.shop')}/${item.slug}`} className='d-grid gap-2'>
                                                            <Image src={item.image} role='button' className='mw-100 m-auto mt-0 mb-0' rounded />
                                                        </NavLink>
                                                    </Col>

                                                    <Col xs={10}>

                                                        <Row>
                                                            <Col xs={5} className='cart-item cart-item-info'>
                                                                <NavLink to={`${langPrefix}/${t('_route.shop')}/${item.slug}`} className='d-grid gap-2 fw-bold'>
                                                                    {item.name}
                                                                </NavLink>
                                                                {item.sku}
                                                            </Col>

                                                            <Col xs={2} className='cart-item cart-item-price pt-1'>
                                                                <span>{formatter.format(item.list / 100)}</span>
                                                            </Col>

                                                            <Col xs={2} className='cart-item cart-item-quantity'>

                                                                <div className='d-flex align-items-center justify-content-between border rounded p-1 ps-2 pe-2'>
                                                                    <FontAwesomeIcon icon={faMinus} onClick={() => item.quantity > 1 ? dispatch({ type: REDUCER_ACTIONS.QUANTITY, payload: { ...item, quantity: (item.quantity - 1) } }) : undefined} style={{ cursor: item.quantity > 1 ? 'pointer' : 'not-allowed' }} />
                                                                    <Form.Control
                                                                        required
                                                                        type="number"
                                                                        maxLength={10}
                                                                        value={item.quantity}
                                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e, item)}
                                                                        className='quantity-input'
                                                                    />
                                                                    <FontAwesomeIcon icon={faPlus} onClick={() => item.quantity < 999 ? dispatch({ type: REDUCER_ACTIONS.QUANTITY, payload: { ...item, quantity: (item.quantity + 1) } }) : undefined} style={{ cursor: item.quantity < 999 ? 'pointer' : 'not-allowed' }} />
                                                                </div>

                                                            </Col>

                                                            <Col xs={2} className='cart-item cart-item-total text-end pt-1'>
                                                                {displayItemTotal(item)}
                                                            </Col>

                                                            <Col xs={1} className='text-end pt-1'>
                                                                <Button variant="link" className='text-start text-decoration-none p-0 cart-item-remove-btn' onClick={() => dispatch({ type: REDUCER_ACTIONS.REMOVE, payload: item })}>
                                                                    <FontAwesomeIcon icon={faTrashAlt as IconProp} />
                                                                </Button>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col xs={11}>
                                                                {item.attributes && item.attributes.map((attribute: ICartItemAttribute, index: number) => {
                                                                    return (
                                                                        <p key={index} className='m-0'>
                                                                            {`${attribute.option.label[lang].trim()}: ${attribute.option_value.label[lang]}`}
                                                                        </p>
                                                                    )
                                                                })}

                                                                <p className='m-0'>
                                                                    {`${t('total_area')}: ${formatArea(item.area * item.quantity, lang)}`}
                                                                </p>

                                                                <p className='m-0'>
                                                                    {`${t('total_weight')}: ${formatWeight(item.weight * item.quantity)}`}
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                })}


                            </Col>

                            <Col lg={3} xl={{ span: 3, offset: 1 }} >
                                <Card>
                                    <Card.Body className='p-4'>
                                        <Card.Title className='mb-4'>{t('order_summary')}</Card.Title>

                                        <div className='d-flex justify-content-between mb-3'>
                                            <span>{t('sub_total')}</span>
                                            <span>{formatter.format(subtotal / 100)}</span>
                                        </div>

                                        {discount > 0 &&
                                            <div className='d-flex justify-content-between mb-3'>
                                                <span>{t('discount')}</span>
                                                <span className='text-danger'>-{formatter.format(discount / 100)}</span>
                                            </div>
                                        }

                                        <div className='d-flex justify-content-between mb-3'>
                                            <span>{t('estimated_taxes')}</span>
                                            <span>{formatter.format(0)}</span>
                                        </div>

                                        <hr style={{ margin: '1rem 0' }}></hr>

                                        <div className='d-flex justify-content-between mb-3 fw-bold'>
                                            <span>{t('estimated_total')}</span>
                                            <span>{formatter.format(total / 100)}</span>
                                        </div>

                                        <div className="d-grid gap-2 mt-4">
                                            <NavLink to={`${langPrefix}/${t('_route.checkout')}`} className='d-grid gap-2'>
                                                <Button variant="primary">
                                                    {t('proceed_to_checkout')}
                                                </Button>
                                            </NavLink>
                                        </div>
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>

                    </>

                    :

                    <>
                        <Row className='mt-5 mb-2'>
                            <Col>
                                <h1>{t('shopping_cart')}</h1>
                            </Col>
                        </Row>

                        <Row className=''>
                            <Col xs={12}>
                                <p>{t('empty_shopping_cart')}</p>
                            </Col>

                            <Col xs={12}>
                                <NavLink to={`${langPrefix}/${t('_route.shop')}`} className='mt-2'>
                                    <Button variant="outline-primary d-flex justify-content-center gap-2 ps-4 pe-4 pt-2 pb-2" >
                                        <FaCart />
                                        <span>{t('continue_shopping')}</span>
                                    </Button>
                                </NavLink>
                            </Col>
                        </Row>
                    </>
                }

            </Container >

        </>
    )

}