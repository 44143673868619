import { useCookies } from 'react-cookie';
import { CookieNames } from '../enums/App.enum';

const useCustomCookies = () => {

    const [cookies, setCookie, removeCookie] = useCookies([
        CookieNames.MAP_TILE_LAYER
    ]);

    const setCustomCookies = (name: CookieNames, value: any) => {
        setCookie(name, value, {
            sameSite: 'none',
            secure: true,
            path: '/',
            maxAge: 31536000
        });
    };

    const getCookie = (name: CookieNames, defaultValue?: any) => {
        if (cookies[name]) {
            return cookies[name]
        }

        if (defaultValue) {
            return defaultValue
        }

        return
    }

    return { getCookie, setCustomCookies, removeCookie };
};

export default useCustomCookies;
