import { useParams, useSearchParams } from 'react-router-dom';
import ls from 'localstorage-slim';
import i18n from "../i18n"
import apiService from '../services/api';
import { ChangeEvent, useEffect, useState } from 'react';
import { IProduct, IProductOption, IProductOptionValue, IProductSample, IProductVariant, ISelectedOption } from '../interfaces/IProduct';
import { Col, Container, Form, Row, Button, Image, Card, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { formatArea, formatDimension, formatter, formatWeight } from '../utils/Toolbox';
import { ReactComponent as FaCart } from '../assets/icons/faCart.svg';
import useCart from '../hooks/useCart';
import DocumentComponent from '../components/Document.component';
import Error from '../components/Error.component'
import Loader from '../components/Loader.component'
import { ICartItem } from '../interfaces/ICart';
import Gallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import StoreInfoModalComponent from '../components/StoreInfoModal.component';
import DiscountComponent from '../components/Discount.component';
import { getCurrentLang } from '../utils/Local';

export default function Product() {

    const { t } = i18n;
    const lang = getCurrentLang()
    const params = useParams()
    const [searchParams] = useSearchParams()
    const productSlug = (params.product || '').toLowerCase()
    const skuParam = (searchParams.get('v') || '').toLowerCase()
    const [product, setProduct] = useState<IProduct>()
    const [selectedOptions, setSelectedOptions] = useState<ISelectedOption>({})
    const [quantity, setQuantity] = useState(1)
    const { dispatch, REDUCER_ACTIONS } = useCart()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [showGallery, setShowGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showElectricalConduitModal, setShowElectricalConduitModal] = useState(false)
    const [showStoreInformationModal, setShowStoreInformationModal] = useState(false)
    const handleStoreInfoModalClose = () => setShowStoreInformationModal(false);
    const contactEmail = 'info@pakville.ca'

    const fetchProduct = async () => {
        try {

            const cacheProductKey = `p:${productSlug}`

            const cachedProduct = ls.get(cacheProductKey, { decrypt: true }) as IProduct;
            if (cachedProduct) {
                setProduct(cachedProduct)
                setSelectedOptions(getDefaultSelections(cachedProduct))
            } else {
                const response = await apiService.fetchProduct(productSlug)
                if (response.status === 200 && response.data && response.data.product) {
                    const productData = response.data.product as IProduct;
                    ls.set(cacheProductKey, productData, { ttl: 180, encrypt: true });
                    setProduct(productData)
                    setSelectedOptions(getDefaultSelections(productData))
                }
            }

        } catch (err: any) {

            if (err.response && err.response.status && err.response.status === 404) {
                // not found
            } else {
                setError(true)
            }

        } finally {
            setIsLoading(false)
        }
    }

    const getDefaultSelections = (item: IProduct) => {

        if (skuParam && skuParam !== '') {
            const desireVariant = item.variants.filter(item => item.sku.toLowerCase() === skuParam)[0]
            if (desireVariant) {
                return desireVariant.attributes
            }
        }
        return item.variants[0].attributes;
    }

    const handleSelection = (option_id: string, option_value_id: string) => {

        if (isOptionValueDisabled(option_id, option_value_id)) {
            return
        }

        if (!product) {
            return
        }

        setQuantity(1)

        // setSelectedOptions(prev => ({ ...prev, [option_id]: option_value_id }));
        setSelectedOptions(prev => {

            const updatedSelections = { ...prev, [option_id]: option_value_id };
            return findClosestMatch(updatedSelections)

        });
    }

    const findClosestMatch = (updatedSelections: any): { [option_id: string]: string } => {

        if (product) {

            // Find the first variant that matches as many of the updated selections as possible
            const matchingVariant = product.variants.find(variant =>

                Object.entries(updatedSelections).every(([id]) => {
                    return variant.attributes[id] === updatedSelections[id]
                }
                )
            );

            if (matchingVariant) {
                setCurrentIndex(0)
                return matchingVariant.attributes;
            }

            // If no matching variant is found, fallback to the next available variant by removing the last selected option

            // Get an array of the object's keys
            const keys = Object.keys(updatedSelections);

            // Find the last key
            const lastKey = keys[keys.length - 1];

            // Remove the last key-value pair
            delete updatedSelections[lastKey];

            return findClosestMatch(updatedSelections)
        }

        return updatedSelections

    }

    // Determine if an option value should be disabled
    const isOptionValueDisabled = (option_id: string, option_value_id: string): boolean => {


        if (!product) {
            return true
        }

        if (option_id === '101') {
            return false
        }

        // Construct a hypothetical selection state including the current choice
        const hypotheticalSelections = { ...selectedOptions, [option_id]: option_value_id };

        // Check if there's any variant that matches the hypothetical selection state
        return !product.variants.some(variant =>
            Object.entries(hypotheticalSelections).every(([id, val]) =>
                variant.attributes[id] === val
            )
        );

    };

    const findMatchingVariant = (): IProductVariant | undefined => {
        if (!product) {
            return
        }

        return product.variants.find(variant =>
            Object.entries(selectedOptions).every(([optionId, valueId]) =>
                variant.attributes[optionId] === valueId
            )
        );
    };

    const isSelected = (option_id: string, option_value_id: string): boolean => {
        return (option_value_id === selectedOptions[option_id]) && !isOptionValueDisabled(option_id, option_value_id)
    };

    const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value)
        if (value < 1) {
            setQuantity(1)
        } else if (value > 1000) {
            setQuantity(999)
        } else {
            setQuantity(value)
        }
    }

    const matchingVariant = findMatchingVariant();

    const handleAddToCart = async () => {
        if (matchingVariant && product) {

            const attributes = Object.entries(matchingVariant.attributes).map(([optionId, valueId]) => {
                const option = product.options.filter(item => item.id === optionId)[0]
                const option_value = option.values.filter(value => value.id === valueId)[0]

                return ({
                    option: {
                        id: option.id,
                        label: option.label
                    },
                    option_value: {
                        id: option_value.id,
                        label: option_value.label
                    }
                })
            })

            const cartItem = {
                name: product.name[lang],
                sku: matchingVariant.sku,
                price: matchingVariant.price,
                list: matchingVariant.list,
                quantity: quantity,
                image: matchingVariant.images ? matchingVariant.images[0] : '',
                attributes: attributes,
                area: matchingVariant.area,
                weight: matchingVariant.weight,
                slug: `${productSlug}?v=${matchingVariant.sku}`,
                parent_product: productSlug,
            } as ICartItem

            if (matchingVariant.discount_code) {
                cartItem.discount_code = matchingVariant.discount_code
            }
            dispatch({ type: REDUCER_ACTIONS.ADD, payload: { ...cartItem, quantity: quantity } })
            setQuantity(1)
        }
    }


    const handleSampleAddToCart = (sku: string) => {

        if (product && product.samples) {
            const sample = product.samples.filter(item => item.sku.toLowerCase() === sku.toLowerCase())[0]

            const cartItem = {
                name: sample.label[lang],
                sku: sample.sku,
                price: sample.price,
                list: sample.list,
                quantity: 1,
                image: sample.image,
                area: sample.area,
                weight: sample.weight,
                slug: `${productSlug}`,
                parent_product: productSlug
            } as ICartItem
            dispatch({ type: REDUCER_ACTIONS.ADD, payload: { ...cartItem, quantity: quantity } })
        }
    }



    const galleryItems = matchingVariant && matchingVariant.images ? matchingVariant.images.map((image, index): ReactImageGalleryItem => {
        return {
            original: image,
            originalClass: 'product-gallery-original',
            thumbnail: image,
            thumbnailClass: 'product-gallery-thumbnail',
            loading: "lazy",
            thumbnailLoading: "lazy"
        }
    }) : []


    const handleGalleryClick = () => {
        setShowGallery(true);
    };

    const handleSlideClick = (index: number) => {
        setCurrentIndex(index);
    };


    useEffect(() => {
        fetchProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (

        <>
            {product &&
                <DocumentComponent title={`${t('product')} > ${product.name[lang]}`} />
            }

            {isLoading && <Loader />}

            {error && <Error />}

            {!isLoading && !error && !product && 'product not found'}

            {product &&

                <>
                    <Container className='product-container mb-4'>

                        <Modal show={showGallery} fullscreen={true} onHide={() => setShowGallery(false)} data-bs-theme="dark" className='modalGallery'>
                            <Modal.Header closeButton>

                            </Modal.Header>
                            <Modal.Body>
                                <Gallery
                                    items={galleryItems}
                                    startIndex={currentIndex}
                                    showThumbnails={false}
                                    onSlide={handleSlideClick}
                                />
                            </Modal.Body>
                        </Modal>


                        <Row>
                            <Col lg={5} className='mt-5'>

                                {matchingVariant && matchingVariant.images &&
                                    <>
                                        <Gallery
                                            items={galleryItems}
                                            startIndex={currentIndex}
                                            showThumbnails={true}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            onSlide={handleSlideClick}
                                            onClick={handleGalleryClick}
                                        />

                                        <Alert key={'success'} variant={'dark'} className='d-flex gap-2 align-items-center p-2 ps-3 pe-4 m-0 ' style={{ backgroundColor: 'var(--bg-light-color)', fontSize: '14px' }}>
                                            <span>
                                                {t('product_image_note')} <a rel="noreferrer" href={`mailto:${contactEmail}`} style={{ flex: '2 0' }}> {contactEmail}</a> .
                                            </span>
                                        </Alert>
                                    </>
                                }

                            </Col>

                            <Col lg={7} className='mt-5'>

                                <div className='product-info-container'>
                                    <h1>{product.name[lang]}</h1>
                                    <p>{product.description && product.description[lang]}</p>
                                    {matchingVariant && (
                                        <>
                                            <div className='product-price'>{formatter.format(matchingVariant.price / 100)} <span className='fs-5 fw-normal'>/{t('per_panel')}</span></div>
                                            <span className='text-uppercase text-muted'>{t('sku')}: {matchingVariant.sku}</span>
                                        </>
                                    )}
                                    <div className='mt-3'>
                                        <DiscountComponent />
                                    </div>
                                </div>

                                <hr style={{ margin: '1rem 0' }}></hr>

                                {product.options.map((option: IProductOption, index: number) => {

                                    const countOptionValues = option.values.length || 0
                                    const isStack = countOptionValues > 2


                                    return (
                                        <Row key={index} id={`option-container-${option.id}`} className='option-container mt-2'>

                                            <Col xs={12}>

                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <h2 className='section-title'>{option.label[lang]}</h2>

                                                    {parseInt(option.id) === 103 &&
                                                        <Button variant="link" className='m-0 p-0 ms-auto text-end' onClick={() => setShowElectricalConduitModal(true)}>{t('why_choose_electrical_conduits')}</Button>
                                                    }
                                                </div>

                                            </Col>

                                            <Col xs={12}>
                                                <Row className=''>
                                                    {option.values.map((optionValue: IProductOptionValue, indexProductOptionValue: number) => {
                                                        return (
                                                            <Col className='mb-2' id={`option-value-container-${option.id}-${optionValue.id}`} xs={12} lg={isStack ? 12 : 6} key={`ProductOptionValue-${indexProductOptionValue}`}  >
                                                                <div onClick={() => handleSelection(option.id, optionValue.id)} className={`d-flex flex-row justify-content-between align-items-start rounded pakville-option-value-container h-100 pb-0 ${isOptionValueDisabled(option.id, optionValue.id) ? 'pakville-option-disabled' : ''} ${isSelected(option.id, optionValue.id) ? 'pakville-option-selected' : ''}`}>

                                                                    {optionValue.images && optionValue.images[0] && isStack &&
                                                                        <Image src={optionValue.images[0]} role='button' rounded style={{ maxWidth: '80%', maxHeight: '120px' }} />
                                                                    }


                                                                    <div className={`d-flex flex-column`}>

                                                                        {optionValue.images && optionValue.images[0] && !isStack ?
                                                                            <>
                                                                                <h4 className='text-center'>{optionValue.label[lang]}</h4>
                                                                                <div className='mt-1 mb-1 text-center'>
                                                                                    <Image src={optionValue.images[0]} role='button' rounded style={{ maxWidth: '70%' }} />
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <h4>{optionValue.label[lang]}</h4>
                                                                            </>
                                                                        }
                                                                        <div>
                                                                            <p>{optionValue.description && optionValue.description[lang]}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {isSelected(option.id, optionValue.id) ?
                                                                            <FontAwesomeIcon icon={faCheckCircle} color='var(--info-color)' />
                                                                            :
                                                                            <FontAwesomeIcon icon={faCircle as IconProp} color='#A1A1A1' />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                })}

                                {matchingVariant &&
                                    <>
                                        <Row>
                                            <Col>
                                                <div className='section-title mb-2 mt-2'>{t('quantity')}</div>
                                                <div className='d-flex flex-column flex-lg-row justify-content-lg-between product-summary-container'>

                                                    <div className='d-flex align-items-center justify-content-between border rounded p-2 ps-3 pe-3' style={{ gap: '0.5rem', maxWidth: '50%' }}>
                                                        <FontAwesomeIcon icon={faMinus} onClick={() => quantity > 1 ? setQuantity(quantity - 1) : undefined} style={{ cursor: quantity > 1 ? 'pointer' : 'not-allowed' }} />
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            maxLength={10}
                                                            value={quantity}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e)}
                                                            className='quantity-input'
                                                        />
                                                        <FontAwesomeIcon icon={faPlus} onClick={() => quantity < 999 ? setQuantity(quantity + 1) : undefined} style={{ cursor: quantity < 999 ? 'pointer' : 'not-allowed' }} />
                                                    </div>

                                                    <div className='d-flex flex-column flex-lg-row justify-content-start gap-0 gap-lg-5'>
                                                        <div className='d-flex justify-content-between align-items-center data-info'>
                                                            <span className=' p-2'>
                                                                {t('total_area')}:
                                                            </span>
                                                            <span style={{ minWidth: '50px' }}>
                                                                {formatArea(matchingVariant.area * quantity, lang)}
                                                            </span>
                                                        </div>

                                                        <div className='d-flex justify-content-between align-items-center data-info'>
                                                            <span className='p-2'>
                                                                {t('total_weight')}:
                                                            </span>
                                                            <span style={{ minWidth: '50px' }}>
                                                                {formatWeight(matchingVariant.weight * quantity)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <div className="d-grid gap-2 mt-3 product-add-to-cart-container">
                                                    <Button variant="primary d-flex justify-content-center gap-2 " onClick={() => handleAddToCart()}>
                                                        <FaCart />
                                                        <span>{t('add_to_cart')}</span>
                                                        <span> &mdash;</span>
                                                        <div>{formatter.format(quantity * matchingVariant.price / 100)}</div>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className='product-checkout-note-container mt-2'>
                                            <Col>
                                                {t('shipping_is_calculated_at_checkout')}
                                            </Col>
                                        </Row>

                                        <Row className='product-checkout-note-container mt-3'>

                                            <Col xs={12} lg={7} className='text-start text-lg-start'>
                                                <div className="d-flex flex-column justify-content-start align-items-start">
                                                    <span>
                                                        {t('pickup_available_at')}&nbsp;<strong>Dorval, QC, Canada.</strong>&nbsp;
                                                    </span>

                                                    <span>
                                                        {t('usually_ready_in_x_days', { days: 10 })}
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col xs={12} lg={5} className='text-start text-lg-end mt-2 mt-lg-0'>
                                                <Button variant="link" className='m-0 p-0 ms-auto text-end' onClick={() => setShowStoreInformationModal(true)}>{t('view_store_information')}</Button>
                                            </Col>
                                        </Row>
                                    </>
                                }



                                {product.samples &&
                                    <Card className='mt-5'>
                                        <Card.Header className='bg-transparent'>
                                            <Card.Title>{t('_sample_card_title')}</Card.Title>
                                        </Card.Header>
                                        <Card.Body>

                                            {product.samples.map((sample: IProductSample, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <div className='d-flex flex-row justify-content-between rounded'>
                                                            <Image src={sample.image} rounded style={{ maxWidth: '33%', maxHeight: '120px' }} />

                                                            <div className='d-flex flex-column'>

                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <div>
                                                                        {sample.label[lang]}
                                                                    </div>
                                                                    <div>
                                                                        {formatter.format(sample.price / 100)}
                                                                    </div>
                                                                </div>

                                                                {sample.description &&
                                                                    <div className='d-flex flex-row'>
                                                                        <div>
                                                                            {sample.description[lang]}
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className='d-flex flex-column flex-lg-row mt-3'>
                                                                    <div>
                                                                        {t('sample_size')}: {formatDimension(sample.width, sample.length, sample.thickness)}
                                                                    </div>

                                                                    <Button variant="outline-primary d-flex justify-content-center gap-2" style={{ marginLeft: 'auto' }} onClick={() => handleSampleAddToCart(sample.sku)}>
                                                                        <span>{t('add_to_cart')}</span>
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Row>
                                                )
                                            })}

                                        </Card.Body>
                                    </Card>
                                }
                            </Col>
                        </Row>
                    </Container>

                    <Modal show={showElectricalConduitModal} onHide={() => setShowElectricalConduitModal(false)} animation={true} centered size='lg'>
                        <Modal.Header closeButton className='border-bottom-0'>
                        </Modal.Header>
                        <Row>
                            <Col xs={12} >
                                <Modal.Body className='d-flex flex-column justify-content-between align-items-center gap-4'>

                                    <Modal.Title>{t('why_choose_electrical_conduits')}</Modal.Title>
                                    <Image src={`/images/products/electrical_conduits.png`} className='mw-100' rounded />
                                    <div className='text-center'>
                                        <p className='m-0'>{t('_electrical_conduits_description_1')}</p>
                                        <p>{t('_electrical_conduits_description_2')}</p>
                                    </div>
                                </Modal.Body>
                            </Col>
                        </Row>
                    </Modal>


                    <StoreInfoModalComponent show={showStoreInformationModal} onClose={handleStoreInfoModalClose} />
                </>

            }

        </>
    )
}