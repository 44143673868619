
import React, { useState } from 'react';
import HeroComponent from "../components/Hero.component";
import DocumentComponent from '../components/Document.component';
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import i18n from "../i18n";
import { flatPhoneNumber } from "../utils/Toolbox";
import apiService from '../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Alert from 'react-bootstrap/Alert';
import { PhoneInput } from 'react-international-phone';

export default function Contact() {

    const { t, language } = i18n;
    const phoneNumber = process.env.REACT_APP_SALES_PHONE_NUMBER || '';
    const calendarLink = process.env.REACT_APP_CALENDAR_LINK || '';

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        models: '',
        phone: '',
        language: language
    });

    const [submitting, setSubmitting] = useState(false)
    const [formSuccess, setFormSuccess] = useState(false)
    const [formFailed, setFormFailed] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        setSubmitting(true)
        setFormFailed(false)

        e.preventDefault();

        try {

            await apiService.submitContactForm(formData)
            setSubmitting(false)
            setFormSuccess(true)

        } catch (e) {
            setSubmitting(false)
            setFormFailed(true)
        }
    };


    return (<>

        <DocumentComponent title={t('contact_us')} />

        <HeroComponent title={t('contact_us')} />

        <Container>

            <Row>
                <Col xs={12}>
                    <h2 className="text-center itemHeader">{t('contact_us_title')}</h2>
                </Col>

                <Col xs={12} className='text-center'>
                    <p>{t('schedule_p1')} {t('schedule_p2')} <a href={`tel:${flatPhoneNumber(phoneNumber)}`}>{phoneNumber}</a>.</p>
                </Col>

            </Row>

            <Row>
                <Col xs={12} md={{ span: 4, offset: 4 }} className='text-center'>
                    <Button ><a href={calendarLink} target="_blank" rel="noreferrer">{t('schedule_a_call')}</a></Button>
                </Col>
            </Row>


            <Container >
                <hr style={{ margin: '2rem 0' }}></hr>
            </Container>

            <Row>

                <Col md={6}>

                    {formSuccess ?

                        <>
                            <Alert key={'success'} variant={'success'}>
                                {t('form.success')}
                            </Alert>
                        </>

                        :

                        <>

                            {formFailed &&
                                <Alert key={'danger'} variant={'danger'}>
                                    {t('form.failed')}
                                </Alert>
                            }

                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="name" className='mb-3'>
                                    <Form.Label>{t('form.name')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder={t('enter_your_name')}
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="email" className='mb-3'>
                                    <Form.Label>{t('form.email')}</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder={t('enter_your_email')}
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="phone" className='mb-3'>
                                    <Form.Label>{t('form.phone')}</Form.Label>
                                    <PhoneInput
                                        defaultCountry="ca"
                                        value={formData.phone}
                                        required={true}
                                        onChange={(phone) => setFormData({ ...formData, phone: phone })}
                                        inputClassName={`form-control ps-5 `}
                                        inputStyle={{ height: '38px', fontSize: '1rem', borderRadius: 'var(--bs-border-radius)' }}
                                        countrySelectorStyleProps={{
                                            buttonClassName: 'm-0 p-0',
                                            buttonStyle: { height: '35px', position: 'absolute', border: 'none', left: '2px', top: '2px' },
                                            flagStyle: { height: '16px' }
                                        }}
                                        forceDialCode={true}
                                        dialCodePreviewStyleProps={{
                                            style: { border: '21px solid red', display: 'none' }
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="models" className='mb-3'>
                                    <Form.Label>{t('form.models')}</Form.Label>
                                    <Form.Select name="models" value={formData.models} onChange={handleChange}>
                                        <option value="">{t("please_choose_one")}</option>
                                        <option value="pak-tokyo">PakTokyo</option>
                                        <option value="pak-alps">PakAlps</option>
                                        <option value="pak-stockholm">PakStockholm</option>
                                        <option value="pak-shiraz">PakShiraz</option>
                                        <option value="pak-new-york">PakNewYork</option>
                                        <option value="pak-montreal">PakMontréal</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="message" className='mb-3'>
                                    <Form.Label>{t('form.question')}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        rows={4}
                                        placeholder={t('enter_your_message')}
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Row className='mt-4 mb-4'>
                                    <Col md={{ span: 4, offset: 4 }}>
                                        {submitting ?
                                            <Button variant="secondary" className='w-100'><FontAwesomeIcon icon={faSpinner} spin={true} /></Button>
                                            :
                                            <Button variant="primary" type="submit" className='w-100'>{t('submit')}</Button>
                                        }
                                    </Col>
                                </Row>

                            </Form>

                        </>
                    }

                </Col>

                <Col md={6}>
                    <Row>
                        <Col xs={12} className="text-center">
                            <Image src='/images/contact_us.jpg' className='mw-100' rounded alt={`PakVille - ${t('contact_us')}`} />
                        </Col>
                    </Row>
                </Col>

            </Row>

            <br />
            <br />
            <br />

        </Container >
    </>)
}