import { Container, Row, Col, Image } from "react-bootstrap";
import DocumentComponent from "../components/Document.component";
import i18n from "../i18n"
import teamData from '../data/team.json'
import ScheduleComponent from "../components/Schedule.component";
import OurStoryScreen from "../components/screens/OurStory.component";
import HeroComponent from "../components/Hero.component";


interface ITeam {
    name: string,
    title: string,
    description: string,
    img: {
        src: string
    }
}


export default function About() {

    const { t } = i18n;

    return (<>

        <DocumentComponent title={t('our_story')} />

        <HeroComponent title={t('our_story')} style={{'backgroundColor': 'var(--bg-light-color)'}}/>

        <OurStoryScreen />

        <Container fluid className="teamContainer">

            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={{ span: 10, offset: 1 }}>
                        <span>~ {t('meet_our_team')} ~</span>
                        <h2>{t('our_team_title')}</h2>
                        <p>{t('our_team_description')}</p>
                    </Col>
                </Row>

            </Container>
        </Container>

        {
            teamData.map((item: ITeam, index: number) => {
                let imgContainerOrder = 'order-1 order-lg-2';
                let contentContainerOrder = 'order-2 order-lg-1';
                let textAlignClass = 'text-center text-lg-start';
                let alignItemClass = 'align-items-center align-items-lg-start'
                let contentOffset = 1;

                if (index % 2 === 0) {
                    imgContainerOrder = 'order-1 order-lg-1';
                    contentContainerOrder = 'order-2 order-lg-2';
                    textAlignClass = 'text-center text-lg-start'
                    alignItemClass = 'align-items-center align-items-lg-start'
                    contentOffset = 0;
                }

                return (
                    <Container key={index} >
                        <Row className="mt-4 mb-4">
                            <Col xs={12} lg={{ span: 6, offset: contentOffset }} className={`${contentContainerOrder} ${textAlignClass}`}>
                                <div className={`flexContainer ${alignItemClass}`} >
                                    <h3 className={`modelHeader`}>{t(item.name)}</h3>
                                    <h4>{t(item.title)}</h4>
                                    <p style={{ fontSize: '1rem', fontWeight: 'normal' }}>{t(item.description)}</p>
                                </div>
                            </Col>

                            <Col xs={12} lg={5} className={imgContainerOrder}>
                                <div className="flexContainer" style={{ alignItems: 'center' }}>
                                    <Image src={item.img.src} style={{ maxWidth: '70%' }} rounded className="mt-4 mb-4" alt={t(item.name)} title={t(item.name)} />
                                </div>
                            </Col>

                        </Row>

                        {
                            index + 1 !== teamData.length &&
                            <Row key={index} className="mt-4 mb-4">
                                <Col xs={12} className="d-block d-lg-none order-0">
                                    <hr></hr>
                                </Col>
                            </Row>
                        }

                    </Container>

                )
            })

        }


        <ScheduleComponent />


    </>)
}