import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBug } from "@fortawesome/free-solid-svg-icons"
import i18n from "../i18n"
import { NavLink } from "react-router-dom"
import { getUrlLangPrefix } from "../utils/Local"
import { Button } from "react-bootstrap"
import { ReactComponent as FaHome } from '../assets/icons/faHome.svg';
import { useEffect } from "react"
import useCart from "../hooks/useCart"
import ls from 'localstorage-slim';

const ErrorComponent = () => {

    const { t } = i18n
    const { dispatch, REDUCER_ACTIONS } = useCart()
    
    useEffect(() => {

        ls.clear()
        dispatch({ type: REDUCER_ACTIONS.RESET })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center position-absolute h-100 w-100 gap-4">
                <FontAwesomeIcon icon={faBug} size="4x" />
                {t('_error_message')}

                <NavLink to={`${getUrlLangPrefix()}/`} className='mt-2'>
                    <Button variant="outline-primary d-flex justify-content-center gap-2 ps-4 pe-4 pt-2 pb-2" >
                        <FaHome />
                        <span>{t('go_to_homepage')}</span>
                    </Button>
                </NavLink>
            </div>
        </>
    )
}

export default ErrorComponent