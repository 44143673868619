import { Container, Row, Col } from "react-bootstrap";

interface HeroComponentProps {
    title: string,
    description: string,
    style?: object
}

const BannerComponent: React.FC<HeroComponentProps> = ({ title, description, style }) => {

    return (<>
        <Container style={style} className="text-center mt-5 mb-5">
            <Row>
                <Col xs={{ span: 10, offset: 1 }} >
                    <h1>{title}</h1>
                    <p>{description}</p>
                </Col>
            </Row>
        </Container>

    </>)
}

export default BannerComponent;