import { Helmet } from 'react-helmet-async';

const MetaPixelComponent: React.FC = () => {

    let metaPixel = ''
    let metaPixelNoScript = ''

    if (process.env.REACT_APP_ENV === 'production') {
        metaPixel = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '283733264673798');
fbq('track', 'PageView');`

        metaPixelNoScript = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=283733264673798&ev=PageView&noscript=1" />`
    }

    return (
        <Helmet>
            <script>{metaPixel}</script>
            <noscript>{metaPixelNoScript}</noscript>
        </Helmet>
    )

}

export default MetaPixelComponent;